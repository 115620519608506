import { FC } from 'react';
import { CustomResourceDetails } from './CustomResourceDetails';
import { useCurrentlySelectedResource } from 'atoms/resource';

export const CustomResourceSidebar: FC = () => {
  const selectedResource = useCurrentlySelectedResource();
  const { id: selectedResourceId } = selectedResource || {};

  if (!selectedResourceId) {
    return null;
  }

  //const view = useEditCustomResourceView();
  //   switch (view) {
  //     case 'resource-details':
  //       return <ResourceDetails />;
  //     case 'resource-list':
  //       return <ResourceList />;
  //     default:
  //       checkIsExhaustive(view);
  //       return null;
  //   }

  return <CustomResourceDetails selectedResourceId={selectedResourceId} />;
};
