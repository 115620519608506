import styled from '@emotion/styled';
import { StartTimeDropdown, EndTimeDropdown } from 'components/global/controls';
import { FC, useCallback } from 'react';
import { useSetStartTime, useTimezone } from 'atoms/resource';
import { Tooltip, Typography } from '@robinpowered/ui-kit';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const CustomResourceTimePickers: FC<{
  status?: '' | 'warning' | 'error' | undefined;
}> = ({ status }) => {
  const setStartTime = useSetStartTime();
  const { t } = useTranslation('customResourceDetails');
  const { timezone, longName } = useTimezone();
  const handleStartTimeSelect = useCallback(
    (selectedTime) => {
      setStartTime(selectedTime);
    },
    [setStartTime]
  );

  return (
    <TimePickersContainer>
      <StartTimeDropdown onTimeSelect={handleStartTimeSelect} status={status} />
      <Typography.Text>
        {t('resource_booking_controls.time_divider')}
      </Typography.Text>
      <EndTimeDropdown type={'seats'} status={status} />
      {timezone && (
        <Tooltip title={longName}>
          <Timezone type="secondary">
            {moment.tz(timezone).format('z')}
          </Timezone>
        </Tooltip>
      )}
    </TimePickersContainer>
  );
};

const TimePickersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`;

const Timezone = styled(Typography.Text)`
  && {
    margin: 0;
  }
`;
