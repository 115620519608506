import { gql } from '@apollo/client';
import { useAuthContext } from 'contexts';
import {
  DeskListItemDataQuery,
  DeskListItemDataQueryVariables,
} from 'generated';
import { useQueryCachedLoad } from 'hooks';
import { useResourceAvailabilityQueryParameters } from 'hooks/useResourceAvailabilityParameters';

export type DeskListItemData = NonNullable<
  DeskListItemDataQuery['getAnyDeskById']
>;

export type DeskListItemReservation = NonNullable<
  DeskListItemDataQuery['getAnyDeskById']
>['state']['reservations'][number];

export type DeskListItemDeskReservation = NonNullable<
  DeskListItemDataQuery['getAnyDeskById']
>['state']['reservations'][number];

const QUERY = gql`
  query DeskListItemData(
    $deskId: ID!
    $userId: Int!
    $startTimeMoment: Date!
    $endTimeMoment: Date!
    $recurrence: String
  ) {
    getAnyDeskById(id: $deskId) {
      id
      rawType
      state(
        startTime: $startTimeMoment
        endTime: $endTimeMoment
        userId: $userId
        recurrence: $recurrence
      ) {
        reservations {
          id
          startTime
          endTime
          visibility
          reservee {
            user {
              name
              avatar
              id
            }
          }
        }
      }
    }
  }
`;

export const useDeskListItemData = ({
  deskId,
  skip,
}: {
  deskId: string;
  skip: boolean;
}) => {
  const { currentUser } = useAuthContext();
  const { startTimes, endTime, recurrence } =
    useResourceAvailabilityQueryParameters();

  const { data, loading } = useQueryCachedLoad<
    DeskListItemDataQuery,
    DeskListItemDataQueryVariables
  >(QUERY, {
    skip:
      !deskId ||
      !currentUser ||
      !endTime ||
      !startTimes ||
      startTimes.length === 0 ||
      skip,
    variables: {
      deskId,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      userId: Number(currentUser!.id),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      endTimeMoment: endTime!.toString(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      startTimeMoment: startTimes![0].toString(),
      recurrence: recurrence,
    },
  });

  return {
    desk: data?.getAnyDeskById,
    loading,
  };
};
