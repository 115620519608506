import { gql, useQuery } from '@apollo/client';
import {
  UseWorkAreaDetailsQuery,
  UseWorkAreaDetailsQueryVariables,
} from 'generated/graphql';

const GET_WORK_AREA_BY_ID = gql`
  query UseWorkAreaDetails($id: ID!) {
    getWorkAreaById(id: $id) {
      id
      name
      level {
        id
        name
      }
      desks {
        id
        name
        zone {
          id
          name
        }
        isDisabled
        amenities {
          id
          name
        }
      }
    }
  }
`;

export const useWorkAreaDetails = (workAreaId: string | undefined) => {
  const { data, loading } = useQuery<
    UseWorkAreaDetailsQuery,
    UseWorkAreaDetailsQueryVariables
  >(GET_WORK_AREA_BY_ID, {
    variables: { id: workAreaId || '' },
    skip: !workAreaId,
  });

  return { data, loading };
};
