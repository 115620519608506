import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import {
  ResourceDetailsDataQuery,
  ResourceDetailsDataQueryVariables,
} from 'generated';

export type ResourcePolicyConfiguration =
  ResourceDetailsDataQuery['resourceDetails'][0]['policyConfiguration'];

const RESOURCE_DETAILS_QUERY = gql`
  query ResourceDetailsData($organizationId: ID!, $resourceIds: [ID!]!) {
    resourceDetails(
      organizationId: $organizationId
      resourceIds: $resourceIds
    ) {
      customResourceInstanceId
      amenities {
        id
        name
      }
      policyConfiguration {
        maxBookingLength {
          enabled
          duration
        }
        advancedBookingThreshold {
          enabled
          duration
        }
        requireCheckIn
        localCheckInOnly
        advanceCheckInWindow {
          enabled
          duration
        }
        abandonedResourceProtection {
          enabled
          duration
        }
      }
    }
  }
`;

export const useCustomResourceAmenitiesAndPoliciesData = (
  resourceId: string | null | undefined
) => {
  const { currentOrg } = useAuthContext();

  const { loading, data } = useQuery<
    ResourceDetailsDataQuery,
    ResourceDetailsDataQueryVariables
  >(RESOURCE_DETAILS_QUERY, {
    skip: !currentOrg?.id || !resourceId,
    variables: {
      organizationId: currentOrg?.id || '',
      resourceIds: resourceId ? [resourceId] : [],
    },
  });

  const resourceDetails = data?.resourceDetails[0] || null;

  return {
    amenities: resourceDetails?.amenities || [],
    policyConfiguration: resourceDetails?.policyConfiguration || null,
    loading,
  };
};
