import styled from '@emotion/styled';
import SvgCheckmark from '@robinpowered/icons/Checkmark';
import { useTranslation } from 'react-i18next';
import { StatusMessage } from 'components/resource-details/shared/StatusMessage';

export function CheckedInStatus({ isConfirmed }: { isConfirmed: boolean }) {
  const { t } = useTranslation('customResourceDetails');
  const showCheckedInStatus = isConfirmed;
  if (!showCheckedInStatus) {
    return null;
  }
  return (
    <CheckInContainer>
      {isConfirmed && (
        <StatusMessage
          icon={<CheckmarkIcon data-testid="checkmark-icon" color="green" />}
          message={t('resource_booking_controls.checked_in_today')}
          isPositive
        />
      )}
    </CheckInContainer>
  );
}

const CheckInContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--Space-Padding-paddingXS, 8px);
  flex-direction: column;
`;

const CheckmarkIcon = styled(SvgCheckmark)`
  top: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
`;
