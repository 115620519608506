import styled from '@emotion/styled';
import { Button } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { useDeskReservationActions } from '../hooks';

interface CheckInButtonProps {
  reservationId: string;
}

export const CheckInButton = ({ reservationId }: CheckInButtonProps) => {
  const { t } = useTranslation('deskDetails');

  const { handleCheckIn, checkinLoading } = useDeskReservationActions();

  return (
    <StyledCheckInButton
      data-testid="check-in-button"
      type="primary"
      onClick={() => handleCheckIn(reservationId)}
      loading={checkinLoading}
    >
      {t('check_in.check_in_now')}
    </StyledCheckInButton>
  );
};

const StyledCheckInButton = styled(Button)`
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInline, 16px);
  justify-content: center;
  align-items: center;
  flex: 1;
`;
