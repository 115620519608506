import { FC, useCallback } from 'react';
import styled from '@emotion/styled';
import { Image, Tooltip, theme } from '@robinpowered/ui-kit';
import { SpaceListDetail } from '../graphql/useSpaceList';
import {
  HandicapAccessibleSolid,
  VideoCallSolid,
  PhoneSolid,
  TeamSolid,
  LockSolid,
} from '@robinpowered/icons';
import { SpaceListAvailabilityPills } from '../space-availability-pills/SpaceListAvailabilityPills';
import { useSetSpaceSidebarView } from 'atoms/editSpace';
import { useSetCurrentlySelectedResource } from 'atoms/resource';
import { useTranslation } from 'react-i18next';
import { CreateSpaceRequestButton } from '../CreateSpaceRequestButton';

type Props = {
  space: SpaceListDetail;
};

const PHONE = 'Phone';
const VIDEO_CONFERENCING = 'Video Conferencing';

export const SpaceListItem: FC<Props> = ({ space }) => {
  const { t } = useTranslation('spaceDetails');
  const { token } = theme.useToken();

  const iconColor = token.colorIcon || '#6c6c6c';
  const setSpaceSidebarView = useSetSpaceSidebarView();
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const otherAmenities = space.amenities.filter(
    (x) => x.name !== PHONE && x.name !== VIDEO_CONFERENCING
  );

  const handleGoToSpaceDetail = useCallback(() => {
    setCurrentlySelectedResource({ type: 'spaces', id: space.id });
    setSpaceSidebarView('space-details');
  }, [setSpaceSidebarView, space.id, setCurrentlySelectedResource]);

  const OnDemandSpace = () => {
    return (
      <IconTextContainer>
        <GrayText>{t('space_list_item.on_demand')}</GrayText>
      </IconTextContainer>
    );
  };

  const RequestOnlySpace = () => {
    return (
      <RequestToBookWrapper>
        <IconTextContainer>
          <LockSolid color={iconColor} size={20} />
          <GrayText>{t('space_list_item.request_only')}</GrayText>
        </IconTextContainer>
        <CreateSpaceRequestButton
          spaceId={space.id}
          buttonType="space_list"
        ></CreateSpaceRequestButton>
      </RequestToBookWrapper>
    );
  };
  return (
    <SpaceListItemContainer>
      <SpaceDetailWrapper onClick={handleGoToSpaceDetail}>
        <LeftContent>
          <SpaceTitle>{space.name}</SpaceTitle>
          <SpaceDetail>
            <IconTextContainer>
              <TeamSolid color={iconColor} size={20} />
              <GrayText>{space.capacity}</GrayText>
            </IconTextContainer>
            {space.isAccessible && (
              <HandicapAccessibleSolid
                color={iconColor}
                size={20}
                data-testid="accessible"
              />
            )}
            {space.amenities.some((x) => x.name === VIDEO_CONFERENCING) && (
              <VideoCallSolid color={iconColor} size={20} data-testid="video" />
            )}
            {space.amenities.some((x) => x.name === PHONE) && (
              <PhoneSolid color={iconColor} size={20} data-testid="phone" />
            )}
            {otherAmenities.length > 1 && (
              <ViewMore title={otherAmenities.map((a) => a.name).join(', ')}>
                <TooltipText data-testid="amenities">
                  {t('space_list_item.amenities_count', {
                    count: otherAmenities.length,
                  })}
                </TooltipText>
              </ViewMore>
            )}
          </SpaceDetail>
        </LeftContent>
        <RightContent>
          {space.image && (
            <SpaceImage
              data-testid="space-image"
              src={space.image}
              width={64}
              height={48}
              preview={false}
            />
          )}
        </RightContent>
      </SpaceDetailWrapper>
      <>{space.scheduleConfiguration === 'ON_DEMAND' && <OnDemandSpace />}</>
      <>
        {space.scheduleConfiguration === 'REQUEST_ONLY' && <RequestOnlySpace />}
      </>
      <>
        {space.scheduleConfiguration === 'SCHEDULED' && (
          <SpaceListAvailabilityPills space={space} />
        )}
      </>
    </SpaceListItemContainer>
  );
};

const SpaceImage = styled(Image)`
  height: 48px;
  width: 64px;
`;

const RequestToBookWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
`;

const GrayText = styled.span`
  color: var(--Colors-Neutral-Text-colorTextSecondary, #515151);
`;

const IconTextContainer = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;
`;

const ViewMore = styled(Tooltip)`
  cursor: pointer;
`;

const TooltipText = styled.span`
  border-bottom: 1px dotted
    var(--Colors-Neutral-Text-colorTextSecondary, #515151);
  color: var(--Colors-Neutral-Text-colorTextSecondary, #515151);
  font-size: 12px;
`;

const SpaceTitle = styled.div`
  height: 16px;
  color: var(--Colors-Neutral-Text-colorText, #1c1c1c);
`;

const SpaceDetail = styled.div`
  height: 48px;
  margin-right: 8px;
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  color: var(--Colors-Neutral-Text-colorTextSecondary, #515151);
  width: 100%;
  gap: 3px;

  align-items: center;
`;
const SpaceDetailWrapper = styled.div`
  height: 48px;
  margin-right: 8px;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  color: var(--Colors-Neutral-Text-colorText, #1c1c1c);
  width: 100%;
  cursor: pointer;
`;

const SpaceListItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 2px !important;
  border: initial !important;
  margin: 3px !important;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
`;

const LeftContent = styled.div`
  border-radius: 100px;
  flex-direction: column;
  margin-right: 8px;
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  gap: 10px;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
