import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useMutation,
  MutationHookOptions,
  gql,
  ApolloClient,
  FetchResult,
  ApolloError,
} from '@apollo/client';
import {
  GetResourceEventConsistencyQuery,
  GetResourceEventConsistencyQueryVariables,
} from 'generated/graphql';
import { useEffect, useRef, useState, useCallback, useMemo } from 'react';

// Define the ResourceEventConsistency query
const RESOURCE_EVENT_CONSISTENCY_QUERY = gql`
  query getResourceEventConsistency($organizationId: ID!, $eventTypes: [ID!]!) {
    resourceEventConsistency(
      eventTypes: $eventTypes
      organizationId: $organizationId
    ) {
      organizationId
      eventType
      value
    }
  }
`;

// Default values for polling configuration
const DEFAULT_POLLING_INTERVAL = 1000;
const DEFAULT_MAX_POLLING_ATTEMPTS = 10;
const DEFAULT_INITIAL_DELAY = 300;

/**
 * Helper type for creating an event type and value extractor result
 */
export type EventTypeAndValueResult =
  | {
      eventType: string | null;
      value: number;
    }
  | null
  | undefined;

/**
 * Helper type for creating an event type and value extractor function
 */
export type EventTypeAndValueExtractor<TData> = (
  data: TData
) => EventTypeAndValueResult;

// Define the configuration options for eventual consistency
export interface EventualConsistencyConfig<TData> {
  // Organization ID to use for consistency checks
  organizationId: string;
  // required function to extract eventType and value from TData
  eventTypeAndValueExtractor: EventTypeAndValueExtractor<TData>;
  // Polling interval in milliseconds
  pollingInterval?: number;
  // Maximum number of polling attempts
  maxPollingAttempts?: number;
  // Initial delay before starting polling (in milliseconds)
  initialDelay?: number;
  // Queries to refetch after successful polling
  refetchQueries?: string[];
}

// return type for useMutationWithEventualConsistency
export type MutationWithConsistencyTuple<TData, TVariables> = [
  ({ variables }: { variables?: TVariables }) => Promise<FetchResult<TData>>,
  {
    loading: boolean;
    isLoadingOrPolling: boolean;
    data?: TData;
    error?: ApolloError;
    reset: () => void;
    client: ApolloClient<object>;
    called: boolean;
  }
];

/**
 * A custom hook for polling the resourceEventConsistency query
 */
function useConsistencyPolling<TData>(
  client: ApolloClient<object>,
  config: {
    organizationId: string;
    eventType: string | null;
    expectedValue: number;
    pollingInterval: number;
    maxPollingAttempts: number;
    initialDelay: number;
    onComplete: (data: TData) => void;
    mutationData: TData | null;
  }
) {
  const {
    organizationId,
    eventType,
    expectedValue,
    pollingInterval,
    maxPollingAttempts,
    initialDelay,
    onComplete,
    mutationData,
  } = config;

  const [pollCount, setPollCount] = useState(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hasCompletedRef = useRef(false);

  // Helper function to safely clear timeout
  const clearPollingTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  }, []);

  // Helper function to complete polling
  const completePolling = useCallback(() => {
    if (!hasCompletedRef.current && mutationData) {
      hasCompletedRef.current = true;
      onComplete(mutationData);
    }
  }, [mutationData, onComplete]);

  // Reset polling state when dependencies change
  useEffect(() => {
    setPollCount(0);
    hasCompletedRef.current = false;
    clearPollingTimeout();
  }, [
    eventType,
    expectedValue,
    organizationId,
    mutationData,
    clearPollingTimeout,
  ]);

  useEffect(() => {
    // Skip polling if expectedValue is -1 (no change was made or eventType is null)
    // or if organizationId is not provided
    if (expectedValue === -1 || !eventType || !organizationId) {
      completePolling();
      return;
    }

    // Don't start polling if we've already completed
    if (hasCompletedRef.current) return;

    // Recursively poll for consistency until you find the expected value or max poll attempts
    const pollForConsistency = async () => {
      // Increment poll count
      setPollCount((prev) => {
        const newCount = prev + 1;

        // Check if we've reached the maximum number of polling attempts
        if (newCount >= maxPollingAttempts) {
          completePolling();
          return newCount;
        }

        return newCount;
      });

      try {
        // Query the server for consistency status
        const { data } = await client.query<
          GetResourceEventConsistencyQuery,
          GetResourceEventConsistencyQueryVariables
        >({
          query: RESOURCE_EVENT_CONSISTENCY_QUERY,
          variables: {
            organizationId,
            eventTypes: [eventType],
          },
          fetchPolicy: 'network-only',
        });

        // Check if our event reached the expected value
        const consistencyResult = data?.resourceEventConsistency?.find(
          (item) => item.eventType === eventType
        );

        if (consistencyResult) {
          // If value is -1 or the consistency value is greater than or equal to the expected value, stop polling
          if (
            consistencyResult.value === -1 ||
            (expectedValue && consistencyResult.value >= expectedValue)
          ) {
            completePolling();
            return;
          }
        }
      } catch (error) {
        // Continue polling even on errors
      }

      // Schedule the next poll if we haven't completed
      if (!hasCompletedRef.current) {
        clearPollingTimeout();
        timeoutRef.current = setTimeout(pollForConsistency, pollingInterval);
      }
    };

    // Start the first poll after the initial delay
    timeoutRef.current = setTimeout(pollForConsistency, initialDelay);

    // Clean up the timeout when the component unmounts or dependencies change
    return clearPollingTimeout;
  }, [
    eventType,
    expectedValue,
    organizationId,
    pollingInterval,
    maxPollingAttempts,
    initialDelay,
    client,
    onComplete,
    mutationData,
    clearPollingTimeout,
    completePolling,
  ]);

  return { pollCount };
}

/**
 * A custom hook that combines a mutation with polling for eventual consistency.
 * After the mutation completes, it starts polling the resourceEventConsistency query
 * until either:
 * 1. The value is -1 (indicating completion)
 * 2. The consistency value is greater than or equal to the expected value
 * 3. The maximum number of polling attempts is reached
 *
 * Polling is skipped entirely if:
 * 1. No eventType is found in the mutation result via the eventTypeAndValueExtractor
 * 2. Value is -1 in the mutation result via the eventTypeAndValueExtractor
 * 3. No eventualConsistency config is provided
 * 4. The extractor returns null or undefined
 *
 * The eventTypeAndValueExtractor function is used to extract the eventType and value
 * fields from the mutation result. It should return an object with eventType and value
 * properties, or null/undefined if the mutation result doesn't have the expected structure.
 *
 * The onCompleted callback is only called after polling is complete or when polling is skipped.
 * An initial delay can be configured before starting the first poll.
 *
 * @param mutation The GraphQL mutation document
 * @param options The mutation options
 * @param options.eventualConsistency Configuration for eventual consistency polling
 * @returns A standard MutationTuple from Apollo Client with an isPolling flag
 */
export function useMutationWithEventualConsistency<
  TData,
  TVariables = OperationVariables
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & {
    eventualConsistency?: EventualConsistencyConfig<TData>;
  }
): MutationWithConsistencyTuple<TData, TVariables> {
  const eventualConsistencyConfig = options?.eventualConsistency;
  const originalOnCompletedRef = useRef(options?.onCompleted);
  const mutationDataRef = useRef<TData | null>(null);
  const hasCalledOriginalOnCompletedRef = useRef(false);

  // isLoading starts when the mutation starts and ends when polling stop
  const [isLoadingOrPolling, setIsLoadingOrPolling] = useState(false);
  const [pollingState, setPollingState] = useState<{
    isActive: boolean;
    eventType: string | null;
    expectedValue: number;
  }>({
    isActive: false,
    eventType: null,
    expectedValue: -1,
  });

  // set the original onCompleted callback
  useEffect(() => {
    originalOnCompletedRef.current = options?.onCompleted;
  }, [options?.onCompleted]);

  // Reset state when mutation changes
  useEffect(() => {
    return () => {
      hasCalledOriginalOnCompletedRef.current = false;
      mutationDataRef.current = null;
      setPollingState({
        isActive: false,
        eventType: null,
        expectedValue: -1,
      });
    };
  }, [mutation]);

  // Helper function to call the original onCompleted callback
  const callOriginalOnCompleted = useCallback((data: TData) => {
    if (
      originalOnCompletedRef.current &&
      !hasCalledOriginalOnCompletedRef.current
    ) {
      hasCalledOriginalOnCompletedRef.current = true;
      originalOnCompletedRef.current(data);
    }
  }, []);

  // option to initialize the polling on mutation completion
  const mutationOptions: MutationHookOptions<TData, TVariables> = useMemo(
    () => ({
      ...options,
      onCompleted: (data: TData) => {
        mutationDataRef.current = data;
        // Reset the flag when a new mutation completes
        hasCalledOriginalOnCompletedRef.current = false;

        let shouldStartPolling = false;

        if (eventualConsistencyConfig) {
          // Use the extractor to get eventType and value
          const extractedEventTypeAndValue =
            eventualConsistencyConfig.eventTypeAndValueExtractor(data);

          // Start polling only if we have valid eventType and value
          if (
            extractedEventTypeAndValue &&
            extractedEventTypeAndValue.eventType !== null &&
            extractedEventTypeAndValue.value !== -1
          ) {
            shouldStartPolling = true;
            setPollingState({
              isActive: true,
              eventType: extractedEventTypeAndValue.eventType,
              expectedValue: extractedEventTypeAndValue.value,
            });
          }
        }

        // Call the original onCompleted callback immediately if we're not polling
        if (!shouldStartPolling) {
          setIsLoadingOrPolling(false);
          callOriginalOnCompleted(data);
        }
      },

      // Add onError handler to set isLoading to false when an error occurs
      onError: (error: ApolloError) => {
        setIsLoadingOrPolling(false);
        // Forward the error to the original onError callback if it exists
        if (options?.onError) {
          options.onError(error);
        }
      },
    }),
    [eventualConsistencyConfig, callOriginalOnCompleted, options]
  );

  // Use the Apollo useMutation hook with our modified options
  const [mutate, mutationResult] = useMutation<TData, TVariables>(
    mutation,
    mutationOptions
  );

  useEffect(() => {
    if (mutationResult.loading) {
      setIsLoadingOrPolling(true);
    }
  }, [mutationResult.loading]);

  const handlePollingComplete = useCallback(
    async (data: TData) => {
      // Only proceed if polling is active
      if (!pollingState.isActive) return;

      // Refetch queries if configured
      const refetchQueriesConfig = eventualConsistencyConfig?.refetchQueries;
      if (refetchQueriesConfig && refetchQueriesConfig.length > 0) {
        await mutationResult.client.refetchQueries({
          include: refetchQueriesConfig,
        });
      }

      // Update polling state
      setPollingState({
        isActive: false,
        eventType: null,
        expectedValue: -1,
      });

      // Set loading to false here after polling is complete
      setIsLoadingOrPolling(false);

      // Call the original onCompleted
      callOriginalOnCompleted(data);
    },
    [
      pollingState.isActive,
      callOriginalOnCompleted,
      eventualConsistencyConfig,
      mutationResult.client,
      setIsLoadingOrPolling,
    ]
  );

  // Memoize the config object to prevent unnecessary re-renders
  const pollingConfig = useMemo(() => {
    // Ensure a reasonable minimum interval
    const safePollingInterval = Math.max(
      100,
      eventualConsistencyConfig?.pollingInterval || DEFAULT_POLLING_INTERVAL
    );

    return {
      organizationId:
        pollingState.isActive && eventualConsistencyConfig
          ? eventualConsistencyConfig.organizationId
          : '',
      eventType: pollingState.isActive ? pollingState.eventType : null,
      expectedValue:
        pollingState.isActive && pollingState.expectedValue
          ? pollingState.expectedValue
          : -1,
      pollingInterval: safePollingInterval,
      maxPollingAttempts:
        eventualConsistencyConfig?.maxPollingAttempts ||
        DEFAULT_MAX_POLLING_ATTEMPTS,
      initialDelay:
        eventualConsistencyConfig?.initialDelay || DEFAULT_INITIAL_DELAY,
      onComplete: handlePollingComplete,
      mutationData: pollingState.isActive ? mutationDataRef.current : null,
    };
  }, [
    pollingState.isActive,
    pollingState.eventType,
    pollingState.expectedValue,
    eventualConsistencyConfig,
    handlePollingComplete,
  ]);

  // Always call useConsistencyPolling but only perform actual polling when conditions are met
  useConsistencyPolling(mutationResult.client, pollingConfig);

  // Return the mutation function and the result with the isPolling flag
  return [
    mutate,
    {
      ...mutationResult,
      isLoadingOrPolling,
    },
  ] as MutationWithConsistencyTuple<TData, TVariables>;
}
