import { atom } from 'recoil';
import { syncEffect } from 'recoil-sync';
import { writeWithReset } from '../utils';
import { DatePickerType, SelectedResource } from './types';
import {
  refineDatePickerType,
  refineIsoString,
  refineIsoStringArray,
  refineSelectedResource,
  refineNumberToString,
} from './refine';
import moment from 'moment';
import { IsoString, momentToIsoString } from 'utils';

export const AtomKey = {
  currentlySelectedResource: 'currentlySelectedResource',
  selectedLevel: 'selectedLevel',
  selectedLocation: 'selectedLocation',
};

export const currentlySelectedResource = atom<SelectedResource | null>({
  key: AtomKey.currentlySelectedResource,
  default: null,
  effects: [
    syncEffect({
      itemKey: 'csr',
      refine: refineSelectedResource(),
      write: writeWithReset('csr'),
    }),
  ],
});

export const selectedLevel = atom<string | null>({
  key: AtomKey.selectedLevel,
  default: null,
  effects: [
    syncEffect({
      itemKey: 'levels',
      refine: refineNumberToString(),
      write: writeWithReset('levels'),
    }),
  ],
});

export const selectedLocation = atom<string | null>({
  key: AtomKey.selectedLocation,
  default: null,
  effects: [
    syncEffect({
      itemKey: 'locations',
      refine: refineNumberToString(),
      write: writeWithReset('locations'),
    }),
  ],
});

export const datePickerType = atom<DatePickerType>({
  key: 'datePickerType',
  default: 'single',
  effects: [
    syncEffect({
      itemKey: 'datePickerType',
      refine: refineDatePickerType(),
      write: writeWithReset('datePickerType'),
    }),
  ],
});

export const selectedDatesState = atom<IsoString[]>({
  key: 'selectedDatesState',
  default: [momentToIsoString(moment())],
  effects: [
    syncEffect({
      itemKey: 'selectedDates',
      refine: refineIsoStringArray([momentToIsoString(moment())]),
      write: writeWithReset('selectedDates'),
    }),
  ],
});

export const startTimeState = atom<IsoString | null>({
  key: 'startTimeState',
  default: null,
  effects: [
    syncEffect({
      itemKey: 'startTime',
      refine: refineIsoString(),
      write: writeWithReset('startTime'),
    }),
  ],
});

export const endTimeStateForSpaces = atom<IsoString | null>({
  key: 'endTimeStateForSpaces',
  default: null,
  effects: [
    syncEffect({
      itemKey: 'endSpaceTime',
      refine: refineIsoString(),
      write: writeWithReset('endSpaceTime'),
    }),
  ],
});

export const endTimeStateForResources = atom<IsoString | null>({
  key: 'endTimeStateForResources',
  default: null,
  effects: [
    syncEffect({
      itemKey: 'endResourceTime',
      refine: refineIsoString(),
      write: writeWithReset('endResourceTime'),
    }),
  ],
});

export const locationTimezone = atom<string>({
  key: 'locationTimezone',
  default: moment.tz.guess(),
});
