import { useCallback, useMemo } from 'react';
import { FilterTag } from '../FilterTag';
import { SpaceSolid } from '@robinpowered/icons';
import { useCurrentFilter } from 'atoms/mapInteractions';
import { useFilterTagClick } from '../useFilterTagClick';
import { theme } from '@robinpowered/ui-kit';
import { SpaceSubFilters } from './sub-filters';
import { useTranslation } from 'react-i18next';
import { InteractiveLayer } from 'atoms/resource/types';
import { useSetSpaceSidebarView } from 'atoms/editSpace';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';

export const SpaceFilter = () => {
  const { t } = useTranslation('filters');
  const { useToken } = theme;
  const { token } = useToken();

  const currentFilter = useCurrentFilter();
  const setSpaceSidebarView = useSetSpaceSidebarView();
  const setRightSidebarView = useSetRightSidebarView();
  const isDisplayed = useMemo(
    () => !currentFilter || currentFilter === 'spaces',
    [currentFilter]
  );

  const isActive = useMemo(() => currentFilter === 'spaces', [currentFilter]);

  const activeLayers: Set<InteractiveLayer> = useMemo(
    () => new Set(['spaces', 'space_labels']),
    []
  );

  const { handleClick } = useFilterTagClick({
    type: 'spaces',
    active: isActive,
    activeLayers,
  });

  const onClick = useCallback(() => {
    handleClick();
    if (!isActive) {
      setSpaceSidebarView('space-list');
    } else {
      setRightSidebarView(undefined);
    }
  }, [handleClick, isActive, setSpaceSidebarView, setRightSidebarView]);

  if (!isDisplayed) {
    return null;
  }

  return (
    <>
      <FilterTag
        title={t('SPACE_FILTERS.TAG_LABEL')}
        active={isActive}
        onClick={onClick}
        icon={
          <SpaceSolid
            color={isActive ? token.colorBgContainer : token.colorBgSpotlight}
            size={16}
          />
        }
        data-testid="space-filter"
      />

      {isActive && <SpaceSubFilters />}
    </>
  );
};
