import styled from '@emotion/styled';
import { AmenitiesFilter } from './AmenitiesFilter';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';
import { useEffect } from 'react';

export const DeskSubFilters = () => {
  const setDeskSidebarView = useSetDeskSidebarView();

  useEffect(() => {
    setDeskSidebarView('desk-list');
  }, [setDeskSidebarView]);

  return (
    <Container>
      <AmenitiesFilter />
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
`;
