import styled from '@emotion/styled';
import { Button, Typography } from '@robinpowered/ui-kit';
import { DatePickerComponent } from 'components/global/controls';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CustomResourceTimePickers } from './CustomResourceTimePickers';
import { useReserveSingleDayResource } from '../hooks/graphql/useReserveResourceSingleDay';
import { useAuthContext } from 'contexts';
import { useResourceAvailabilityQueryParameters } from 'hooks/useResourceAvailabilityParameters';
import {
  createIso8601Duration,
  toIsoDateOnlyString,
  toIsoLocalTimeString,
} from 'utils';
import {
  ClientType,
  ResourceReservationValidationFailureType,
} from 'generated';
import { useReserveResourceRecurring } from '../hooks/graphql/useReserveResourceRecurring';
import { ViolationMessage } from '../../shared/ViolationMessage';
import { CustomResourceValidationError } from '../hooks/graphql/useResourceReservationRecurringValidate';

type ResourceBookingControlsProps = {
  unbookableReasons: CustomResourceValidationError[];
  bookingEnabled: boolean;
  resourceInstanceId: string;
};

export const CustomResourceBookingControls: FC<
  ResourceBookingControlsProps
> = ({ unbookableReasons, bookingEnabled, resourceInstanceId }) => {
  const { startTimes, durationInMinutes } =
    useResourceAvailabilityQueryParameters();
  const { currentOrg, currentUser } = useAuthContext();
  const { t } = useTranslation('customResourceDetails');
  const { reserveResourceSingleDay, isReserving: isReservingSingleDay } =
    useReserveSingleDayResource();
  const { reserveResourceRecurring, isReserving: isReservingMultiDay } =
    useReserveResourceRecurring();

  const dateViolationMessage = useMemo(() => {
    const violationReason = unbookableReasons?.find(
      (r) =>
        r.failureType ===
          ResourceReservationValidationFailureType.ExceedsAdvancedBookingThreshold ||
        r.failureType ===
          ResourceReservationValidationFailureType.BookingOverlaps ||
        r.failureType ===
          ResourceReservationValidationFailureType.ExceedsMaximumBookingLength
    );

    const shouldShowDefaultUnbookableMessage =
      !violationReason &&
      unbookableReasons.length > 0 &&
      unbookableReasons.some(isNotIgnoredViolation);

    if (shouldShowDefaultUnbookableMessage) {
      return t('resource_policies.unbookable_reason');
    }

    switch (violationReason?.failureType) {
      case ResourceReservationValidationFailureType.ExceedsMaximumBookingLength:
        return t('resource_policies.max_booking_length');

      case ResourceReservationValidationFailureType.ExceedsAdvancedBookingThreshold: {
        const durationInDays = violationReason.meta
          ? Math.round(moment.duration(violationReason.meta).asDays())
          : 0;

        return t('resource_policies.max_advanced_booking_threshold', {
          x: `${durationInDays} ${durationInDays === 1 ? 'day' : 'days'}`,
        });
      }

      case ResourceReservationValidationFailureType.BookingOverlaps:
        return t('resource_policies.booking_overlap');

      default:
        return undefined;
    }
  }, [t, unbookableReasons]);

  const timeViolationMessage = useMemo(() => {
    const violationReason = unbookableReasons?.find(
      (r) =>
        r.failureType ===
        ResourceReservationValidationFailureType.OutsideOfWorkingHours
    );

    switch (violationReason?.failureType) {
      case ResourceReservationValidationFailureType.OutsideOfWorkingHours:
        return t('resource_policies.outside_of_working_hours');

      default:
        return undefined;
    }
  }, [t, unbookableReasons]);

  const isReserving = useMemo(
    () => isReservingSingleDay || isReservingMultiDay,
    [isReservingSingleDay, isReservingMultiDay]
  );
  const isDisabled = useMemo(
    () => !bookingEnabled || !!dateViolationMessage || !!timeViolationMessage,
    [bookingEnabled, dateViolationMessage, timeViolationMessage]
  );

  const handleReserveResource = useCallback(async () => {
    if (currentOrg?.id && startTimes && currentUser?.id) {
      if (startTimes.length === 1) {
        await reserveResourceSingleDay({
          variables: {
            organizationId: currentOrg.id,
            resourceInstanceId,
            clientType: ClientType.DashboardWeb,
            date: toIsoDateOnlyString(startTimes[0]),
            startTime: toIsoLocalTimeString(startTimes[0]),
            duration: createIso8601Duration({ minutes: durationInMinutes }),
          },
        });
      }
      if (startTimes.length > 1) {
        await reserveResourceRecurring({
          variables: {
            organizationId: currentOrg.id,
            resourceInstanceId,
            duration: createIso8601Duration({ minutes: durationInMinutes }),
            clientType: ClientType.DashboardWeb,
            requestReservationDates: startTimes.map((x) => ({
              localDate: toIsoDateOnlyString(x),
              localTime: toIsoLocalTimeString(x),
            })),
            recurringReservationRequests: [],
          },
        });
      }
    }
  }, [
    currentOrg,
    currentUser,
    reserveResourceSingleDay,
    resourceInstanceId,
    startTimes,
    durationInMinutes,
    reserveResourceRecurring,
  ]);

  return (
    <Container>
      <BookingControlsTitle level={5}>
        {t('resource_booking_controls.title')}
      </BookingControlsTitle>

      <DatePickerComponent
        status={dateViolationMessage ? 'error' : undefined}
      />

      {dateViolationMessage && (
        <ViolationMessage message={dateViolationMessage} />
      )}

      <CustomResourceTimePickers
        status={timeViolationMessage ? 'error' : undefined}
      />
      {timeViolationMessage && (
        <ViolationMessage message={timeViolationMessage} />
      )}

      <BookingControlsCallToActions>
        <BookButton
          data-testid="book-button"
          onClick={handleReserveResource}
          type="primary"
          disabled={isDisabled}
          loading={isReserving}
        >
          {isReserving
            ? t('resource_booking_controls.reserving')
            : t('resource_booking_controls.book_now')}
        </BookButton>
      </BookingControlsCallToActions>
    </Container>
  );
};

const isNotIgnoredViolation = (reason: CustomResourceValidationError) => {
  const ignoredViolationReasons = [
    ResourceReservationValidationFailureType.ReservationTypeNotAllowed,
  ];

  return !ignoredViolationReasons.includes(reason.failureType);
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const BookingControlsTitle = styled(Typography.Title)`
  && {
    margin: 0;
  }
`;

const BookingControlsCallToActions = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
`;

const BookButton = styled(Button)`
  && {
    margin: 0;
  }
`;
