import { gql } from '@apollo/client';
import { message } from '@robinpowered/ui-kit';
import { useCurrentlySelectedResource } from 'atoms/resource/hooks';
import { CustomResourceAmplitudeEvents } from 'constants/amplitudeEvents';
import { useAmplitude } from 'contexts/AmplitudeContext';
import { useAuthContext } from 'contexts/AuthContext';
import {
  ReserveByResourceIdMutation,
  ReserveByResourceIdMutationVariables,
} from 'generated';
import { useMutationWithEventualConsistency } from 'hooks/useMutationWithEventualConsistency';
import { useTranslation } from 'react-i18next';

const RESERVE_RESOURCE_MUTATION_FOR_SELF = gql`
  mutation reserveByResourceId(
    $organizationId: ID!
    $resourceInstanceId: ID!
    $date: IsoDateOnlyString!
    $startTime: IsoLocalTimeString!
    $duration: ISO8601_Duration!
    $clientType: ClientType!
  ) {
    scheduleSingleDayReservation(
      organizationId: $organizationId
      resourceInstanceId: $resourceInstanceId
      date: $date
      startTime: $startTime
      duration: $duration
      clientType: $clientType
    ) {
      ... on ScheduleSingleDayReservationSuccessType {
        eventType
        value
        reservationId
      }
      ... on ResourceReservationValidationResponse {
        id
        policiesEvaluated
        errors {
          id
          failureType
        }
        userHasPermissionToReserve
      }
    }
  }
`;

export const useReserveSingleDayResource = () => {
  const { t } = useTranslation('customResourceDetails');
  const { currentOrg } = useAuthContext();
  const { trackCustomResourceEvent } = useAmplitude();
  const selectedResource = useCurrentlySelectedResource();

  const [reserveResourceSingleDay, { isLoadingOrPolling: isLoading }] =
    useMutationWithEventualConsistency<
      ReserveByResourceIdMutation,
      ReserveByResourceIdMutationVariables
    >(RESERVE_RESOURCE_MUTATION_FOR_SELF, {
      eventualConsistency: {
        organizationId: currentOrg?.id || '',
        refetchQueries: [
          'getCustomResourceReservations',
          'ResourceReservationRecurringValidate',
        ],
        eventTypeAndValueExtractor: (data) => {
          const result = data.scheduleSingleDayReservation;
          if (result.__typename === 'ScheduleSingleDayReservationSuccessType') {
            return {
              eventType: result.eventType || null,
              value: result.value,
            };
          }
          return null;
        },
      },
      onCompleted: async (data) => {
        const handleSuccess = () => {
          void message.open({
            type: 'success',
            content: t('resource_booking_controls.success'),
          });
          if (selectedResource?.resourceCategory) {
            trackCustomResourceEvent(
              CustomResourceAmplitudeEvents.BOOKED_CUSTOMRESOURCE,
              selectedResource.resourceCategory
            );
          }
        };

        const handleFailure = (errors: { failureType: string }[]) => {
          if (
            errors.some(
              (error: { failureType: string }) =>
                error.failureType === 'BOOKING_OVERLAPS'
            )
          ) {
            void message.open({
              type: 'error',
              content: t('resource_booking_controls.already_booked'),
            });
          } else {
            void message.open({
              type: 'error',
              content: t('resource_booking_controls.failure'),
            });
          }
        };

        if (
          data.scheduleSingleDayReservation.__typename ===
          'ScheduleSingleDayReservationSuccessType'
        ) {
          handleSuccess();
        } else if (
          data.scheduleSingleDayReservation.__typename ===
          'ResourceReservationValidationResponse'
        ) {
          handleFailure(data.scheduleSingleDayReservation.errors);
        }
      },
      onError: (error) => {
        const userFriendlyMessage = t('resource_booking_controls.failure');

        void message.open({
          type: 'error',
          content: userFriendlyMessage,
        });
      },
    });

  return {
    reserveResourceSingleDay,
    isReserving: isLoading,
  };
};
