import { Collapse, CollapseProps, Skeleton } from '@robinpowered/ui-kit';
import { FC, useMemo } from 'react';
import { useBookingEnabledForDesks } from '../hooks/useBookingEnabledForDesks';
import { ZoneAvailability } from './ZoneAvailability';
import { ZoneList } from './ZoneList';
import { DeskListSearch } from './DeskListSearch';
import styled from '@emotion/styled';
import { sortObjectAsc } from 'utils';
import { useFilteredDeskIds } from 'atoms/mapInteractions';

export type DeskListDesk = {
  id: string;
  name: string;
  zone?: {
    id: string | null;
    name: string | null;
  } | null;
};

export type DeskListZone = {
  id: string;
  name: string;
  desks: DeskListDesk[];
};

type DeskListProps = {
  zones: DeskListZone[];
  selectedDeskId?: string;
  isLoading?: boolean;
};

export const DeskList: FC<DeskListProps> = ({
  zones,
  selectedDeskId,
  isLoading = false,
}) => {
  const filteredDeskIds = useFilteredDeskIds();

  // Filter desks based on the filtered desk IDs
  const zonesWithFilteredDesks = useMemo(() => {
    return zones
      .map((zone) => ({
        ...zone,
        desks: zone.desks.filter((desk) => filteredDeskIds.includes(desk.id)),
      }))
      .filter((zone) => zone.desks.length > 0);
  }, [zones, filteredDeskIds]);

  // Get desk availability information
  const allDeskIds = useMemo(() => {
    return zones.flatMap((zone) => zone.desks.map((desk) => desk.id));
  }, [zones]);

  const { data: desksWithAvailability, loading: desksWithAvailabilityLoading } =
    useBookingEnabledForDesks(allDeskIds);

  // Add availability information to desks
  const zonesWithAvailability = useMemo(() => {
    if (isLoading || desksWithAvailabilityLoading || !zonesWithFilteredDesks) {
      return [];
    }

    return zonesWithFilteredDesks.map((zone) => {
      return {
        ...zone,
        desks: zone.desks.map((desk) => ({
          ...desk,
          isBookable: desksWithAvailability?.[desk.id]?.isBookable || false,
          isDuringExclusion:
            desksWithAvailability?.[desk.id]
              ?.isSelectedStartTimeDuringExclusion || false,
          noAccessOrPermission:
            desksWithAvailability?.[desk.id]?.noAccessOrPermission || false,
          requiresAssignmentByAdmin:
            desksWithAvailability?.[desk.id]?.requiresAssignmentByAdmin ||
            false,
        })),
      };
    });
  }, [
    isLoading,
    desksWithAvailabilityLoading,
    zonesWithFilteredDesks,
    desksWithAvailability,
  ]);

  const defaultOpenZoneId = useMemo(() => {
    if (!selectedDeskId) {
      return undefined;
    }

    return zonesWithAvailability?.find((zone) =>
      zone.desks.some((desk) => desk.id === selectedDeskId)
    )?.id;
  }, [selectedDeskId, zonesWithAvailability]);

  const items: CollapseProps['items'] = useMemo(() => {
    return zonesWithAvailability?.sort(sortObjectAsc('name')).map((zone) => ({
      key: zone.id,
      label: zone.name,
      children: <ZoneList desks={zone.desks} />,
      extra: <ZoneAvailability zone={zone} />,
    }));
  }, [zonesWithAvailability]);

  const showLoading = isLoading || desksWithAvailabilityLoading;

  return (
    <>
      {showLoading && (
        <div data-testid="skeleton">
          <Skeleton />
        </div>
      )}

      {!showLoading && (
        <Container>
          <DeskListSearch zones={zonesWithAvailability} />
          <Collapse items={items} defaultActiveKey={defaultOpenZoneId} />
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-margin, 16px);
`;
