import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import { useMapControlsContext } from 'contexts';
import {
  GetAvailableFloorsQuery,
  GetAvailableFloorsQueryVariables,
} from 'generated';
import { useMemo } from 'react';

// Define minimal type matching our query
type MinimalLevel = {
  id: string;
  name: string;
};

export type UseAvailableFloorsData = {
  data: {
    availableFloors: Array<MinimalLevel>;
    canAccessATForCurrentFloor: boolean;
  };
};

type UseAvailableFloorsState = {
  isLoading: boolean;
};

type UseAvailableFloors = UseAvailableFloorsState & UseAvailableFloorsData;

export const GET_AVAILABLE_FLOORS = gql`
  query getAvailableFloors($buildingId: ID!) {
    getAvailableFloors(buildingId: $buildingId) {
      id
      name
    }
  }
`;

export const useAvailableFloors = (
  buildingId: string | null
): UseAvailableFloors => {
  const { selectedLevelId } = useMapControlsContext();

  const { data, loading } = useQuery<
    GetAvailableFloorsQuery,
    GetAvailableFloorsQueryVariables
  >(GET_AVAILABLE_FLOORS, {
    skip: !buildingId,
    variables: {
      buildingId: buildingId || '', // Fallback needed for TS
    },
    nextFetchPolicy: 'cache-and-network',
  });

  const availableFloors = useMemo(
    () => data?.getAvailableFloors || [],
    [data?.getAvailableFloors]
  );
  const canAccessATForCurrentFloor = useMemo(() => {
    return availableFloors.some((floor) => floor.id === selectedLevelId);
  }, [availableFloors, selectedLevelId]);

  return {
    data: {
      availableFloors,
      canAccessATForCurrentFloor,
    },
    isLoading: loading,
  };
};
