import {
  InteractiveLayer,
  SelectableResources,
  SelectedResource,
} from '../atoms/resource/types';
import {
  CustomResourceTemplates,
  OnMapClickEvent,
} from '@robinpowered/perseus';

const interactiveLayers: InteractiveLayer[] = [
  'seats',
  'spaces',
  'space_labels',
  'work_area_labels',
];

const isInteractiveLayer = (layer: string): layer is InteractiveLayer => {
  return interactiveLayers.includes(layer as InteractiveLayer);
};

const mapInteractiveLayerToSelectableResource = (
  sourceType: InteractiveLayer,
  templates: CustomResourceTemplates | undefined
): SelectableResources => {
  if (templates && templates.some((template) => template.id === sourceType)) {
    return sourceType;
  }

  switch (sourceType) {
    case 'seats':
      return 'seats';

    case 'spaces':
    case 'space_labels':
      return 'spaces';

    case 'work_area_labels':
      return 'work_area_labels';

    default:
      //checkIsExhaustive(sourceType);
      throw new Error(
        `Unknown interactive layer when mapping to resource: ${sourceType}`
      );
  }
};

const getCustomResourceCategory = (
  layer: string,
  templates: CustomResourceTemplates | undefined
): string | undefined => {
  if (!templates) {
    return undefined;
  }

  const matchingTemplate = templates.find((template) => template.id === layer);
  return matchingTemplate?.resourceCategory;
};

// A single click on the map can return multiple layers
// For example clicking on an avatar over a desk would return both
// We want to filter the list for any interactive layers that we care about
// and then return the first one, assuming that it was the top layer displayed on the map
export const getClickedResource = (
  event: OnMapClickEvent,
  templates: CustomResourceTemplates | undefined
): SelectedResource | undefined => {
  const { sourceTypes, targetDetails } = event;

  // Include template IDs as interactive layers
  const templateIds = templates ? templates.map((t) => t.id) : [];

  // Filter sourceTypes to include both predefined interactive layers and custom template layers
  const combinedInteractiveLayers = sourceTypes.filter(
    (layer) => isInteractiveLayer(layer) || templateIds.includes(layer)
  );

  // Return undefined if there are no layers that we care about
  if (combinedInteractiveLayers.length === 0) {
    return;
  }

  // We are assuming that the first item in the list was the 'top' most rendered layer
  const topLayer = combinedInteractiveLayers[0];

  // Find the target details for the top layer
  const target = targetDetails.find((td) => td.type === topLayer);

  // If for some reason the layer has no target details, return undefined
  if (!target) {
    // TODO: Log error
    return;
  }

  const resourceType = mapInteractiveLayerToSelectableResource(
    topLayer,
    templates
  );

  const customResourceCategory = getCustomResourceCategory(topLayer, templates);

  return {
    type: resourceType,
    id: target.resourceId,
    resourceCategory: customResourceCategory,
  };
};
