import styled from '@emotion/styled';
import { InfoSolid } from '@robinpowered/icons';
import SvgCheckmark from '@robinpowered/icons/Checkmark';
import { StatusMessage } from '../../../shared/StatusMessage';
import { useTranslation } from 'react-i18next';
import { HealthCheckpointStatus } from 'generated';
import { useMemo } from 'react';
import { SHORT_TIME_FORMAT } from 'constants/timeFormat';
import { Reservation } from '../../graphql';
import { DeskSettings } from '../../graphql/useDeskSettings';
import { useDeskReservationSettings } from '../hooks/useDeskReservationSettings';

export function CheckedInStatus({
  isDuringExclusion,
  reservation,
  deskSettings,
}: {
  isDuringExclusion: boolean | undefined;
  reservation: Reservation;
  deskSettings: DeskSettings;
}) {
  const { t } = useTranslation('deskDetails');

  const {
    getCheckInStart,
    getDeskAbandonedAt,
    enforceLocalCheckInOnly,
    allowCheckIns,
  } = useDeskReservationSettings(deskSettings);

  const deskAbandonedAt = getDeskAbandonedAt(reservation);
  const checkInStart = getCheckInStart(reservation);
  const canCheckInNow = !!reservation.canCheckInNow;
  const isCheckedIn = reservation.isCheckedIn;
  const healthCheckpointStatus = reservation.healthCheckpoint?.status;

  const healthCheckpointMessage = useMemo(() => {
    if (healthCheckpointStatus === HealthCheckpointStatus.Incomplete) {
      return allowCheckIns
        ? t('check_in.health_checkpoint_and_check_in_required')
        : t('check_in.health_checkpoint_required');
    } else if (healthCheckpointStatus === HealthCheckpointStatus.Complete) {
      return t('check_in.health_checkpoint_complete');
    } else if (healthCheckpointStatus === HealthCheckpointStatus.Failed) {
      return t('check_in.health_checkpoint_failed');
    }
  }, [allowCheckIns, healthCheckpointStatus, t]);

  const checkInMessage = useMemo(() => {
    if (enforceLocalCheckInOnly && !isCheckedIn && allowCheckIns) {
      return t('check_in.local_check_in');
    }

    if (!isCheckedIn && allowCheckIns && !canCheckInNow && checkInStart) {
      if (deskAbandonedAt) {
        return t('check_in.check_in_window', {
          after: checkInStart.format(SHORT_TIME_FORMAT),
          before: deskAbandonedAt.format(SHORT_TIME_FORMAT),
        });
      } else {
        return t('check_in.check_in_after', {
          time: checkInStart.format(SHORT_TIME_FORMAT),
        });
      }
    }
    return null;
  }, [
    allowCheckIns,
    canCheckInNow,
    checkInStart,
    deskAbandonedAt,
    enforceLocalCheckInOnly,
    isCheckedIn,
    t,
  ]);

  return (
    <CheckInContainer>
      {!isCheckedIn && checkInMessage && !isDuringExclusion && (
        <StatusMessage
          icon={<InfoIcon data-testid="info-icon" />}
          message={checkInMessage}
        />
      )}
      {isCheckedIn && (
        <StatusMessage
          icon={<CheckmarkIcon data-testid="checkmark-icon" />}
          message={t('check_in.checked_in_today')}
          isPositive
        />
      )}
      {healthCheckpointMessage && !isDuringExclusion && (
        <StatusMessage
          icon={
            healthCheckpointStatus === HealthCheckpointStatus.Complete ? (
              <CheckmarkIcon data-testid="checkmark-icon" />
            ) : (
              <InfoIcon data-testid="info-icon" />
            )
          }
          message={healthCheckpointMessage}
          isPositive={
            healthCheckpointStatus === HealthCheckpointStatus.Complete
          }
        />
      )}
    </CheckInContainer>
  );
}

const CheckInContainer = styled.div`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  gap: var(--Space-Padding-paddingXS, 8px);
  flex-direction: column;
`;

const CheckmarkIcon = styled(SvgCheckmark)`
  top: 2px;
  position: absolute;
  width: 100%;
  height: 100%;
`;

const InfoIcon = styled(InfoSolid)`
  width: 100%;
  height: 100%;
  fill-opacity: 0.55;
  position: absolute;
  top: 3px;
`;
