import { FC, useMemo } from 'react';
import { EditSummaryView } from './edit-desk-reservation/EditSummaryView';
import { checkIsExhaustive } from 'utils';
import { EditDeskDetailsView } from './edit-desk-reservation/EditDeskDetailsView';
import { EditDeskListView } from './edit-desk-reservation/EditDeskListView';
import { BackDestinationContextProvider } from './edit-desk-reservation/contexts';
import { DeskDetails } from './DeskDetails';
import { useDeskSidebarView } from 'atoms/sidebar/hooks';
import { DeskListView } from './DeskListView';
import { EditDeskAssignments } from './edit-desk-assignments';
import { WorkAreaDeskListView } from '../work-area/WorkAreaDeskListView';

export const DeskSidebar: FC = () => {
  const view = useDeskSidebarView();

  const sidebarView = useMemo(() => {
    switch (view) {
      case 'edit-summary':
        return <EditSummaryView />;

      case 'current-desk':
      case 'new-desk':
      case 'potential-desk':
        return <EditDeskDetailsView />;

      case 'edit-desk-list':
        return <EditDeskListView />;

      case 'desk-list':
        return <DeskListView />;

      case 'desk-details':
        return <DeskDetails />;
      case 'edit-desk-assignments':
        return <EditDeskAssignments />;

      case 'work-area-details':
        return <WorkAreaDeskListView />;

      default:
        checkIsExhaustive(view);
        return null;
    }
  }, [view]);

  return (
    <BackDestinationContextProvider>
      {sidebarView}
    </BackDestinationContextProvider>
  );
};
