import { ISO8601Duration } from 'generated';

type TimeInput = {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export const createIso8601Duration = (
  timeInput: TimeInput
): ISO8601Duration => {
  const { days, hours, minutes, seconds } = timeInput;

  let totalSeconds =
    (seconds || 0) +
    (minutes || 0) * 60 +
    (hours || 0) * 3600 +
    (days || 0) * 86400;

  // Handle zero duration case
  if (totalSeconds === 0) {
    return 'PT0S' as ISO8601Duration;
  }

  const newDays = Math.floor(totalSeconds / 86400);
  totalSeconds %= 86400;
  const newHours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const newMinutes = Math.floor(totalSeconds / 60);
  const newSeconds = totalSeconds % 60;

  let duration = 'P';

  if (newDays) duration += `${newDays}D`;
  if (newHours || newMinutes || newSeconds) duration += 'T';
  if (newHours) duration += `${newHours}H`;
  if (newMinutes) duration += `${newMinutes}M`;
  if (newSeconds) duration += `${newSeconds}S`;

  return duration as ISO8601Duration;
};
