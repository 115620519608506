import { RRule } from 'rrule';
import { DeskReservationVisibility, DeskSchedule } from 'generated';
import { useTranslation } from 'react-i18next';
import { Desk, Reservation } from '../graphql';
import { AssignedDeskSchedule } from './AssignedDeskSchedule';
import { ReservationDetails } from './ReservationDetails';
import { UserDetails } from '../UserDetails';
import styled from '@emotion/styled';
import { Skeleton, Typography } from '@robinpowered/ui-kit';
import { CheckedInStatus } from './status/CheckedInStatus';
import { DeskReservationCardActions } from './actions';
import { useDeskSettings } from '../graphql/useDeskSettings';
import { useMemo } from 'react';
import { useAuthContext } from 'contexts';

const { Text } = Typography;

interface DeskReservationCardProps {
  reservation: Reservation | undefined;
  desk: Desk;
  isDuringExclusion?: boolean;
  schedule: DeskSchedule | null;
  showUserDetails?: boolean;
  showReservationDetails: boolean;
}

export function DeskReservationCard({
  reservation,
  desk,
  isDuringExclusion,
  schedule,
  showUserDetails,
  showReservationDetails,
}: DeskReservationCardProps) {
  const { t } = useTranslation('deskDetails');
  const { currentUser } = useAuthContext();
  const {
    deskSettings,
    permissions,
    loading: settingsLoading,
  } = useDeskSettings(desk?.id);

  const isUsersReservation = useMemo(
    () => reservation?.accountReservee?.user?.id === currentUser?.id,
    [reservation, currentUser]
  );

  const canDelegate = useMemo(
    () =>
      permissions?.some(
        (permission) =>
          permission.name === 'seats:delegate' && permission.value === true
      ),
    [permissions]
  );

  const scheduleName = useMemo(
    () =>
      schedule?.firstName || schedule?.lastName
        ? `${schedule.firstName} ${schedule.lastName}`
        : '',
    [schedule]
  );

  if (settingsLoading) return <Skeleton active data-testid="skeleton" />;

  return (
    <DeskReservationsContainer key={reservation?.id || schedule?.id}>
      <DeskReservationContainer>
        <DeskReservationSubContainer>
          {isDuringExclusion && (
            <ItalicText>
              {t('reservation_details.usually_sits_here')}
            </ItalicText>
          )}
          {schedule && (
            <AssignedDeskSchedule
              key={schedule.id}
              recurrence={RRule.fromString(schedule.recurrence)}
            />
          )}
          {showUserDetails && (
            <UserDetails
              avatarSrc={
                schedule
                  ? schedule.avatar || ''
                  : reservation?.accountReservee?.user?.avatar || ''
              }
              name={
                scheduleName || reservation?.accountReservee?.user?.name || ''
              }
              email={
                schedule
                  ? schedule.email
                  : reservation?.accountReservee?.email || ''
              }
            />
          )}
          {reservation && showReservationDetails && (
            <ReservationDetails
              startTime={reservation.startTime}
              seriesId={reservation.seriesId}
              endTime={reservation.endTime}
              timezone={reservation.timeZone}
              isPrivate={
                reservation.visibility === DeskReservationVisibility.JustMe
              }
            />
          )}
          {reservation && (
            <CheckedInStatus
              reservation={reservation}
              isDuringExclusion={isDuringExclusion}
              deskSettings={deskSettings}
            />
          )}
          {reservation && (isUsersReservation || canDelegate) && (
            <DeskReservationCardActions
              reservation={reservation}
              desk={desk}
              isScheduled={!!schedule?.id}
              isDuringExclusion={isDuringExclusion}
              deskSettings={deskSettings}
              isUsersReservation={isUsersReservation}
            />
          )}
        </DeskReservationSubContainer>
      </DeskReservationContainer>
    </DeskReservationsContainer>
  );
}

const DeskReservationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;

  border-radius: var(--Border-Radius-borderRadius, 4px);
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
`;

const DeskReservationContainer = styled.div`
  display: flex;
  padding: var(--Space-Padding-paddingSM, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const DeskReservationSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
  align-self: stretch;
`;

const ItalicText = styled(Text)`
  font-style: italic;
`;
