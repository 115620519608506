import { Alert } from '@robinpowered/ui-kit';
import { CustomResourceValidationError } from './hooks/graphql/useResourceReservationRecurringValidate';
import { useTranslation } from 'react-i18next';
import {
  BookingType,
  ResourceReservationValidationFailureType,
} from 'generated';
import { CustomResourceBookingType } from './hooks/graphql/useGetResourceById';
import { useMemo } from 'react';

export const CustomResourceNonReservableAlert = ({
  validationErrors,
  bookingType,
}: {
  validationErrors: CustomResourceValidationError[];
  bookingType: CustomResourceBookingType | null | undefined;
}) => {
  const { t } = useTranslation(['customResourceDetails', 'days']);

  const daysThatAreReservable = useMemo(
    () =>
      bookingType
        ? Object.entries(bookingType)
            .filter(([_, value]) => value === BookingType.Reservable)
            .map(([day]) =>
              t(`daysOfWeek.${day.toLowerCase()}`, { ns: 'days' })
            )
        : [],
    [bookingType, t]
  );

  const alertMessage = useMemo(() => {
    if (daysThatAreReservable.length === 0) {
      return t('alerts.no_reservable_days', { ns: 'customResourceDetails' });
    }

    return t('alerts.reservable_days', {
      ns: 'customResourceDetails',
      days: daysThatAreReservable.join(', '),
    });
  }, [daysThatAreReservable, t]);

  if (
    !validationErrors.some(
      (x) =>
        x.failureType ===
        ResourceReservationValidationFailureType.ReservationTypeNotAllowed
    )
  ) {
    return null;
  }

  return <Alert message={alertMessage} type="info" showIcon />;
};
