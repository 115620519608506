import React from 'react';
import { useSpaceWindowedAvailability } from './graphql';
import { Skeleton, Tag } from '@robinpowered/ui-kit';
import { useGetStartTimesForSelectedDates } from 'atoms/resource';
import { Availability } from 'generated';
import { useTranslation } from 'react-i18next';

export const SpaceAvailabilityTag: React.FC<{
  selectedResourceId: string | undefined;
}> = ({ selectedResourceId }) => {
  const { t } = useTranslation('spaceDetails');
  const startTimes = useGetStartTimesForSelectedDates();

  const { spaceDetails, loading: spaceAvailabilityLoading } =
    useSpaceWindowedAvailability(selectedResourceId, startTimes?.[0]);

  const firstBlockStatus =
    spaceDetails?.meetingRoomWindowedAvailability &&
    spaceDetails.meetingRoomWindowedAvailability.length > 0
      ? spaceDetails.meetingRoomWindowedAvailability[0].status
      : null;

  const isRequestOnly = spaceDetails?.isManaged;
  const isOnDemand =
    !spaceDetails?.calendar?.calendarId ||
    spaceDetails.calendar.calendarId === '';

  if (spaceAvailabilityLoading) {
    return <Skeleton.Button active />;
  }

  if (isRequestOnly) {
    return <Tag color="processing">{t('space_type.request_only')}</Tag>;
  }

  if (isOnDemand) {
    return <Tag color="processing">{t('space_type.on_demand')}</Tag>;
  }

  return (
    <Tag
      color={firstBlockStatus === Availability.Available ? 'success' : 'error'}
    >
      {firstBlockStatus === Availability.Available
        ? t('space_details.available')
        : t('space_details.booked')}
    </Tag>
  );
};
