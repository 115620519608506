import {
  endTimeStateForResources,
  endTimeStateForSpaces,
  selectedDatesState,
  startTimeState,
  useTimezone,
} from 'atoms/resource';
import { useAuthContext } from 'contexts';
import { useClock } from 'hooks/useClock';
import { useLocationWorkingHours } from 'hooks/useLocationWorkingHours';
import moment from 'moment';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

/**
 * This component is used to sync the state of the office to the office-dev-tool
 * relies on having the office-dev-tool extension installed and enabled
 */
export const OfficeDevToolSync = () => {
  const { currentUser } = useAuthContext();
  const clock = useClock();
  const startTime = useRecoilValue(startTimeState);
  const endTimeDesk = useRecoilValue(endTimeStateForSpaces);
  const endTimeSpace = useRecoilValue(endTimeStateForResources);
  const selectedDates = useRecoilValue(selectedDatesState);
  const locationTimezone = useTimezone();
  const browserTimezone = moment.tz.guess();
  const officeHours = useLocationWorkingHours();
  const dashboardData = useMemo(() => {
    return {
      clock,
      locationTimezone: locationTimezone.timezone,
      browserTimezone,
      startTime,
      endTimeDesk,
      endTimeSpace,
      selectedDates,
      officeHours: officeHours.location?.workingHours,
    };
  }, [
    clock,
    locationTimezone,
    browserTimezone,
    startTime,
    endTimeDesk,
    endTimeSpace,
    selectedDates,
    officeHours,
  ]);

  useEffect(() => {
    window.postMessage(
      {
        type: 'RECOIL_STATE_UPDATE',
        dashboardData,
      },
      '*'
    );
  }, [dashboardData, currentUser]);

  return null;
};
