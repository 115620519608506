import styled from '@emotion/styled';
import { Avatar, Button, Card } from '@robinpowered/ui-kit';
import { CloseOutlined } from '@ant-design/icons';

type PropTypes = {
  name: string | undefined | null;
  email: string | undefined | null;
  avatar: string | undefined | null;
  onRemove: () => void | undefined;
};

export const AssignedDeskUser = ({
  name,
  email,
  avatar,
  onRemove,
}: PropTypes): JSX.Element => {
  const cardContent = name ? (
    <Card.Meta
      avatar={<Avatar src={avatar} />}
      title={<TextWrapper>{name}</TextWrapper>}
      description={<TextWrapper>{email}</TextWrapper>}
    />
  ) : (
    <CardContent>
      <Avatar src={avatar} name={email || ''} />
      <TextWrapper>{email}</TextWrapper>
    </CardContent>
  );
  return (
    <AssignedDeskUserWrapper>
      <StyledCard
        size="default"
        type="inner"
        bordered={false}
        style={{ boxShadow: 'none' }}
      >
        {cardContent}
      </StyledCard>

      <StyledButton
        size="small"
        icon={<CloseOutlined />}
        onClick={onRemove}
      ></StyledButton>
    </AssignedDeskUserWrapper>
  );
};
const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  margin-bottom: 0px !important;
  gap: 12px;
  color: var(--ant-color-text-description);
`;
const StyledButton = styled(Button)`
  align-self: center;
  border: none;
  box-shadow: none;
  background-color: transparent !important;
`;
const AssignedDeskUserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
const TextWrapper = styled.div`
  max-width: 150px;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
`;

const StyledCard = styled(Card)`
  background-color: transparent !important;
  .ant-card-body {
    display: flex;
    align-items: start;
    box-shadow: none;
    padding: 0px;
    white-space: nowrap;
    flex-direction: column;
    height: 28px;
  }
  .ant-card-meta-avatar {
    padding-right: 12px !important;
  }
  .ant-card-meta-title {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0px !important;
  }
  .ant-card-meta-description {
    font-size: 12px;
  }
`;
