import styled from '@emotion/styled';
import { Checkbox, Input, theme } from '@robinpowered/ui-kit';
import { MinorPinSolid } from '@robinpowered/icons';
import { useState, useCallback, ChangeEvent, useMemo } from 'react';
import {
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from '../../components/global/sidebar';
import { useTranslation } from 'react-i18next';
import { Typography } from '@robinpowered/ui-kit';
import { MutationResult } from '@apollo/client';
import { ReportIssueWithDeskInput, ReportIssueWithSpaceInput } from 'generated';

const { Title, Text } = Typography;
const { TextArea } = Input;

export type IssueReport = {
  description: string;
  isUnusable: boolean;
};

export type ResourceDetails = {
  id: string | undefined;
  name: string | undefined;
  level: {
    name: string | undefined;
  };
  location: {
    name: string | undefined;
  };
};

type ReportIssueProps = {
  resourceDetails: ResourceDetails;
  handleClose: () => void;
  handleFormSubmit: (issue: IssueReport) => void;
  reportIssueWithResourceRequest:
    | MutationResult<ReportIssueWithDeskInput>
    | MutationResult<ReportIssueWithSpaceInput>;
};

export const ReportIssue = ({
  resourceDetails,
  handleClose,
  handleFormSubmit,
  reportIssueWithResourceRequest,
}: ReportIssueProps) => {
  const { t } = useTranslation('reportAnIssue');
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [textValue, setTextValue] = useState<string>('');
  const { token } = theme.useToken();
  const iconColor = token.colorIcon || '#6c6c6c';

  const handleTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setTextValue(e.target.value);
      if (e.target.value) {
        setError(null);
      }
    },
    []
  );

  const getLocationDetails = useMemo(
    () => (levelName?: string, locationName?: string) => {
      if (levelName && locationName) {
        return `${levelName}, ${locationName}`;
      }
      return levelName || locationName || '';
    },
    []
  );

  const handleCheckbox = useCallback(() => {
    setCheckboxValue((value: unknown) => !value);
  }, []);

  const onFormSubmit = useCallback(() => {
    if (!textValue.trim()) {
      setError(t('report_issue.error_message'));
      return;
    }

    const issue = { description: textValue, isUnusable: checkboxValue };

    handleFormSubmit(issue);
  }, [handleFormSubmit, checkboxValue, textValue, t]);

  return (
    <ReportIssueContainer>
      <SidebarHeader header={t('report_issue.header')} onClose={handleClose} />
      <SidebarContent>
        <ReportIssueWrapper data-testid="report-issue">
          <ResourceDetailsSection>
            <ResourceName data-testid="resource-name" level={4}>
              {resourceDetails?.name}
            </ResourceName>
            <ResourceLocation data-testid="resource-location" type="secondary">
              <MinorPinSolid size={16} color={iconColor} />
              {getLocationDetails(
                resourceDetails?.level?.name,
                resourceDetails?.location.name
              )}
            </ResourceLocation>
          </ResourceDetailsSection>
          <Details>
            <DetailsTitle aria-required="true">
              {t('report_issue.details_title')}
            </DetailsTitle>
            <DetailsTextArea
              placeholder={t('report_issue.details_placeholder')}
              onChange={handleTextChange}
              value={textValue}
              aria-invalid={!!error}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
          </Details>
          <Checkbox onChange={handleCheckbox}>
            {t('report_issue.checkbox_label')}
          </Checkbox>
        </ReportIssueWrapper>
      </SidebarContent>
      <SidebarFooter
        actions={{
          primary: {
            label: t('report_issue.send_report'),
            onClick: onFormSubmit,
            disabled: reportIssueWithResourceRequest.loading || !textValue,
          },
          secondary: {
            label: t('report_issue.close'),
            onClick: handleClose,
            disabled: reportIssueWithResourceRequest.loading,
          },
        }}
      />
    </ReportIssueContainer>
  );
};

const ReportIssueContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ReportIssueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--Space-Margin-marginLG, 24px);
  overflow-y: auto;
`;

const ResourceDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-marginXXS, 4px);
`;

const DetailsTitle = styled(Text)`
  && {
    margin: 0;
  }
`;

const ResourceName = styled(Title)`
  && {
    margin: 0;
  }
`;

const ResourceLocation = styled(Text)`
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: var(--Space-Padding-paddingXS, 8px);

  && {
    margin: 0;
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--Space-Margin-marginXS, 8px);
`;

const DetailsTextArea = styled(TextArea)`
  &[aria-invalid='true'] {
    border-color: red;
  }
`;

const ErrorMessage = styled(Text)`
  color: red;
  margin-top: 8px;
`;
