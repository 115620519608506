import { WarningTriangleSolid } from '@robinpowered/icons';
import { useTranslation } from 'react-i18next';

export const CancelReservationModalTitle = ({
  reservationInProgress,
}: {
  reservationInProgress: boolean;
}) => {
  const { t } = useTranslation('deskDetails');

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <WarningTriangleSolid size={18} color={'#E81C1C'} />
      <span>
        {reservationInProgress
          ? t('cancel_modal.end_desk_booking')
          : t('cancel_modal.cancel_desk_booking')}
      </span>
    </div>
  );
};
