import { useTranslation } from 'react-i18next';
import { DeskListItemBase } from './DeskListItemBase';
import { UnavailableIcon } from './icons/UnavailableIcon';
import { FC } from 'react';
import { useDefaultDeskListItemClick } from './hooks/useDefaultDeskListItemClick';

export const DeskListItemNoAccess: FC<{ deskId: string; deskName: string }> = ({
  deskName,
  deskId,
}) => {
  const { t } = useTranslation('deskDetails');

  const onClick = useDefaultDeskListItemClick(deskId);

  return (
    <DeskListItemBase
      title={deskName}
      subtitle={t('desk_list.no_access_or_permission')}
      icon={<UnavailableIcon />}
      onClick={onClick}
    />
  );
};
