import Cookies from 'js-cookie';
import { config } from 'config';
import { AuthenticationError } from 'components/global/GlobalErrorBoundary';

type RobinSession = {
  access_token: string;
};

export function useAccessToken(): string {
  const sessionCookie = Cookies.get(config.sessionCookieName);

  const robinSession = (sessionCookie && JSON.parse(sessionCookie)) as
    | RobinSession
    | undefined;

  if (!robinSession) {
    throw new AuthenticationError('Access token unavailable.');
  }

  return robinSession.access_token;
}
