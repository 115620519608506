import { gql } from '@apollo/client';
import { useCurrentlySelectedResource } from 'atoms/resource';
import { CustomResourceAmplitudeEvents } from 'constants/amplitudeEvents';
import { useAmplitude } from 'contexts/AmplitudeContext';
import { useAuthContext } from 'contexts/AuthContext';
import { CustomResourceReservationSelfConfirmMutationVariables } from 'generated';
import { CustomResourceReservationSelfConfirmMutation } from 'generated';
import { useMutationWithEventualConsistency } from 'hooks/useMutationWithEventualConsistency';

const CONFIRM_RESOURCE_RESERVATION_MUTATION = gql`
  mutation customResourceReservationSelfConfirm(
    $organizationId: ID!
    $reservationId: ID!
    $startDate: IsoDateOnlyString!
    $startTime: IsoLocalTimeString!
    $clientType: ConfirmationClientType!
  ) {
    customResourceReservationSelfConfirm(
      organizationId: $organizationId
      reservationId: $reservationId
      startDate: $startDate
      startTime: $startTime
      clientType: $clientType
    ) {
      ... on ResourceReservationConfirmationSuccessResponse {
        eventType
        value
        reservationId
      }
      ... on ResourceReservationConfirmationErrorResponse {
        id
        errorCodes
        detailedErrors {
          failureCode
          meta
        }
      }
    }
  }
`;

export const useConfirmResourceReservation = () => {
  const { currentOrg } = useAuthContext();
  const { trackCustomResourceEvent } = useAmplitude();
  const selectedResource = useCurrentlySelectedResource();

  const [confirmResourceReservation, { isLoadingOrPolling: isLoading, error }] =
    useMutationWithEventualConsistency<
      CustomResourceReservationSelfConfirmMutation,
      CustomResourceReservationSelfConfirmMutationVariables
    >(CONFIRM_RESOURCE_RESERVATION_MUTATION, {
      eventualConsistency: {
        organizationId: currentOrg?.id || '',
        refetchQueries: [
          'getCustomResourceReservations',
          'ResourceReservationRecurringValidate',
        ],
        eventTypeAndValueExtractor: (data) => {
          const result = data.customResourceReservationSelfConfirm;
          if (
            result &&
            result.__typename ===
              'ResourceReservationConfirmationSuccessResponse'
          ) {
            return {
              eventType: result.eventType || null,
              value: result.value,
              reservationId: result.reservationId || null,
            };
          }
          return null;
        },
      },
      onCompleted: (data) => {
        if (selectedResource?.resourceCategory) {
          trackCustomResourceEvent(
            CustomResourceAmplitudeEvents.CHECKIN_CUSTOMRESOURCE,
            selectedResource.resourceCategory
          );
        }
      },
    });

  return { confirmResourceReservation, isLoading, error };
};
