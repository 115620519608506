import gql from 'graphql-tag';
import { useApolloContext, useAuthContext } from 'contexts';
import {
  DeskSharedScheduleQuery,
  DeskSharedScheduleQueryVariables,
} from 'generated';
import { useQueryCachedLoad } from 'hooks';

export type SharedDeskScheduleReturn = {
  loading: boolean;
  data: DeskSharedScheduleQuery | undefined;
};

export type SharedDeskSchedule = NonNullable<
  NonNullable<DeskSharedScheduleQuery['getSharedDesksSchedules']>[number]
>['schedule'][number];

export type SharedDeskDetails = NonNullable<
  NonNullable<DeskSharedScheduleQuery['getDesksByIds']>[number]
>;

const DESK_SHARED_SCHEDULE = gql`
  query DeskSharedSchedule($deskIds: [ID!]!) {
    getDesksByIds(ids: $deskIds) {
      id
      name
      level {
        id
        name
      }
      location {
        id
        name
      }
    }
    getSharedDesksSchedules(deskIds: $deskIds) {
      id
      schedule {
        id
        uniqueId
        firstName
        lastName
        email
        recurrence
        avatar
      }
    }
  }
`;

export const useAssignedDeskDetails = (
  deskId: string | undefined
): SharedDeskScheduleReturn => {
  const { currentUser } = useAuthContext();
  const { tenantId } = useApolloContext();

  const { loading, data } = useQueryCachedLoad<
    DeskSharedScheduleQuery,
    DeskSharedScheduleQueryVariables
  >(DESK_SHARED_SCHEDULE, {
    context: {
      headers: {
        'cache-refresh': 'no-cache',
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !tenantId || !currentUser || !deskId,
    variables: {
      deskIds: deskId ? [deskId] : [],
    },
  });

  return {
    loading,
    data,
  };
};
