import {
  Select,
  Button,
  Dropdown,
  MenuProps,
  Typography,
} from '@robinpowered/ui-kit';
import { DefaultOptionType } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import { useMapControlsContext, useLocalStorageContextByKey } from 'contexts';
import {
  OrganizationLocation,
  useOrganizationLocations,
} from 'hooks/useOrganizationLocations';
import { useCallback, useMemo } from 'react';
import { sortObjectAsc } from 'utils/sort';

const { Text } = Typography;

type GroupedLocationOption =
  | {
      label: string;
      options: LocationOption[];
    }
  | false;

type LocationOption = DefaultOptionType & {
  campusName?: string;
};

const renderLabel = (location: OrganizationLocation) => {
  return (
    <Text
      style={{
        width: '180px',
        display: 'block',
        textAlign: 'left',
      }}
      ellipsis={{ tooltip: location.name }}
    >
      <div>{location.name}</div>
    </Text>
  );
};

export function LocationSelector(): JSX.Element | null {
  const { t } = useTranslation('mapControls');
  const locationData = useOrganizationLocations();
  const [recentlySelectedLocationIds] = useLocalStorageContextByKey(
    'recentlySelectedLocations'
  );
  const { selectedLocationId, setSelectedLocationId } = useMapControlsContext();

  const items: MenuProps['items'] = useMemo(() => {
    if (locationData.loading || !locationData.locations) {
      return [];
    }

    return locationData.locations
      .map((location) => ({
        label: renderLabel(location),
        key: location.id,
        name: location.name,
      }))
      .sort(sortObjectAsc('name'));
  }, [locationData]);

  const menuProps = {
    items,
    onClick: (e: { key: string }) => {
      const locationId = e.key;
      setSelectedLocationId(locationId);
    },
  };

  const locationDisplayLine = useMemo(() => {
    return (
      locationData.locations.find((l) => l.id === selectedLocationId)?.name ??
      null
    );
  }, [locationData.locations, selectedLocationId]);

  const renderItem = useCallback(
    (location: OrganizationLocation): LocationOption => {
      return {
        value: location.name,
        id: location.id,
        campusName: location.campus?.name,
        label: renderLabel(location),
      };
    },
    []
  );

  const getOptions = useCallback(
    (locations: OrganizationLocation[]): GroupedLocationOption[] => {
      const filteredAndSortedRecentLocations =
        recentlySelectedLocationIds.reduce(
          (acc: OrganizationLocation[], key: string) => {
            const foundIndex = locations.findIndex((x) => x.id === key);
            if (foundIndex > -1) {
              acc.push(locations[foundIndex]);
            }
            return acc;
          },
          []
        );

      const otherLocations = locations
        .filter(
          (x) => recentlySelectedLocationIds.findIndex((y) => x.id === y) === -1
        )
        .sort(sortObjectAsc('name'));

      return [
        !!filteredAndSortedRecentLocations.length && {
          label: t('location_selector.recently_used'),
          options: filteredAndSortedRecentLocations.map((location) =>
            renderItem(location)
          ),
        },
        {
          label: filteredAndSortedRecentLocations.length
            ? t('location_selector.other_locations')
            : t('location_selector.all_locations'),
          options: otherLocations.map((location) => renderItem(location)),
        },
      ];
    },
    [recentlySelectedLocationIds, renderItem, t]
  );

  const filterOptions = useCallback(
    (val: string, option: LocationOption | undefined) => {
      //ignore grouped options
      if (option && option.options) {
        return false;
      }
      const locationFound = option?.value
        ?.toString()
        .toLocaleLowerCase()
        .includes(val.toLowerCase());
      const campusFound = option?.campusName
        ?.toString()
        .toLocaleLowerCase()
        .includes(val.toLowerCase());

      if (locationFound || campusFound) {
        return true;
      }
      return false;
    },
    []
  );
  const onSelect = useCallback(
    (_, option: LocationOption) => {
      const selectedOption = locationData.locations.find(
        (location) => location.id === option.key
      );
      if (selectedOption) {
        setSelectedLocationId(selectedOption.id);
      }
    },
    [locationData, setSelectedLocationId]
  );

  if (locationData.locations.length > 5) {
    return (
      <Select
        style={{ maxWidth: 200, minWidth: 180 }}
        showSearch
        value={locationDisplayLine}
        placeholder={t('location_selector.placeholder')}
        filterOption={filterOptions}
        onSelect={onSelect}
      >
        {getOptions(locationData.locations).map((optGroup) => {
          return (
            optGroup && (
              <Select.OptGroup key={optGroup.label} label={optGroup.label}>
                {optGroup.options.map((option) => {
                  return (
                    <Select.Option
                      value={option.value}
                      key={option.id}
                      campusName={option.campusName}
                    >
                      {option.label}
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            )
          );
        })}
      </Select>
    );
  }

  return (
    <Dropdown menu={menuProps}>
      <Button>
        <Text
          style={{
            minWidth: 150,
            maxWidth: 170,
            textAlign: 'left',
          }}
          ellipsis={{ tooltip: locationDisplayLine }}
        >
          <div>{locationDisplayLine}</div>
        </Text>
      </Button>
    </Dropdown>
  );
}
