import { createHttpLink } from '@apollo/client';
import { config } from 'config';
import fetch from 'cross-fetch';

export const httpLink = createHttpLink({
  uri: config.gqlUrl ?? '',
  fetch,
  fetchOptions: {
    keepalive: true,
    keepaliveTimeout: 31000, // 31 second keepalive timeout
  },
});
