import { useMemo } from 'react';
import { Button, Dropdown, Typography } from '@robinpowered/ui-kit';
import { useAuthContext, useMapControlsContext } from 'contexts';
import ChevronDown from '@robinpowered/icons/ChevronDownOutline';
import {
  assignmentToolUrl,
  quickEditAssignmentToolUrl,
  scenarioPlanningAssignmentToolUrl,
  layoutToolUrl,
} from 'constants/url';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useAvailableFloors } from '../hooks/useGetAvailableFloors';

export function ManageFloorDropdown(): JSX.Element | null {
  const { currentOrg } = useAuthContext();
  const { t } = useTranslation('mapControls');
  const { selectedLevelId, selectedLocationId } = useMapControlsContext();
  const {
    permissions: { canManageOfficeAccess },
  } = useAuthContext();

  const {
    data: { canAccessATForCurrentFloor },
  } = useAvailableFloors(selectedLocationId);

  const { value: newATFFEnabled } = useFeatureFlag('tanuki(new-AT)', false);
  const { value: splitATSPEnabled } = useFeatureFlag(
    'tanuki(split-AT-SP)',
    false
  );

  const splitAssignmentsToolAndSpacePlanning =
    splitATSPEnabled && canAccessATForCurrentFloor;
  const showUnifiedAssignmentsTool =
    !splitATSPEnabled && newATFFEnabled && canAccessATForCurrentFloor;

  const showLayoutTool = canManageOfficeAccess;

  // Check if we have any options to show in the dropdown
  const hasAnyManageOptions =
    showLayoutTool ||
    showUnifiedAssignmentsTool ||
    splitAssignmentsToolAndSpacePlanning;

  const orgSlug = useMemo(
    () => currentOrg?.slug || currentOrg?.id || '',
    [currentOrg]
  );

  const layoutUrl = useMemo(() => {
    return orgSlug && selectedLevelId
      ? layoutToolUrl(orgSlug, selectedLevelId)
      : undefined;
  }, [orgSlug, selectedLevelId]);

  const assignmentUrl = useMemo(() => {
    return orgSlug && selectedLevelId
      ? assignmentToolUrl(orgSlug, selectedLevelId)
      : undefined;
  }, [orgSlug, selectedLevelId]);

  const quickEditAssignmentUrl = useMemo(() => {
    return orgSlug && selectedLevelId
      ? quickEditAssignmentToolUrl(orgSlug, selectedLevelId)
      : undefined;
  }, [orgSlug, selectedLevelId]);

  const scenarioPlanningAssignmentUrl = useMemo(() => {
    return orgSlug && selectedLevelId
      ? scenarioPlanningAssignmentToolUrl(orgSlug, selectedLevelId)
      : undefined;
  }, [orgSlug, selectedLevelId]);

  const items = useMemo(() => {
    const itemsList = [];

    if (showUnifiedAssignmentsTool) {
      itemsList.push({
        key: '0',
        label: (
          <a rel="noopener noreferrer" href={assignmentUrl}>
            {t('manage_floor.assignments')}
          </a>
        ),
      });
    }

    if (splitAssignmentsToolAndSpacePlanning) {
      itemsList.push(
        ...[
          {
            key: '1',
            label: (
              <a rel="noopener noreferrer" href={quickEditAssignmentUrl}>
                {t('manage_floor.assignments_quick_edit')}
              </a>
            ),
          },
          {
            key: '2',
            label: (
              <a rel="noopener noreferrer" href={scenarioPlanningAssignmentUrl}>
                {t('manage_floor.assignments_scenario_planning')}
              </a>
            ),
          },
        ]
      );
    }

    if (showLayoutTool) {
      itemsList.push({
        key: '3',
        label: <a href={layoutUrl}>{t('manage_floor.layout')}</a>,
      });
    }

    return itemsList;
  }, [
    t,
    assignmentUrl,
    quickEditAssignmentUrl,
    scenarioPlanningAssignmentUrl,
    layoutUrl,
    showUnifiedAssignmentsTool,
    splitAssignmentsToolAndSpacePlanning,
    showLayoutTool,
  ]);

  if (!hasAnyManageOptions) {
    return null;
  }

  return (
    <Dropdown menu={{ items }}>
      <Button data-testid="manage-floor-dropdown">
        <Typography.Text
          style={{
            textAlign: 'center',
          }}
        >
          <div>{t('manage_floor.edit_floor')}</div>
        </Typography.Text>
        <ChevronDown size={12} />
      </Button>
    </Dropdown>
  );
}
