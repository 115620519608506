import styled from '@emotion/styled';
import { Typography } from '@robinpowered/ui-kit';
import { CalendarSolid, ClockSolid } from '@robinpowered/icons';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { getLongTimeZoneName, LocationDateTimeMoment } from 'utils';
import { SHORT_DATE_FORMAT, SHORT_TIME_FORMAT } from 'constants/timeFormat';

const { Text } = Typography;
interface ReservationDetailsProps {
  startTime: LocationDateTimeMoment;
  endTime: LocationDateTimeMoment;
  timezone: string | null | undefined;
}

export function ReservationDetails({
  startTime,
  endTime,
  timezone,
}: ReservationDetailsProps) {
  const isValidTimezone = timezone && moment.tz.names().includes(timezone);

  const longReservationTimezoneName = useMemo(() => {
    return getLongTimeZoneName(timezone);
  }, [timezone]);

  if (!isValidTimezone) {
    return null;
  }

  const formattedStartTime = startTime.format(SHORT_TIME_FORMAT);
  const formattedEndTime = endTime.format(SHORT_TIME_FORMAT);
  const formattedStartDate = `${startTime.format(SHORT_DATE_FORMAT)}`;
  return (
    <ReservationContainer>
      <ReservationDetailsContainer>
        <DateWrapper>
          <DateContainer>
            <DateIcon />
            <Text>{formattedStartDate}</Text>
          </DateContainer>
        </DateWrapper>

        <ReservationTimeContainer>
          <TimeContainer>
            <ClockIcon />
            <TimeText>
              {formattedStartTime}
              {endTime && ` - ${formattedEndTime}`}
            </TimeText>
          </TimeContainer>
          {!!longReservationTimezoneName && (
            <TimezoneContainer>
              <TimezoneText>{longReservationTimezoneName}</TimezoneText>
            </TimezoneContainer>
          )}
        </ReservationTimeContainer>
      </ReservationDetailsContainer>
    </ReservationContainer>
  );
}

const ReservationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const ReservationDetailsContainer = styled(Text)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const ReservationTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Padding-paddingXS, 8px);
  align-self: stretch;
`;

const ClockIcon = styled(ClockSolid)`
  width: 16px;
  height: 16px;
  fill-opacity: 0.55;
`;

const TimeText = styled(Text)`
  flex: 1 0 0;
`;

const TimezoneContainer = styled.div`
  display: flex;
  padding-left: 24px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
`;

const TimezoneText = styled(Text)`
  flex: 1 0 0;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingXS, 8px);
`;
const DateContainer = styled.div`
  display: flex;
  gap: var(--Space-Padding-paddingXS, 8px);
`;

const DateIcon = styled(CalendarSolid)`
  width: 16px;
  height: 16px;
  fill-opacity: 0.55;
  margin-top: 2px;
`;
