import { useEditDeskReservationId, useEditDeskNewDeskId } from 'atoms/editDesk';
import {
  useGetResourceEndLocationTimeOnly,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import { SidebarFooter } from 'components/global/sidebar';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useEditBooking } from '../graphql/useEditBooking';
import { UpdateMyReservationDeskAndTimeFrameInput } from 'generated';
import { message } from '@robinpowered/ui-kit';
import { useOnCompleted } from './hooks/useOnCompleted';

export const EditSummaryViewFooter: FC<{
  hasDeskBeenEdited: boolean;
  hasStartTimeBeenEdited: boolean;
  hasEndTimeBeenEdited: boolean;
  isBookable: boolean;
  loading: boolean;
  onCancel: () => void;
}> = ({
  hasDeskBeenEdited,
  hasStartTimeBeenEdited,
  hasEndTimeBeenEdited,
  isBookable,
  loading,
  onCancel,
}) => {
  const { t } = useTranslation('deskDetails');
  const { timezone } = useTimezone();

  const editReservationId = useEditDeskReservationId();
  const newDeskId = useEditDeskNewDeskId();
  const startTimes = useGetStartTimesForSelectedDates();
  const endTime = useGetResourceEndLocationTimeOnly();

  const hasBeenEdited = useMemo(() => {
    return hasDeskBeenEdited || hasStartTimeBeenEdited || hasEndTimeBeenEdited;
  }, [hasDeskBeenEdited, hasEndTimeBeenEdited, hasStartTimeBeenEdited]);

  const { editBooking, loading: submitting } = useEditBooking();

  const onCompleted = useOnCompleted();

  const onSaveChanges = useCallback(() => {
    if (!editReservationId) {
      void message.error(t('edit_desk_reservation.failure'));
      return;
    }

    const input: UpdateMyReservationDeskAndTimeFrameInput = {
      reservationId: editReservationId,
    };

    if (hasDeskBeenEdited) {
      input.seatId = Number(newDeskId);
    }

    if (hasStartTimeBeenEdited) {
      const startTime = startTimes?.[0];
      if (!startTime) {
        void message.error(t('edit_desk_reservation.failure'));
        return;
      }

      input.start = {
        dateTime: startTime.toISOString(),
        timeZone: timezone,
      };
    }

    if (hasEndTimeBeenEdited) {
      if (!endTime) {
        void message.error(t('edit_desk_reservation.failure'));
        return;
      }

      input.end = {
        dateTime: endTime.toISOString(),
        timeZone: timezone,
      };
    }

    void editBooking({
      variables: {
        input,
      },
      onCompleted,
      onError: () => {
        void message.error(t('edit_desk_reservation.failure'));
      },
    });
  }, [
    editBooking,
    editReservationId,
    endTime,
    hasDeskBeenEdited,
    hasEndTimeBeenEdited,
    hasStartTimeBeenEdited,
    onCompleted,
    startTimes,
    t,
    timezone,
    newDeskId,
  ]);

  return (
    <SidebarFooter
      actions={{
        primary: {
          label: t('edit_desk_reservation.footer.save_changes'),
          onClick: onSaveChanges,
          disabled: loading || !hasBeenEdited || !isBookable,
          loading: submitting,
        },
        secondary: {
          label: t('edit_desk_reservation.footer.cancel'),
          onClick: onCancel,
          disabled: false,
        },
      }}
    />
  );
};
