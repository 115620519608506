import { useState, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button, message, Skeleton, Typography } from '@robinpowered/ui-kit';
import {
  useGetSpaceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useStartTimeIsNow,
  useTimezone,
} from 'atoms/resource';
import { momentToLocationDateTime } from 'utils';
import { useAvailabilityPillStyles, useBookingComposer } from './hooks';
import { AvailabilityPill } from './SpaceAvailabilityPill';
import { useSpaceWindowedAvailability } from '../graphql/useSpaceWindowedAvailability';
import { useAmplitude, useAuthContext, useMapControlsContext } from 'contexts';
import { redirectToOldSpaceDetails } from 'utils/legacyDashboard';
import {
  Availability_Pill_VERY_SHORT_TIME_FORMAT,
  Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE,
  Availability_Pill_SHORT_TIME_FORMAT,
  TIME_FORMAT_24H,
} from 'constants/index';
import { useLocationWorkingHours } from 'hooks/useLocationWorkingHours';
import { PlaceUnavailableReason } from 'generated';
import { AmplitudeEvents } from 'constants/amplitudeEvents';
import { useUserLocale } from 'hooks';

const { Title } = Typography;

type AvailabilityDetailsProps = {
  spaceId: string;
};

export const SpaceAvailabilityPills = ({
  spaceId,
}: AvailabilityDetailsProps) => {
  const { t } = useTranslation('spaceDetails');
  const { trackEvent } = useAmplitude();
  const { timezone } = useTimezone();
  const { currentOrg } = useAuthContext();
  const startTimeIsNow = useStartTimeIsNow();
  const { location, loading: locationLoading } = useLocationWorkingHours();
  const { selectedLocationId } = useMapControlsContext();
  const { linkToComposer } = useBookingComposer();
  const endTimes = useGetSpaceEndTimesForSelectedDates();
  const startTimes = useGetStartTimesForSelectedDates();
  const { use24HourTime } = useUserLocale();

  const pillStyles = useAvailabilityPillStyles();

  const [isRedirecting, setIsRedirecting] = useState(false);

  const { spaceDetails, loading } = useSpaceWindowedAvailability(
    spaceId,
    startTimes?.[0]
  );

  const handlePillClick = useCallback(
    (start: string, end: string) => {
      setIsRedirecting(true);
      trackEvent(AmplitudeEvents.SPACE_DETAILS_AVAILABILITY_PILL_CLICK);
      linkToComposer(spaceId, start, end);
    },
    [trackEvent, linkToComposer, spaceId]
  );

  const onViewMore = useCallback(() => {
    if (
      !currentOrg ||
      currentOrg.slug === '' ||
      !startTimes?.[0] ||
      !endTimes?.[0] ||
      !selectedLocationId
    ) {
      void message.error(t('error'));
      return;
    }
    setIsRedirecting(true);
    trackEvent(AmplitudeEvents.SPACE_DETAILS_VIEW_MORE_TIMES);
    redirectToOldSpaceDetails(
      currentOrg?.slug ?? '',
      spaceId,
      selectedLocationId ?? ''
    );
  }, [
    currentOrg,
    startTimes,
    endTimes,
    selectedLocationId,
    trackEvent,
    spaceId,
    t,
  ]);

  const renderAvailabilityPills = useCallback(() => {
    if (loading) {
      return <Skeleton />;
    }
    return spaceDetails?.meetingRoomWindowedAvailability?.map((slot, index) => {
      const { start, end, status } = slot;

      if (!start || !end) {
        return null;
      }

      const isDisabled = status !== 'AVAILABLE';
      const startMoment = moment(start);

      const startTimeFormat = use24HourTime
        ? TIME_FORMAT_24H
        : startMoment.minutes() === 0
        ? Availability_Pill_VERY_SHORT_TIME_FORMAT
        : Availability_Pill_SHORT_TIME_FORMAT;
      const endTimeFormat = use24HourTime
        ? TIME_FORMAT_24H
        : Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE;

      const startTime =
        index === 0 && startTimeIsNow
          ? t('space_availability.now')
          : momentToLocationDateTime(startMoment, timezone).format(
              startTimeFormat
            );
      const endTime = momentToLocationDateTime(moment(end), timezone).format(
        endTimeFormat
      );
      const timeRange = `${startTime} - ${endTime}`;

      return (
        <AvailabilityPill
          key={index}
          disabled={isDisabled || isRedirecting}
          {...pillStyles}
          onClick={() => handlePillClick(start, end)}
        >
          {timeRange}
        </AvailabilityPill>
      );
    });
  }, [
    loading,
    spaceDetails,
    startTimeIsNow,
    timezone,
    isRedirecting,
    pillStyles,
    handlePillClick,
    use24HourTime,
    t,
  ]);

  if (locationLoading) {
    return (
      <AvailbilityDetails>
        <Skeleton active />
      </AvailbilityDetails>
    );
  }

  // If the location is unavailable for any reason other than max capacity reached, don't show the availability pills
  // capacity relates to desks, not spaces
  if (
    location?.state?.availability?.unavailableReasons &&
    location.state.availability.unavailableReasons.length > 0 &&
    location.state.availability.unavailableReasons.some(
      (reason) => reason !== PlaceUnavailableReason.MaxCapacityReached
    )
  ) {
    return null;
  }

  return (
    <AvailbilityDetails>
      <Title level={5}>{t('space_availability.availability')}</Title>
      <AvailabilityPillsContainer>
        {renderAvailabilityPills()}
      </AvailabilityPillsContainer>
      <ViewScheduleButton onClick={onViewMore} disabled={isRedirecting}>
        {t('space_availability.view_more_times')}
      </ViewScheduleButton>
    </AvailbilityDetails>
  );
};

const AvailbilityDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  max-width: 100%;
  box-sizing: border-box;
`;

const AvailabilityPillsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 8px;
  align-self: stretch;
  max-width: 100%;
  box-sizing: border-box;
`;

const ViewScheduleButton = styled(Button)`
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
