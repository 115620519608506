import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Space, theme, Typography } from '@robinpowered/ui-kit';
import { Add } from '@robinpowered/icons';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import {
  useSetStartTime,
  useSetEndTimeFromStartTimeForSpaces,
  useSetEndTimeFromStartTimeForResources,
  startTimeState,
} from 'atoms/resource';
import { useCurrentFilter } from 'atoms/mapInteractions';
import { rightSidebarView } from 'atoms/sidebar/atoms';
import { LocationDateTimeMoment } from '../../../utils';
import { LocationSelector } from './LocationSelector';
import { LevelSelector } from './LevelSelector';
import { DatePickerComponent, EndTimeDropdown } from '../../global/controls';
import { StartTimeDropdown } from 'components/global/controls/StartTimeDropdown';
import { OfficeClosedBanner } from 'components/map/OfficeClosedBanner';
import { OfficeHoursModal } from '../OfficeHoursModal';
import { useLocationWorkingHours } from 'hooks/useLocationWorkingHours';
import { ManageFloorDropdown } from './ManageFloorDropdown';
import { isLocationClosed } from 'utils';
import { AmplitudeEvents } from 'constants/amplitudeEvents';
import { useAmplitude } from 'contexts';

export const MapControls = () => {
  const { t } = useTranslation('mapControls');
  const { trackEvent } = useAmplitude();
  const { useToken } = theme;
  const { token } = useToken();
  const currentFilter = useCurrentFilter();

  const [officeHoursModalOpen, setOfficeHoursModalOpen] = useState(false);
  const [officeIsOpen, setOfficeIsOpen] = useState<boolean>(true);
  const [manuallyShowEndTime, setManuallyShowEndTime] =
    useState<boolean>(false);

  const setStartTime = useSetStartTime();
  const setEndTimeForSpaces = useSetEndTimeFromStartTimeForSpaces();
  const setEndTimeForDesks = useSetEndTimeFromStartTimeForResources();
  const { location, loading: locationLoading } = useLocationWorkingHours();

  const selectedStartTime = useRecoilValue(startTimeState);
  const sidebarView = useRecoilValue(rightSidebarView);
  const displayTimeFilters = useMemo(() => {
    return !sidebarView || sidebarView === 'spaces';
  }, [sidebarView]);

  useEffect(() => {
    if (location && !locationLoading) {
      const isOpen = !isLocationClosed(
        location?.state?.availability?.unavailableReasons
      );
      setOfficeIsOpen(isOpen);
    }
  }, [location, locationLoading]);

  const showEndTime = useMemo(
    () => manuallyShowEndTime || !!currentFilter,
    [currentFilter, manuallyShowEndTime]
  );

  const toggleManuallyShowEndTime = useCallback(() => {
    setManuallyShowEndTime((prev) => !prev);

    trackEvent(AmplitudeEvents.MAP_CONTROLS_END_TIME_BUTTON, {
      showEndTime: manuallyShowEndTime,
    });
  }, [manuallyShowEndTime, trackEvent]);

  const handleStartTimeSelect = useCallback(
    (selectedTime: LocationDateTimeMoment) => {
      setStartTime(selectedTime);
      setEndTimeForSpaces(selectedTime);
      setEndTimeForDesks(selectedTime);
    },
    [setStartTime, setEndTimeForSpaces, setEndTimeForDesks]
  );

  const openOfficeHoursModal = () => {
    setOfficeHoursModalOpen(true);
  };

  const closeOfficeHoursModal = () => {
    setOfficeHoursModalOpen(false);
  };

  return (
    <Fragment>
      <MapControlsContainer style={{ backgroundColor: token.colorBgElevated }}>
        <Space size={'small'} align="center">
          <LocationSelector />

          <LevelSelector />

          {displayTimeFilters && (
            <Fragment>
              <DatePickerComponent />

              <StartTimeDropdown onTimeSelect={handleStartTimeSelect} />

              {showEndTime && (
                <>
                  {/* Dash between TimeDropdown components */}
                  {/* Wrapped in t function to sidestep no hardcoded content lint error */}
                  <Typography.Text>{t('-')}</Typography.Text>

                  <EndTimeDropdown type={currentFilter} />
                  {!currentFilter && (
                    <Button type="text" onClick={toggleManuallyShowEndTime}>
                      <Typography.Text>{t(`CLEAR`)}</Typography.Text>
                    </Button>
                  )}
                </>
              )}

              {!showEndTime && (
                <Button type="text" onClick={toggleManuallyShowEndTime}>
                  <ButtonContainer>
                    <Add size={12} />
                    <Typography.Text>{t(`END_TIME`)}</Typography.Text>
                  </ButtonContainer>
                </Button>
              )}
            </Fragment>
          )}
        </Space>
        <ManageFloorDropdown />
      </MapControlsContainer>
      {!officeIsOpen && !locationLoading && (
        <Fragment>
          <OfficeClosedBanner openOfficeHoursModal={openOfficeHoursModal} />
          <OfficeHoursModal
            onClose={closeOfficeHoursModal}
            isOpen={officeHoursModalOpen}
            location={location}
            selectedStartTime={selectedStartTime}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

const MapControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;
