import { gql, useQuery } from '@apollo/client';
import { i18n } from '../i18n';
import { useEffect } from 'react';
import moment from 'moment';
import { GetUserLocaleQuery } from 'generated/graphql';
import { useAuthContext } from 'contexts';

export const GET_USER_LOCALE_QUERY = gql`
  query GetUserLocale($userId: ID!) {
    getMyLanguageTag {
      languageTag
    }
    getUserSetting(userId: $userId, settingSlug: "24-hour-time") {
      value
    }
  }
`;

type UserLocale = {
  loading: boolean;
  language: string;
  use24HourTime: boolean;
};

export const useUserLocale = (): UserLocale => {
  const { currentUser } = useAuthContext();
  const result = useQuery<GetUserLocaleQuery>(GET_USER_LOCALE_QUERY, {
    variables: {
      userId: currentUser?.id,
    },
  });
  const language = result.data?.getMyLanguageTag.languageTag ?? 'en';
  const loading = language !== i18n.language;
  const use24HourTime = result.data?.getUserSetting?.value
    ? JSON.parse(result.data?.getUserSetting.value)
    : false;

  useEffect(() => {
    if (loading) {
      void Promise.resolve()
        .then(() => language !== 'en' && import(`moment/locale/${language}`))
        .then(() => i18n.changeLanguage(language))
        .then(() => moment.locale(language));
    }
  }, [language, loading]);

  return { language, loading, use24HourTime };
};
