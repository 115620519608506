import { gql, useQuery } from '@apollo/client';
import { useSelectedLocation, useTimezone } from 'atoms/resource';
import { useCallback } from 'react';
import {
  calculateEndOfDay,
  findCustomHourForDate,
  getWorkingHoursForDay,
  LocationDateTimeMoment,
} from 'utils';
import { UseEndTimeQuery, UseEndTimeQueryVariables } from 'generated';
import { SELECTED_DATE_FORMAT } from '../constants';
import { useApolloContext } from 'contexts';

const OFFICE_HOURS_QUERY = gql`
  query useEndTime($locationId: ID!) {
    getLocationById(id: $locationId) {
      id
      timezone
      workingHours {
        day
        timeFrames {
          start
          end
        }
      }
      customWorkingHours {
        id
        date
        timeFrames {
          start
          end
        }
      }
    }
  }
`;

export const useEndTime = () => {
  const { timezone } = useTimezone();
  const { tenantId } = useApolloContext();
  const [selectedLocation] = useSelectedLocation();

  const { data } = useQuery<UseEndTimeQuery, UseEndTimeQueryVariables>(
    OFFICE_HOURS_QUERY,
    {
      skip: !selectedLocation || !tenantId,
      variables: { locationId: selectedLocation || '' },
    }
  );

  const calculateEndTimeForResources = useCallback(
    (startTime: LocationDateTimeMoment) => {
      const workingHours = getWorkingHoursForDay(
        data?.getLocationById?.workingHours || [],
        startTime.day()
      );

      const customHours = findCustomHourForDate(
        data?.getLocationById?.customWorkingHours || [],
        startTime.format(SELECTED_DATE_FORMAT),
        timezone
      );

      return calculateEndOfDay(startTime, customHours || workingHours);
    },
    [
      data?.getLocationById?.workingHours,
      data?.getLocationById?.customWorkingHours,
      timezone,
    ]
  );

  return { calculateEndTimeForResources };
};
