import { ResourceTags } from '../ResourceTags';
import { Skeleton, Typography } from '@robinpowered/ui-kit';
import { useSpaceAmenitiesDetails } from './graphql/useSpaceAmenitiesDetails';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useAuthContext } from 'contexts';
import { config } from 'config';
import { ExternalLink } from '../../global/ExternalLink';
import styled from '@emotion/styled';

export function SpaceAmenities({
  spaceId,
}: {
  spaceId: string | undefined;
}): JSX.Element | null {
  const { t } = useTranslation('spaceDetails');
  const { orgSlug } = useAuthContext();

  const { loading, amenities, canManage } = useSpaceAmenitiesDetails(spaceId);

  const manageAmenitiesUrl = useMemo(() => {
    if (!spaceId || !orgSlug) {
      return;
    }

    return `${config.dashboardUrl}/${orgSlug}/settings/offices/spaces/${spaceId}`;
  }, [spaceId, orgSlug]);

  if (loading) {
    return <Skeleton data-testid="space-amenities-skeleton" active />;
  }

  if ((!amenities || amenities.length === 0) && !canManage) {
    return null;
  }

  return (
    <AmenitiesContainer>
      <Typography.Title level={5}>
        {t('space_details.amenities.title')}
      </Typography.Title>

      {amenities && amenities.length > 0 && (
        <ResourceTags resources={amenities} />
      )}

      {canManage && manageAmenitiesUrl && (
        <ExternalLink href={manageAmenitiesUrl}>
          {t('space_details.amenities.manage_link')}
        </ExternalLink>
      )}
    </AmenitiesContainer>
  );
}

const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
