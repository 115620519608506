import { ReservationDetails } from './ReservationDetails';
import styled from '@emotion/styled';
import { UserDetails } from 'components/resource-details/desk/UserDetails';
import { LocationDateTimeMoment } from 'utils';
import { ResourceReservationCardActions } from './ReservationCardActions';
import { CheckedInStatus } from './CheckedInStatus';

interface ReservationCardProps {
  reservation:
    | {
        isConfirmed: boolean;
        startTime: LocationDateTimeMoment;
        endTime: LocationDateTimeMoment;
        id: string;
        reservee: {
          name: string;
          email: string;
          avatar: string;
        };
      }
    | undefined;
  timeZone: string | null | undefined;
  cancelReservation: () => void;
  checkinReservation: () => void;
  isCancelling: boolean;
  isCheckingIn: boolean;
}

export function ReservationCard({
  reservation,
  timeZone,
  cancelReservation,
  checkinReservation,
  isCancelling,
  isCheckingIn,
}: ReservationCardProps) {
  return (
    <ReservationsContainer key={reservation?.id}>
      <ReservationContainer>
        <ReservationSubContainer>
          <UserDetails
            avatarSrc={reservation?.reservee.avatar}
            name={reservation?.reservee.name}
            email={reservation?.reservee.email}
          />

          {reservation && (
            <>
              <ReservationDetails
                startTime={reservation.startTime}
                endTime={reservation.endTime}
                timezone={timeZone}
              />
              <CheckedInStatus isConfirmed={reservation.isConfirmed} />
              <ResourceReservationCardActions
                cancelReservation={cancelReservation}
                checkinReservation={checkinReservation}
                isConfirmed={reservation.isConfirmed}
                isCancelling={isCancelling}
                isCheckingIn={isCheckingIn}
              />
            </>
          )}
        </ReservationSubContainer>
      </ReservationContainer>
    </ReservationsContainer>
  );
}

const ReservationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;

  border-radius: var(--Border-Radius-borderRadius, 4px);
  border: 1px solid var(--Colors-Neutral-Border-colorBorder, #d9d9d9);
`;

const ReservationContainer = styled.div`
  display: flex;
  padding: var(--Space-Padding-paddingSM, 12px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
`;

const ReservationSubContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
  align-self: stretch;
`;
