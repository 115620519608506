import { Skeleton } from '@robinpowered/ui-kit';
import { ReservationCard } from './presentation/ReservationCard';
import { CustomResourceReservation } from '../hooks/graphql/useGetResourceReservationsByResourceId';
import { dateTimeStringToLocationDateTimeMoment } from 'utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useGetStartTimesForSelectedDates, useTimezone } from 'atoms/resource';
import { useCancelResourceReservation } from '../hooks/graphql/useCancelResourceReservation';
import { useAuthContext } from 'contexts';
import { useConfirmResourceReservation } from '../hooks/graphql/useConfirmResourceReservation';
import { ConfirmationClientType } from 'generated';

const CustomResourceReservationCard = ({
  reservation,
}: {
  reservation: CustomResourceReservation;
}) => {
  const { timezone } = useTimezone();
  const { currentOrg } = useAuthContext();
  const { cancelResourceReservation, isCancellingResource } =
    useCancelResourceReservation();
  const { confirmResourceReservation, isLoading: isConfirmingResource } =
    useConfirmResourceReservation();
  const durationMoment = moment.duration(reservation?.duration);
  const reservationdata = {
    startTime: dateTimeStringToLocationDateTimeMoment(
      reservation.date,
      reservation.startTime,
      timezone
    ),
    endTime: dateTimeStringToLocationDateTimeMoment(
      reservation.date,
      reservation.startTime,
      timezone
    ).add(durationMoment),
    timeZone: timezone,
    isConfirmed: reservation.isConfirmed,
    id: reservation?.id,
    reservee: {
      name: reservation.accountReservee.name || '',
      email: reservation.accountReservee.primaryEmail?.email || '',
      avatar: reservation.accountReservee.avatar || '',
    },
  };
  const cancelReservation = async () => {
    if (currentOrg?.id) {
      await cancelResourceReservation({
        variables: {
          reservationId: reservation.id,
          organizationId: currentOrg.id,
          resourceId: reservation.resourceInstanceId,
        },
      });
    }
  };
  const confirmReservation = async () => {
    if (currentOrg?.id) {
      await confirmResourceReservation({
        variables: {
          reservationId: reservation.id,
          organizationId: currentOrg.id,
          startDate: reservation.date,
          startTime: reservation.startTime,
          clientType: ConfirmationClientType.DashboardWeb,
        },
      });
    }
  };

  return (
    <ReservationCard
      key={reservationdata.id}
      reservation={reservationdata}
      timeZone={timezone}
      cancelReservation={cancelReservation}
      checkinReservation={confirmReservation}
      isCancelling={isCancellingResource}
      isCheckingIn={isConfirmingResource}
    />
  );
};

export function CustomResourceReservations({
  reservations,
  loading,
}: {
  reservations: CustomResourceReservation[];
  loading: boolean;
}): JSX.Element | null {
  const startTime = useGetStartTimesForSelectedDates();
  const reservationCards = useMemo(() => {
    let reservationsToShow: JSX.Element[] = [];
    // TODO: show scheduled/assigned reservations when its available in CRC
    // only display reservations when a single date is selected
    if (startTime && startTime?.length === 1) {
      reservationsToShow = reservations.map((reservation) => {
        return (
          <CustomResourceReservationCard
            key={reservation.id}
            reservation={reservation}
          />
        );
      });
    }
    return reservationsToShow;
  }, [reservations, startTime]);

  if (loading) return <Skeleton active />;

  if (!reservationCards.length) return null;

  return <>{reservationCards}</>;
}
