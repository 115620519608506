import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import {
  ResourceDetailsByIdsQuery,
  ResourceDetailsByIdsQueryVariables,
} from 'generated';

export type CustomResource = ResourceDetailsByIdsQuery['resourceDetails'][0];

export type CustomResourceBookingType =
  ResourceDetailsByIdsQuery['resourceDetails'][0]['bookingType'];

const GET_RESOURCES_BY_ID = gql`
  query resourceDetailsByIds($organizationId: ID!, $resourceIds: [ID!]!) {
    resourceDetails(
      organizationId: $organizationId
      resourceIds: $resourceIds
    ) {
      bookingType {
        sunday
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
      }
      customResourceInstanceId
      originatingCustomResourceTemplateId
      name
      subCategory
      amenityIds
      capacity
      note
      resourceGroupId
      resourceGroupName
      location {
        id
        name
      }
      floor {
        id
        name
      }
    }
  }
`;

export const useGetResourceById = (
  selectedResourceId: string | undefined
): {
  data: CustomResource | undefined;
  loading: boolean;
} => {
  const { currentOrg } = useAuthContext();
  const variables =
    selectedResourceId && currentOrg?.id
      ? {
          organizationId: currentOrg?.id,
          resourceIds: [selectedResourceId],
        }
      : undefined;
  const { loading, data } = useQuery<
    ResourceDetailsByIdsQuery,
    ResourceDetailsByIdsQueryVariables
  >(GET_RESOURCES_BY_ID, {
    skip: !selectedResourceId || !currentOrg?.id,
    variables,
  });

  return {
    data: data?.resourceDetails[0] as CustomResource,
    loading,
  };
};
