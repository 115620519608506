import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import styled from '@emotion/styled';
import { Typography } from '@robinpowered/ui-kit';
import { ResourcePolicyConfiguration } from './hooks/graphql/useCustomResourceAmenitiesAndPolicies';

const { Text } = Typography;

export function CustomResourceBookingPolicies({
  policyConfiguration,
}: {
  policyConfiguration: ResourcePolicyConfiguration | null | undefined;
}): JSX.Element | null {
  const { t } = useTranslation('customResourceDetails');
  const policies = policyConfiguration;

  const {
    advanceBookingThreshold,
    maxBookingLength,
    checkInWindow,
    abandonedResourceProtectionThreshold,
    enforceLocalCheckinOnly,
    requireCheckIn,
  } = useMemo(() => {
    const localizeAdvanceBookingThreshold = policies?.advancedBookingThreshold
      ?.duration
      ? moment.duration(policies.advancedBookingThreshold.duration).humanize()
      : '';

    const localizeMaxBookingLength = policies?.maxBookingLength?.duration
      ? moment.duration(policies.maxBookingLength.duration).humanize()
      : '';

    const localizeCheckInWindow = policies?.advanceCheckInWindow?.duration
      ? moment.duration(policies.advanceCheckInWindow.duration).humanize()
      : '';

    const localizeAbandonedResourceProtection = policies
      ?.abandonedResourceProtection?.duration
      ? moment
          .duration(policies.abandonedResourceProtection.duration)
          .humanize()
      : '';

    const abandonedResourceProtectionEnabled =
      policies?.abandonedResourceProtection?.enabled;

    return {
      advanceBookingThreshold:
        policies?.advancedBookingThreshold?.enabled &&
        policies?.advancedBookingThreshold?.duration
          ? localizeAdvanceBookingThreshold
          : null,
      maxBookingLength:
        policies?.maxBookingLength?.enabled &&
        policies?.maxBookingLength?.duration
          ? localizeMaxBookingLength
          : null,
      checkInWindow:
        policies?.advanceCheckInWindow?.enabled &&
        policies?.advanceCheckInWindow?.duration
          ? localizeCheckInWindow
          : null,
      abandonedResourceProtectionEnabled,
      abandonedResourceProtectionThreshold:
        abandonedResourceProtectionEnabled &&
        policies?.abandonedResourceProtection?.duration
          ? localizeAbandonedResourceProtection
          : null,
      requireCheckIn: policies?.requireCheckIn,
      enforceLocalCheckinOnly: policies?.localCheckInOnly,
    };
  }, [policies]);

  const hasPolicies = useMemo(
    () =>
      Boolean(
        advanceBookingThreshold ||
          maxBookingLength ||
          checkInWindow ||
          abandonedResourceProtectionThreshold ||
          enforceLocalCheckinOnly ||
          requireCheckIn
      ),
    [
      advanceBookingThreshold,
      maxBookingLength,
      checkInWindow,
      abandonedResourceProtectionThreshold,
      enforceLocalCheckinOnly,
      requireCheckIn,
    ]
  );

  if (!hasPolicies) return null;

  return (
    <BookingPoliciesContainer>
      <BookingPoliciesHeader>
        <BookingPoliciesHeaderContent type="secondary">
          {t('resource_details.policies_description')}
        </BookingPoliciesHeaderContent>
      </BookingPoliciesHeader>

      <BookingPoliciesList>
        {advanceBookingThreshold && (
          <BookingPolicy type="secondary">
            {t('resource_booking_policies.booking_threshold', {
              threshold: advanceBookingThreshold,
            })}
          </BookingPolicy>
        )}

        {maxBookingLength && (
          <BookingPolicy type="secondary">
            {t('resource_booking_policies.max_booking_length', {
              length: maxBookingLength,
            })}
          </BookingPolicy>
        )}

        {requireCheckIn && (
          <BookingPolicy type="secondary">
            {t('resource_booking_policies.require_check_in')}
          </BookingPolicy>
        )}

        {checkInWindow && (
          <BookingPolicy type="secondary">
            {t('resource_booking_policies.check_in_after', {
              time: checkInWindow,
            })}
          </BookingPolicy>
        )}

        {abandonedResourceProtectionThreshold && (
          <BookingPolicy type="secondary">
            {t('resource_booking_policies.check_in_before', {
              time: abandonedResourceProtectionThreshold,
            })}
          </BookingPolicy>
        )}

        {enforceLocalCheckinOnly && (
          <BookingPolicy type="secondary">
            {t('resource_booking_policies.local_check_in')}
          </BookingPolicy>
        )}
      </BookingPoliciesList>
    </BookingPoliciesContainer>
  );
}

const BookingPoliciesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const BookingPoliciesHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const BookingPoliciesHeaderContent = styled(Text)`
  && {
    margin: 0;
  }
`;

const BookingPoliciesList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  list-style-type: disc;
  padding-left: 1.5em;
`;

const BookingPolicy = styled(Text)`
  && {
    display: list-item;
    margin: 0;
  }
`;
