import { Button, message } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts';
import { redirectToOldComposer } from 'utils/legacyDashboard';
import { useState } from 'react';
import { momentToLocationDateTime } from 'utils';
import moment from 'moment';
import {
  useGetSpaceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';

type CreateRequestButtonProps = {
  spaceId: string | undefined;
  buttonType?: 'space_detail' | 'space_list';
};

export const CreateSpaceRequestButton = ({
  spaceId,
  buttonType = 'space_detail',
}: CreateRequestButtonProps) => {
  const { t } = useTranslation('spaceDetails');
  const { currentOrg } = useAuthContext();
  const { timezone } = useTimezone();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const endTimes = useGetSpaceEndTimesForSelectedDates();
  const startTimes = useGetStartTimesForSelectedDates();

  const handleCreateRequest = () => {
    if (!currentOrg || currentOrg.slug === '' || !spaceId) {
      void message.error(t('error'));
      return;
    }

    setIsRedirecting(true);

    redirectToOldComposer(
      currentOrg?.slug ?? '',
      spaceId,
      momentToLocationDateTime(moment(startTimes?.[0]), timezone),
      momentToLocationDateTime(moment(endTimes?.[0]), timezone)
    );
  };

  return (
    <Button
      onClick={handleCreateRequest}
      type={buttonType === 'space_detail' ? 'primary' : 'default'}
      disabled={isRedirecting}
    >
      {buttonType === 'space_detail'
        ? t('buttons.create_request')
        : t('space_list_item.request_to_book')}
    </Button>
  );
};
