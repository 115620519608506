import styled from '@emotion/styled';
import { TrashOutline } from '@robinpowered/icons';
import { Button } from '@robinpowered/ui-kit';
import { useTimezone } from 'atoms/resource';
import { CancelReservationModal } from 'components/cancel-reservation-modal';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { locationDateTimeMoment, momentToLocationDateTime } from 'utils';
import { Desk, Reservation } from '../../graphql';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'contexts';

interface CheckInButtonProps {
  reservation: Reservation;
  desk: Desk;
}

export const CancelDeskButton = ({ reservation, desk }: CheckInButtonProps) => {
  const { t } = useTranslation('deskDetails');
  const { timezone } = useTimezone();
  const { currentUser } = useAuthContext();

  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const isSeries = !!reservation.seriesId;

  const reservationInProgress = useMemo(() => {
    return locationDateTimeMoment(
      reservation.timeZone || timezone
    ).isSameOrAfter(
      momentToLocationDateTime(
        moment(reservation.startTime),
        reservation.timeZone || timezone
      )
    );
  }, [reservation, timezone]);

  const cancelMessage = useMemo(() => {
    if (!isSeries) {
      return reservationInProgress ? t('check_in.end') : t('check_in.cancel');
    } else {
      return reservationInProgress
        ? t('check_in.end_series')
        : t('check_in.cancel_series');
    }
  }, [isSeries, reservationInProgress, t]);

  return (
    <>
      <CancelButton
        data-testid="cancel-button"
        type="default"
        danger
        onClick={() => setCancelModalOpen(true)}
      >
        <RedTrashIconContainer>
          <TrashOutline />
        </RedTrashIconContainer>
        {cancelMessage}
      </CancelButton>
      <CancelReservationModal
        onClose={() => setCancelModalOpen(false)}
        isOpen={cancelModalOpen}
        onConfirm={() => setCancelModalOpen(false)}
        reservationInProgress={reservationInProgress}
        reservationUserId={reservation?.accountReservee?.user?.id}
        reservationUserName={reservation?.accountReservee?.user?.name}
        reservationStartDate={momentToLocationDateTime(
          moment(reservation?.startTime),
          timezone
        )}
        reservationTimezone={timezone}
        reservationId={reservation?.id}
        reservationSeriesId={reservation?.seriesId}
        deskName={desk ? desk.name : ''}
        buildingName={desk ? desk.location.name : ''}
        floorName={desk?.level?.name}
        isDelegated={currentUser?.id !== reservation?.accountReservee?.user?.id}
      />
    </>
  );
};

const CancelButton = styled(Button)`
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInline, 16px);
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const RedTrashIconContainer = styled.div`
  width: 16px;
  height: 16px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #e81c1c !important;
    }
  }
`;
