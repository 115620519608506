import styled from '@emotion/styled';
import { EditAlt } from '@robinpowered/icons';
import { Button } from '@robinpowered/ui-kit';
import { useSetEditDeskReservationId } from 'atoms/editDesk';
import { useSetIsCameraResetting, useSetMapMode } from 'atoms/mapInteractions';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const EditDeskButton: FC<{ reservationId: string }> = ({
  reservationId,
}) => {
  const { t } = useTranslation('deskDetails');

  const setMapMode = useSetMapMode();
  const setDeskSidebarView = useSetDeskSidebarView();
  const setEditDeskReservationId = useSetEditDeskReservationId();
  const setIsCameraResetting = useSetIsCameraResetting();

  const onEdit = useCallback(() => {
    setDeskSidebarView('edit-summary');
    setMapMode('edit-desk-reservation');
    setEditDeskReservationId(reservationId);
    setIsCameraResetting(true);
  }, [
    reservationId,
    setEditDeskReservationId,
    setIsCameraResetting,
    setMapMode,
    setDeskSidebarView,
  ]);

  return (
    <>
      <StyledEditDeskButton
        data-testid="edit-button"
        type="default"
        onClick={onEdit}
      >
        <EditAlt size={16} />
        {t('check_in.edit')}
      </StyledEditDeskButton>
    </>
  );
};

const StyledEditDeskButton = styled(Button)`
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInline, 16px);
  justify-content: center;
  align-items: center;
  flex: 1;
`;
