import styled from '@emotion/styled';
import { CancelReservationButton } from './CancelResourceButton';
import { useTranslation } from 'react-i18next';
import { Button } from '@robinpowered/ui-kit';

export function ResourceReservationCardActions({
  cancelReservation,
  checkinReservation,
  isCancelling,
  isCheckingIn,
  isConfirmed,
}: {
  cancelReservation: () => void;
  checkinReservation: () => void;
  isCancelling: boolean;
  isCheckingIn: boolean;
  isConfirmed: boolean;
}) {
  const { t } = useTranslation('customResourceDetails');
  return (
    <ResourceReservationCardButtonContainer>
      {!isConfirmed && (
        <StyledCheckInButton
          type="primary"
          onClick={checkinReservation}
          disabled={isCancelling}
          loading={isCheckingIn}
        >
          {t('resource_booking_controls.check_in')}
        </StyledCheckInButton>
      )}
      <BottomWrapper>
        {/* <EditResourceButton /> */}
        <CancelReservationButton
          cancelReservation={cancelReservation}
          isLoading={isCancelling}
          disabled={isCheckingIn}
        />
      </BottomWrapper>
    </ResourceReservationCardButtonContainer>
  );
}
const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
`;
const StyledCheckInButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: 132px !important;
`;

const ResourceReservationCardButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--Space-Margin-marginXS, 8px);
  align-self: stretch;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
`;
