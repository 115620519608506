import { useMemo } from 'react';
import { Collapse, Skeleton } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { CustomResourceAmenities } from './CustomResourceAmenities';
import { CustomResourceBookingPolicies } from './CustomResourceBookingPolicies';
import { useCustomResourceAmenitiesAndPoliciesData } from './hooks/graphql/useCustomResourceAmenitiesAndPolicies';

interface ResourceDetailsAmenitiesAndPoliciesProps {
  resourceId: string;
}

export const CustomResourceDetailsAmenitiesAndPolicies = ({
  resourceId,
}: ResourceDetailsAmenitiesAndPoliciesProps): JSX.Element | null => {
  const { t } = useTranslation('customResourceDetails');

  const { amenities, policyConfiguration, loading } =
    useCustomResourceAmenitiesAndPoliciesData(resourceId);

  const hasAmenities = useMemo(() => {
    return !loading && amenities && amenities.length > 0;
  }, [loading, amenities]);

  const hasPolicies = useMemo(() => {
    if (loading || !policyConfiguration) return false;

    const policies = policyConfiguration;

    return Boolean(
      (policies.advancedBookingThreshold?.enabled &&
        policies.advancedBookingThreshold?.duration) ||
        (policies.maxBookingLength?.enabled &&
          policies.maxBookingLength?.duration) ||
        (policies.advanceCheckInWindow?.enabled &&
          policies.advanceCheckInWindow?.duration) ||
        (policies.abandonedResourceProtection?.enabled &&
          policies.abandonedResourceProtection?.duration) ||
        policies.localCheckInOnly ||
        policies.requireCheckIn
    );
  }, [loading, policyConfiguration]);

  const collapseItems = useMemo(() => {
    const items = [];

    if (hasAmenities) {
      items.push({
        key: 'amenities',
        label: t('resource_details.amenities'),
        children: <CustomResourceAmenities amenities={amenities} />,
      });
    }

    if (hasPolicies) {
      items.push({
        key: 'policies',
        label: t('resource_details.policies'),
        children: (
          <CustomResourceBookingPolicies
            policyConfiguration={policyConfiguration}
          />
        ),
      });
    }

    return items;
  }, [hasAmenities, hasPolicies, amenities, policyConfiguration, t]);

  if (loading) {
    return <Skeleton active />;
  }

  // If no items to display, return null
  if (collapseItems.length === 0) {
    return null;
  }

  return <StyledCollapse items={collapseItems} />;
};

const StyledCollapse = styled(Collapse)`
  width: 100%;
`;
