import { gql, useQuery } from '@apollo/client';
import { useSelectedLevel } from 'atoms/resource';
import { useApolloContext } from 'contexts';
import {
  CustomResourceLayerIdsQuery,
  CustomResourceLayerIdsQueryVariables,
} from 'generated';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

const query = gql`
  query CustomResourceLayerIds($organizationId: ID!) {
    getCustomResourceTemplates(organizationID: $organizationId) {
      id
    }
  }
`;

export const useCustomResourceLayerIdsForBeta = () => {
  const [selectedLevelId] = useSelectedLevel();
  const { tenantId } = useApolloContext();

  const {
    value: customResourcesEnabled,
    loading: customResourcesEnabledLoading,
  } = useFeatureFlag('talos(office-V2-custom-resources-enabled)', false);

  // This may seem counterintuitive, but if the feature flag is enabled, we want to skip the query.
  // If the flag is enabled, it means we want to _show_ the custom resources layer.
  // Skipping the query will cause the hook to return an empty array, so nothing will be hidden.
  const skip =
    customResourcesEnabled ||
    customResourcesEnabledLoading ||
    !selectedLevelId ||
    !tenantId;

  const variables = skip
    ? undefined
    : {
        organizationId: tenantId,
      };

  const { data, loading, error } = useQuery<
    CustomResourceLayerIdsQuery,
    CustomResourceLayerIdsQueryVariables
  >(query, {
    skip,
    variables,
  });

  return {
    customResourceLayerIds: data?.getCustomResourceTemplates
      ? data.getCustomResourceTemplates.map((template) => template.id)
      : [],
    loading: customResourcesEnabledLoading || loading,
    error,
  };
};
