import { onError } from '@apollo/client/link/error';
import { datadogRum } from '@datadog/browser-rum';
import { config } from 'config';

export const errorLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    const captureException = (message: string) => {
      datadogRum.addError(new Error(message ?? 'Unknown error'), {
        operation,
      });
    };

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (
          config.env !== 'development' &&
          extensions?.code === 'UNAUTHENTICATED'
        ) {
          if (config.robinEnv === 'staging') {
            document.cookie =
              '_rbn_staging_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          } else {
            document.cookie =
              '_rbn_session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          }

          const currentPath = window.location.pathname + window.location.search;
          const encodedPath = encodeURIComponent(currentPath);
          window.location.href = `${config.dashboardUrl}/login?redirectTo=${encodedPath}`;
        }
        captureException(
          `[GraphQL error]: Message: ${message} - {locations: ${JSON.stringify(
            locations
          )}, path: ${path}}`
        );
      });
    }
    if (networkError) {
      captureException(`[Network error]: ${networkError}`);
    }
  }
);
