import { gql } from '@apollo/client';
import { message } from '@robinpowered/ui-kit';
import { useCurrentlySelectedResource } from 'atoms/resource';
import { CustomResourceAmplitudeEvents } from 'constants/amplitudeEvents';
import { useAmplitude } from 'contexts/AmplitudeContext';
import { useAuthContext } from 'contexts/AuthContext';
import {
  ReserveByResourceIdRecurringMutation,
  ReserveByResourceIdRecurringMutationVariables,
} from 'generated';
import { useMutationWithEventualConsistency } from 'hooks';
import { useTranslation } from 'react-i18next';

const RESERVE_RESOURCE_MUTATION_RECURRING = gql`
  mutation reserveByResourceIdRecurring(
    $organizationId: ID!
    $resourceInstanceId: ID!
    $duration: ISO8601_Duration!
    $clientType: ClientType!
    $requestReservationDates: [LocaleDateTimePair!]
    $recurringReservationRequests: [RecurringReservationRequest!]!
  ) {
    resourceRecurringReservationSchedule(
      organizationId: $organizationId
      resourceInstanceId: $resourceInstanceId
      duration: $duration
      clientType: $clientType
      requestReservationDates: $requestReservationDates
      recurringReservationRequests: $recurringReservationRequests
    ) {
      ... on ScheduleRecurringReservationSuccessType {
        reservationId
        eventType
        value
      }
      ... on ResourceReservationValidationResponse {
        id
        policiesEvaluated
        errors {
          id
          failureType
        }
        userHasPermissionToReserve
      }
    }
  }
`;

export const useReserveResourceRecurring = () => {
  const { t } = useTranslation('customResourceDetails');
  const { currentOrg } = useAuthContext();
  const { trackCustomResourceEvent } = useAmplitude();
  const selectedResource = useCurrentlySelectedResource();

  const [reserveResourceMultiDay, { isLoadingOrPolling: isReservingMultiDay }] =
    useMutationWithEventualConsistency<
      ReserveByResourceIdRecurringMutation,
      ReserveByResourceIdRecurringMutationVariables
    >(RESERVE_RESOURCE_MUTATION_RECURRING, {
      eventualConsistency: {
        organizationId: currentOrg?.id || '',
        refetchQueries: [
          'getCustomResourceReservations',
          'ResourceReservationRecurringValidate',
        ],
        eventTypeAndValueExtractor: (data) => {
          const result = data.resourceRecurringReservationSchedule;
          if (result.__typename === 'ScheduleRecurringReservationSuccessType') {
            return {
              eventType: result.eventType || null,
              value: result.value,
            };
          }
          return null;
        },
      },
      onCompleted: async (data) => {
        const handleSuccess = async () => {
          void message.open({
            type: 'success',
            content: t('resource_booking_controls.success'),
          });
          if (selectedResource?.resourceCategory) {
            trackCustomResourceEvent(
              CustomResourceAmplitudeEvents.BOOKED_CUSTOMRESOURCE,
              selectedResource.resourceCategory
            );
          }
        };

        const handleFailure = (errors: { failureType: string }[]) => {
          if (
            errors.some(
              (error: { failureType: string }) =>
                error.failureType === 'BOOKING_OVERLAPS'
            )
          ) {
            void message.open({
              type: 'error',
              content: t('resource_booking_controls.already_booked'),
            });
          } else {
            void message.open({
              type: 'error',
              content: t('resource_booking_controls.failure'),
            });
          }
        };

        if (
          data.resourceRecurringReservationSchedule.__typename ===
          'ScheduleRecurringReservationSuccessType'
        ) {
          await handleSuccess();
        } else if (
          data.resourceRecurringReservationSchedule.__typename ===
          'ResourceReservationValidationResponse'
        ) {
          handleFailure(data.resourceRecurringReservationSchedule.errors);
        }
      },
      onError: (error) => {
        const userFriendlyMessage = t('resource_booking_controls.failure');

        void message.open({
          type: 'error',
          content: userFriendlyMessage,
        });
      },
    });

  return {
    reserveResourceRecurring: reserveResourceMultiDay,
    isReserving: isReservingMultiDay,
  };
};
