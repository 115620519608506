import { gql, useQuery } from '@apollo/client';
import {
  RecurringReservationFrequency,
  ResourceReservationRecurringValidateForOfficeQuery,
  ResourceReservationRecurringValidateForOfficeQueryVariables,
  ClientType,
  ISO8601Duration,
  IsoDateOnlyString,
  IsoLocalTimeString,
} from 'generated';
import { useAuthContext } from 'contexts/AuthContext';

export type CustomResourceValidationError =
  ResourceReservationRecurringValidateForOfficeQuery['resourceReservationRecurringValidate'][number]['validationResponse']['errors'][number];

const CUSTOM_RESOURCE_STATE_QUERY = gql`
  query ResourceReservationRecurringValidateForOffice(
    $clientType: ClientType!
    $duration: ISO8601_Duration!
    $organizationId: ID!
    $recurringReservationRequests: [RecurringReservationRequest!]!
    $requestReservationDates: [LocaleDateTimePair!]
    $reservee: ID!
    $resourceInstanceIds: [ID!]!
  ) {
    resourceReservationRecurringValidate(
      clientType: $clientType
      duration: $duration
      organizationId: $organizationId
      recurringReservationRequests: $recurringReservationRequests
      requestReservationDates: $requestReservationDates
      reservee: $reservee
      resourceInstanceIds: $resourceInstanceIds
    ) {
      resourceId
      validationResponse {
        id
        errors {
          failureType
          meta
        }
      }
    }
  }
`;

export const useResourceReservationRecurringValidate = (
  resourceInstanceId: string,
  localDates: IsoDateOnlyString[],
  localStartTime: IsoLocalTimeString | null,
  duration: ISO8601Duration | null,
  reservee: string,
  clientType: ClientType = ClientType.DashboardWeb
) => {
  const { currentOrg } = useAuthContext();

  const skip =
    !currentOrg?.id ||
    !resourceInstanceId ||
    !localDates ||
    !localStartTime ||
    !duration;

  const variables = skip
    ? undefined
    : {
        clientType,
        duration,
        organizationId: currentOrg.id,
        recurringReservationRequests: [
          {
            frequency: RecurringReservationFrequency.Daily,
            // This is intentionally 0
            // Setting it to 1 results in a bug where availability for _today_ is not correct
            count: 0,
          },
        ],
        requestReservationDates: localDates.map((localDate) => ({
          localDate,
          localTime: localStartTime,
        })),
        reservee,
        resourceInstanceIds: [resourceInstanceId],
      };

  const { data, loading } = useQuery<
    ResourceReservationRecurringValidateForOfficeQuery,
    ResourceReservationRecurringValidateForOfficeQueryVariables
  >(CUSTOM_RESOURCE_STATE_QUERY, {
    skip,
    variables,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  return {
    loading,
    data: data?.resourceReservationRecurringValidate[0],
  };
};
