import { gql } from '@apollo/client';
import { message } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import {
  CustomResourceReservationCompletelyCancelMutation,
  CustomResourceReservationCompletelyCancelMutationVariables,
} from 'generated';
import { useMutationWithEventualConsistency } from 'hooks/useMutationWithEventualConsistency';
import { useAuthContext } from 'contexts/AuthContext';
import { useAmplitude } from 'contexts/AmplitudeContext';
import { useCurrentlySelectedResource } from 'atoms/resource';
import { CustomResourceAmplitudeEvents } from 'constants/amplitudeEvents';
import { useBookingControlsContext } from 'contexts/BookingControlsContext';

const RESOURCE_RESERVATION_CANCEL_MUTATION = gql`
  mutation customResourceReservationCompletelyCancel(
    $organizationId: ID!
    $resourceId: ID!
    $reservationId: ID!
  ) {
    customResourceReservationCompletelyCancel(
      organizationId: $organizationId
      resourceId: $resourceId
      reservationId: $reservationId
    ) {
      eventType
      value
      reservationId
    }
  }
`;

export const useCancelResourceReservation = () => {
  const { t } = useTranslation('customResourceDetails');
  const { currentOrg } = useAuthContext();
  const { trackCustomResourceEvent } = useAmplitude();
  const selectedResource = useCurrentlySelectedResource();
  const { setIsBookingControlsSticky } = useBookingControlsContext();

  const [
    cancelResourceReservation,
    { isLoadingOrPolling: isCancellingResource },
  ] = useMutationWithEventualConsistency<
    CustomResourceReservationCompletelyCancelMutation,
    CustomResourceReservationCompletelyCancelMutationVariables
  >(RESOURCE_RESERVATION_CANCEL_MUTATION, {
    eventualConsistency: {
      organizationId: currentOrg?.id || '',
      refetchQueries: [
        'getCustomResourceReservations',
        'ResourceReservationRecurringValidate',
      ],
      eventTypeAndValueExtractor: (data) => {
        const result = data.customResourceReservationCompletelyCancel;
        if (result) {
          return {
            eventType: result.eventType || null,
            value: result.value,
          };
        }
        return null;
      },
    },
    onCompleted: (data) => {
      void message.open({
        type: 'success',
        content: t('resource_booking_controls.cancel_success'),
      });

      // Reset the booking controls stickiness
      setIsBookingControlsSticky(null);

      if (selectedResource?.resourceCategory) {
        trackCustomResourceEvent(
          CustomResourceAmplitudeEvents.CANCELLED_CUSTOMRESOURCE,
          selectedResource.resourceCategory
        );
      }
    },
    onError: (error) => {
      const userFriendlyMessage = t('resource_booking_controls.cancel_failure');

      void message.open({
        type: 'error',
        content: userFriendlyMessage,
      });
    },
  });

  return {
    cancelResourceReservation,
    isCancellingResource,
  };
};
