import gql from 'graphql-tag';
import { useApolloContext, useAuthContext } from 'contexts';
import { useClock } from 'hooks/useClock';
import moment from 'moment';
import { UpcomingDeskReservationsByDeskIdQueryVariables } from 'generated';
import { UpcomingDeskReservationsByDeskIdQuery } from 'generated';
import { useQueryCachedLoad } from 'hooks';

export type UpcomingDeskReservations =
  UpcomingDeskReservationsByDeskIdQuery['getDeskReservationsBySeatId'];

const DESK_RESERVATIONS_BY_DESK_ID = gql`
  query UpcomingDeskReservationsByDeskId(
    $deskId: ID!
    $startTime: ISODateTime!
    $duration: Int!
  ) {
    getDeskReservationsBySeatId(
      deskId: $deskId
      startTime: $startTime
      duration: $duration
    ) {
      startTime
      endTime
      type
      accountReservee {
        email
        user {
          id
          name
          avatar
        }
      }
    }
  }
`;
const ThirtyDaysInMinutes = 43200;

export const useUpcomingDeskReservations = (deskId: string | undefined) => {
  const { currentUser } = useAuthContext();
  const { tenantId } = useApolloContext();

  const startTime = useClock();
  const startTimeMoment = moment(startTime).utc().startOf('day');

  const duration = ThirtyDaysInMinutes;
  const { loading, data } = useQueryCachedLoad<
    UpcomingDeskReservationsByDeskIdQuery,
    UpcomingDeskReservationsByDeskIdQueryVariables
  >(DESK_RESERVATIONS_BY_DESK_ID, {
    context: {
      headers: {
        'cache-refresh': 'no-cache',
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !tenantId || !currentUser || !deskId,
    variables: {
      deskId: deskId || '',
      startTime: startTimeMoment.toISOString(),
      duration,
    },
  });
  const futureReservations = data?.getDeskReservationsBySeatId.filter(
    (reservation) => moment(reservation.startTime).isAfter(startTime)
  );
  return {
    loading,
    data: futureReservations || [],
  };
};
