import { config } from 'config';
import { useLocalStorageContextByKey } from 'contexts';
import { useFeatureFlag } from 'hooks/useFeatureFlag';
import { useParams } from 'react-router-dom';

export const LegacyOfficeRedirect = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [officeV2Enabled] = useLocalStorageContextByKey('officeV2Enabled');
  const enableOfficeV2FeatureFlag = useFeatureFlag('talos(office-V2)', true);
  const customResourcesEnabled = useFeatureFlag(
    'talos(office-V2-custom-resources-enabled)',
    true
  );
  const dashboardUrl = config.dashboardUrl;
  const { orgSlug } = useParams();

  // skip redirect for dev/local, as it won't work due to multiple domains
  const redirectToV1 =
    config.env !== 'development' && !customResourcesEnabled.value;
  if (!officeV2Enabled || !enableOfficeV2FeatureFlag.value) {
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = `${dashboardUrl}/${orgSlug}/office?${params.toString()}`;
    if (redirectToV1) {
      window.location.href = redirectUrl;
    }
  }

  return children;
};
