import { ResourceTags } from '../ResourceTags';
import styled from '@emotion/styled';

export const CustomResourceAmenities = ({
  amenities,
}: {
  amenities: {
    id: string;
    name: string;
  }[];
}): JSX.Element | null => {
  if (!amenities || amenities.length === 0) return null;

  return (
    <AmenitiesContainer>
      <ResourceTags resources={amenities} />
    </AmenitiesContainer>
  );
};

const AmenitiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
