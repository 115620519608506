import { gql, useMutation } from '@apollo/client';
import { message } from '@robinpowered/ui-kit';
import { AmplitudeEvents } from 'constants/amplitudeEvents';
import { PERSEUS_AVAILABILITY_QUERIES } from 'constants/perseus';
import { useAmplitude } from 'contexts';
import {
  ReserveDeskForMeMutation,
  ReserveDeskForMeMutationVariables,
} from 'generated';
import { useTranslation } from 'react-i18next';

const RESERVE_DESK_MUTATION = gql`
  mutation ReserveDeskForMe(
    $seatId: Int!
    $type: String!
    $start: DateWithTimezone!
    $end: DateWithTimezone!
    $visibility: DeskReservationVisibility
    $recurrence: [String!]
  ) {
    reserveDeskForMe(
      seatId: $seatId
      type: $type
      start: $start
      end: $end
      visibility: $visibility
      recurrence: $recurrence
    ) {
      id
    }
  }
`;

export const useReserveDeskForMe = () => {
  const { trackEvent } = useAmplitude();
  const { t } = useTranslation('deskDetails');

  const [reserveDesk, { loading: isReserving }] = useMutation<
    ReserveDeskForMeMutation,
    ReserveDeskForMeMutationVariables
  >(RESERVE_DESK_MUTATION, {
    refetchQueries: [
      'DeskResourceDetails',
      'DeskReservationsBySeatId',
      'DeskReservationsForDeskPolicyCard',
      'DeskReservationsForCancelModal',
      ...PERSEUS_AVAILABILITY_QUERIES,
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      trackEvent(AmplitudeEvents.BOOKED_DESK);
      void message.open({
        type: 'success',
        content: t('desk_booking_controls.success'),
      });
    },
    onError: (error) => {
      const errorMessage = error.graphQLErrors[0]?.message;
      let userFriendlyMessage = t('desk_booking_controls.failure');

      if (
        errorMessage &&
        errorMessage.includes(
          'Reservee already has a reservation in the same location as seat'
        )
      ) {
        userFriendlyMessage = t('desk_booking_controls.already_reserved');
      }
      void message.open({
        type: 'error',
        content: userFriendlyMessage,
      });
    },
  });

  return {
    reserveDesk,
    isReserving,
  };
};
