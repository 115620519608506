import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isConsecutiveList,
  momentToLocationDateTime,
  sortDatesAscending,
} from 'utils';
import moment from 'moment';
import {
  message,
  Modal,
  RadioChangeEvent,
  Typography,
} from '@robinpowered/ui-kit';
import styled from '@emotion/styled';
import { Z_INDEX } from 'constants/zIndex';
import {
  CancelReservationCancelVisits,
  CancelReservationDetailsSection,
  CancelReservationModalFooter,
  CancelReservationModalTitle,
  CancelReservationOptionsSection,
  ModalBody,
} from '../common';
import { useSeriesStartTimesForCancel } from '../graphql/useSeriesStartTimeForCancel';
import { SHORT_DATE_FORMAT } from 'constants/timeFormat';
import { CancelOptions, CancelReservationSeriesModalProps } from '../types';
import {
  useEndOrCancelDelegatedReservation,
  useEndOrCancelReservation,
  useEndReservationOrVisitsInRange,
} from '../graphql';
import { useAuthContext } from 'contexts';

export const CancelReservationSeriesModal = ({
  isOpen,
  onClose,
  onConfirm,
  reservationInProgress,
  reservationUserId,
  reservationUserName,
  reservationStartDate,
  reservationTimezone,
  reservationId,
  reservationSeriesId,
  deskName,
  buildingName,
  floorName,
  isDelegated,
}: CancelReservationSeriesModalProps) => {
  const { t } = useTranslation('deskDetails');
  const { currentOrg } = useAuthContext();

  const [cancelOption, setCancelOption] = useState<CancelOptions>('single');
  const [shouldCancelVisits, setShouldCancelVisits] = useState<boolean>(
    !reservationInProgress
  );

  const { loading, startTimes } =
    useSeriesStartTimesForCancel(reservationSeriesId);

  const dates = useMemo(
    () =>
      [...new Set<string>([...(startTimes || [])])]
        .map((d) => momentToLocationDateTime(moment(d), reservationTimezone))
        .sort(sortDatesAscending),
    [reservationTimezone, startTimes]
  );

  const consecutiveDatesString = useMemo(() => {
    if (isConsecutiveList(dates)) {
      return `${dates[0].format(SHORT_DATE_FORMAT)} - ${dates[
        dates.length - 1
      ].format(SHORT_DATE_FORMAT)}`;
    }
  }, [dates]);

  const formattedDates = useMemo(
    () => dates.map((d) => d.format(SHORT_DATE_FORMAT)),
    [dates]
  );

  const handleRadioButton = useCallback((e: RadioChangeEvent) => {
    const value = e.target.value;

    setCancelOption(value);
  }, []);

  const handleCheckboxClick = () => {
    if (reservationInProgress && cancelOption === 'single') {
      setShouldCancelVisits(false);
    } else {
      setShouldCancelVisits(!shouldCancelVisits);
    }
  };

  const [
    endOrCancelDelegatedReservation,
    endOrCancelDelegatedReservationRequest,
  ] = useEndOrCancelDelegatedReservation();
  const [endOrCancelReservation, endOrCancelReservationRequest] =
    useEndOrCancelReservation();

  const [setEndOrCancelReservation, setEndOrCancelReservationRequest] =
    isDelegated
      ? [
          endOrCancelDelegatedReservation,
          endOrCancelDelegatedReservationRequest,
        ]
      : [endOrCancelReservation, endOrCancelReservationRequest];

  const [
    setEndReservationOrVisitsInRange,
    setEndReservationOrVisitsInRangeRequest,
  ] = useEndReservationOrVisitsInRange();

  const handleConfirm = useCallback(async () => {
    if (!currentOrg || !reservationUserId) {
      return;
    }

    const idToCancel =
      cancelOption === 'single' ? reservationId : reservationSeriesId;

    // TODO: More precise cancel series visits functionality
    // The setEndReservationOrVisitsInRange mutation is not _quite_ what we want
    // This will cancel all reservations and visits in between the first and last instances
    // of the series. We want to only target the days in the series.
    const cancelAction = shouldCancelVisits
      ? setEndReservationOrVisitsInRange
      : setEndOrCancelReservation;

    const endReservationsVars = {
      reservationId: idToCancel,
    };

    const start = cancelOption === 'single' ? reservationStartDate : dates[0];
    const end =
      cancelOption === 'single'
        ? start.clone().add(1, 'day')
        : dates[dates.length - 1].clone().add(1, 'day');

    const endReservationsAndVisitsVars = {
      orgId: currentOrg?.id,
      userId: reservationUserId,
      start: start.toISOString(),
      end: end.toISOString(),
      cancelFutureVisits: true,
      creationType: ['MANUAL', 'AUTOMATIC'],
    };

    await cancelAction({
      variables: {
        ...endReservationsVars,
        ...endReservationsAndVisitsVars,
      },
      onCompleted: () => {
        onConfirm();

        void message.success(
          reservationInProgress
            ? t('cancel_modal.end_success')
            : t('cancel_modal.cancel_success')
        );
      },
      onError: () => {
        void message.error(
          reservationInProgress
            ? t('cancel_modal.end_failure')
            : t('cancel_modal.cancel_failure')
        );
      },
    });
  }, [
    cancelOption,
    currentOrg,
    dates,
    onConfirm,
    reservationId,
    reservationInProgress,
    reservationSeriesId,
    reservationStartDate,
    reservationUserId,
    setEndOrCancelReservation,
    setEndReservationOrVisitsInRange,
    shouldCancelVisits,
    t,
  ]);

  return (
    <Modal
      zIndex={Z_INDEX.CANCEL_RESERVATION_MODAL}
      title={
        <CancelReservationModalTitle
          reservationInProgress={reservationInProgress}
        />
      }
      open={isOpen}
      onOk={handleConfirm}
      onCancel={onClose}
      footer={
        <CancelReservationModalFooter
          onClose={onClose}
          handleConfirm={handleConfirm}
          loading={
            setEndOrCancelReservationRequest.loading ||
            setEndReservationOrVisitsInRangeRequest.loading
          }
        />
      }
    >
      <ModalBody>
        <div>{t('cancel_modal.confirm_cancel')}</div>

        <CancelReservationDetailsSection
          reservationUserName={reservationUserName}
          deskName={deskName}
          floorName={floorName}
          buildingName={buildingName}
        >
          {consecutiveDatesString || (
            <DateColumn data-testid="dates">
              {formattedDates.map((d) => (
                <Typography.Text key={d}>{d}</Typography.Text>
              ))}
            </DateColumn>
          )}
        </CancelReservationDetailsSection>

        <CancelReservationOptionsSection
          selectedOption={cancelOption}
          isSeries={true}
          reservationStartDate={reservationStartDate}
          reservationInProgress={reservationInProgress}
          loading={loading}
          handleRadioButton={handleRadioButton}
        />

        <CancelReservationCancelVisits
          selectedOption={cancelOption}
          reservationInProgress={reservationInProgress}
          shouldCancelVisits={shouldCancelVisits}
          handleCheckboxClick={handleCheckboxClick}
        />
      </ModalBody>
    </Modal>
  );
};

const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
