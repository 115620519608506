import styled from '@emotion/styled';
import { FC, useMemo } from 'react';
import { Typography } from '@robinpowered/ui-kit';

type ResourceInformationProps = {
  name: string;
  levelName: string;
  locationName: string;
  zoneName?: string;
};

export const ResourceInformation: FC<ResourceInformationProps> = ({
  name,
  levelName,
  locationName,
  zoneName,
}) => {
  const resourceLocation = useMemo(
    () => `${levelName}, ${locationName}`,
    [levelName, locationName]
  );

  const resourceName = useMemo(
    () => `${name}${zoneName ? ', ' + zoneName : ''}`,
    [name, zoneName]
  );

  return (
    <Container>
      <ResourceTitle>
        <ResourceName data-testid="resource-name" level={4}>
          {resourceName}
        </ResourceName>
        <ResourceLocation data-testid="resource-location" type="secondary">
          {resourceLocation}
        </ResourceLocation>
      </ResourceTitle>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const ResourceTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ResourceName = styled(Typography.Title)`
  && {
    margin: 0;
  }
`;

const ResourceLocation = styled(Typography.Text)`
  && {
    margin: 0;
  }
`;
