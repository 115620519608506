import { useCallback, useState } from 'react';
import { message } from '@robinpowered/ui-kit';
import { DeskConfirmationType } from 'generated';
import { useTranslation } from 'react-i18next';
import { useConfirmDesk } from '../../graphql/useConfirmDeskReservation';

export function useDeskReservationActions() {
  const { t } = useTranslation('deskDetails');

  const [checkinLoading, setCheckinLoading] = useState(false);
  const [confirmDesk] = useConfirmDesk();

  const handleCheckIn = useCallback(
    async (reservationId: string) => {
      setCheckinLoading(true);
      const minimumTimeout = new Promise((resolve) => setTimeout(resolve, 750));

      try {
        await Promise.all([
          confirmDesk({
            variables: {
              reservationId,
              type: DeskConfirmationType.Dashboard,
            },
          }),
          minimumTimeout,
        ]);

        setCheckinLoading(false);
        await message.success(t('check_in.success_message'));
      } catch (error) {
        await minimumTimeout;
        setCheckinLoading(false);
        await message.error(t('check_in.error_message'));
      }
    },
    [confirmDesk, t]
  );

  return {
    handleCheckIn,
    checkinLoading,
    setCheckinLoading,
  };
}
