import { Button } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';

export const CancelReservationModalFooter = ({
  onClose,
  handleConfirm,
  loading,
}: {
  onClose: () => void;
  handleConfirm: () => void;
  loading: boolean;
}) => {
  const { t } = useTranslation('deskDetails');

  return (
    <>
      <Button key="keep" onClick={onClose}>
        {t('cancel_modal.keep_booking')}
      </Button>

      <Button
        danger
        type="primary"
        key="cancel"
        onClick={handleConfirm}
        loading={loading}
      >
        {t('cancel_modal.cancel_booking')}
      </Button>
    </>
  );
};
