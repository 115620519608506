import styled from '@emotion/styled';
import { Button, Modal } from '@robinpowered/ui-kit';
import { ReleaseDeskModalContent } from 'components/release-desk-modal';
import { ReleaseDeskModalProvider } from 'components/release-desk-modal/contexts';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type ReleaseDeskButtonProps = {
  deskId: string | undefined;
  seriesId: string | null | undefined;
};

export const ReleaseDeskButton = ({
  deskId,
  seriesId,
}: ReleaseDeskButtonProps) => {
  const { t } = useTranslation('deskDetails');

  const [isReleaseDeskOpen, setReleaseDeskOpen] = useState<boolean>(false);

  return (
    <>
      <StyledReleaseDeskButton
        data-testid="release-button"
        type="default"
        onClick={() => setReleaseDeskOpen(true)}
      >
        {t('check_in.release')}
      </StyledReleaseDeskButton>
      <Modal
        width={575}
        title={t('release_desk_modal.title')}
        open={isReleaseDeskOpen}
        onCancel={() => {
          setReleaseDeskOpen(false);
        }}
        footer={null}
      >
        <ReleaseDeskModalProvider deskId={deskId} seriesId={seriesId}>
          <ReleaseDeskModalContent
            onClose={function (): void {
              setReleaseDeskOpen(false);
            }}
          />
        </ReleaseDeskModalProvider>
      </Modal>
    </>
  );
};

const StyledReleaseDeskButton = styled(Button)`
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInline, 16px);
  justify-content: center;
  align-items: center;
  flex: 1;
`;
