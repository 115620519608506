import { JSX } from '@emotion/react/types/jsx-runtime';
import {
  MapComponent,
  ReactMapGl,
  MapLoadingContextProvider,
  MapDataLoadingContextProvider,
  CustomResourceProvider,
} from '@robinpowered/perseus';
import { useAuthContext, useMapControlsContext } from 'contexts';
import { useAccessToken } from 'hooks';
import { MapData } from './MapData';
import { MapInteractions } from './MapInteractions';
import { useStyleSpec, useCustomResourceLayerIdsForBeta } from './hooks';
import { MapLoadingMessage } from './MapLoadingMessage';

export const Map = (): JSX.Element => {
  const accessToken = useAccessToken();
  const { currentOrg, currentUser } = useAuthContext();
  const { selectedLevelId, selectedLocationId } = useMapControlsContext();
  const { styleUrl, backgroundColor } = useStyleSpec(selectedLevelId);

  // TODO: Remove this once out of CRC beta
  const { customResourceLayerIds, loading: customResourceLayerIdsLoading } =
    useCustomResourceLayerIdsForBeta();

  const showMap =
    currentOrg && currentUser && styleUrl && !customResourceLayerIdsLoading;

  return (
    <>
      {showMap && (
        <MapLoadingContextProvider>
          <MapDataLoadingContextProvider>
            <CustomResourceProvider organizationId={currentOrg.id}>
              <MapLoadingMessage />

              <ReactMapGl.MapProvider>
                <MapComponent
                  userId={currentUser?.id}
                  tenantId={currentOrg?.id}
                  id={`${currentOrg?.id}-${selectedLevelId}`}
                  levelId={selectedLevelId}
                  locationId={selectedLocationId}
                  defaultCursor="default"
                  authorization={`Access-Token ${accessToken}`}
                  orgSlug={'robin'}
                  style={{
                    backgroundColor,
                    height: '100%',
                    width: '100%',
                  }}
                  styleUrl={styleUrl}
                  dashboardUrl="https://dashboard.staging.robinpowered.com"
                  locationTimezone={'America/New_York'}
                  initialHiddenLayerIds={customResourceLayerIds}
                >
                  <MapData />
                  <MapInteractions />
                </MapComponent>
              </ReactMapGl.MapProvider>
            </CustomResourceProvider>
          </MapDataLoadingContextProvider>
        </MapLoadingContextProvider>
      )}
    </>
  );
};
