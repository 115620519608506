import { gql, useQuery } from '@apollo/client';
import { useApolloContext } from 'contexts';
import {
  UseLocationLevelsQuery,
  UseLocationLevelsQueryVariables,
} from 'generated';

export type LocationLevel = NonNullable<
  UseLocationLevelsQuery['getLocationById']
>['levels'][number];

export const GET_LOCATION_LEVELS_QUERY = gql`
  query useLocationLevels($locationId: ID!) {
    getLocationById(id: $locationId) {
      id
      name
      levels {
        id
        name
        mapIsAvailable
      }
      timezone
      workingHours {
        day
        timeFrames {
          start
          end
        }
      }
      customWorkingHours {
        id
        date
        timeFrames {
          start
          end
        }
      }
    }
  }
`;

export const useLocationLevels = (locationId: string | null | undefined) => {
  const { tenantId } = useApolloContext();
  return useQuery<UseLocationLevelsQuery, UseLocationLevelsQueryVariables>(
    GET_LOCATION_LEVELS_QUERY,
    {
      variables: {
        locationId: locationId ?? '',
      },
      skip: !tenantId || !locationId,
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'cache-first',
    }
  );
};
