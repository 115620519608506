import gql from 'graphql-tag';
import { useApolloContext, useAuthContext } from 'contexts';
import {
  DeskResourceDetailsQuery,
  DeskResourceDetailsQueryVariables,
} from 'generated';
import { useQueryCachedLoad } from 'hooks';
import { useTimezone } from 'atoms/resource';
import { useResourceAvailabilityQueryParameters } from 'hooks/useResourceAvailabilityParameters';

export type DeskResourceDetailsReturn = {
  loading: boolean;
  deskDetails: DeskResourceDetails | null | undefined;
  deskAvailability: DeskResourceAvailability | undefined;
};

export type DeskResourceDetails = NonNullable<
  DeskResourceDetailsQuery['getAnyDeskById']
>;

export type DeskResourceAvailability = NonNullable<
  DeskResourceDetailsQuery['reservationsMultiDayGroupedByDate']
>[number];

export type DeskUnbookableReasons =
  DeskResourceAvailability['unbookableReasons'];

export type DeskAvailability = DeskResourceAvailability['availability'];

export type DeskSticker = DeskResourceDetails['stickers'][number];
export type DeskSetting = NonNullable<DeskResourceDetails['settings']>[number];

const DESK_RESOURCE_DETAILS = gql`
  query DeskResourceDetails(
    $deskId: ID!
    $deskIds: [ID!]!
    $dates: [LocalDate!]!
    $startTime: LocalTime!
    $durationInMinutes: Int!
    $timezone: IANATimezone!
    $userId: Int!
    $startTimeMoment: Date!
    $endTimeMoment: Date!
    $recurrence: String
  ) {
    getAnyDeskById(id: $deskId) {
      id
      name
      rawType
      isReservable
      isDisabled
      permissions {
        name
        value
      }
      level {
        name
      }
      location {
        name
      }
      zone {
        name
      }
      stickers {
        displayCode
      }
      settings {
        slug
        value
      }
      state(
        startTime: $startTimeMoment
        endTime: $endTimeMoment
        userId: $userId
        recurrence: $recurrence
      ) {
        reservations {
          startTime
        }
        exclusions {
          startTime
          endTime
        }
      }
    }
    reservationsMultiDayGroupedByDate(
      deskIds: $deskIds
      dates: $dates
      startTime: $startTime
      durationInMinutes: $durationInMinutes
      timezone: $timezone
      userId: $userId
    ) {
      deskId
      availability
      unbookableReasons {
        reason
        value
      }
    }
  }
`;

export const useDeskResourceDetails = (deskId: string | null | undefined) => {
  const { currentUser } = useAuthContext();
  const { timezone } = useTimezone();
  const { tenantId } = useApolloContext();
  const { startTimes, endTime, recurrence, durationInMinutes, dates } =
    useResourceAvailabilityQueryParameters();

  const { loading, data } = useQueryCachedLoad<
    DeskResourceDetailsQuery,
    DeskResourceDetailsQueryVariables
  >(
    DESK_RESOURCE_DETAILS,
    {
      context: {
        headers: {
          'cache-refresh': 'no-cache',
        },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      skip:
        !tenantId ||
        !deskId ||
        !startTimes ||
        startTimes.length === 0 ||
        !endTime ||
        !currentUser,
      variables: {
        deskId: deskId || '',
        deskIds: [deskId || ''],
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        startTimeMoment: startTimes![0].toString(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        endTimeMoment: endTime!.toString(),
        recurrence: recurrence,
        timezone: timezone,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        userId: Number(currentUser!.id),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        startTime: startTimes![0].format('H:m'),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        durationInMinutes: durationInMinutes,
        dates: dates,
      },
    },
    ['deskId']
  );

  // This query returns an array as you can request with multiple desks
  // Since we are only requesting for a single desk return the first in the list
  const availabilityForRequestedDesk =
    data?.reservationsMultiDayGroupedByDate?.[0];

  return {
    loading,
    deskDetails: data?.getAnyDeskById,
    deskAvailability: availabilityForRequestedDesk,
  };
};
