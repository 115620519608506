/** @jsxImportSource @emotion/react */
import React, { useCallback, useMemo, useState } from 'react';
import { Segmented } from '@robinpowered/ui-kit';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import {
  MomentDatePicker,
  MomentRangePicker,
} from '../../map/MomentDatePickers';
import moment from 'moment';
import { useAmplitude, useMapControlsContext } from 'contexts';
import { useLocationLevels } from 'hooks/useLocationLevels';
import {
  createDateRange,
  getWorkingHoursForDay,
  hasTodaySelected,
  locationDateTimeMoment,
  LocationDateTimeMoment,
  momentToLocationDateTime,
} from 'utils';
import { useClock } from 'hooks/useClock';
import {
  useSetSelectedDates,
  useSetStartTime,
  useGetStartTimesForSelectedDates,
  useSetDatePickerType,
  useDatePickerType,
  useSetEndTimeFromStartTimeForResources,
  useTimezone,
  useSetEndTimeFromStartTimeForSpaces,
} from 'atoms/resource';
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import { DatePickerType } from 'atoms/resource/types';
import styled from '@emotion/styled';
import { updatedDatesForPickerType } from './utils/updateDatesWhenTypeChanges';
import { AmplitudeEvents } from 'constants/amplitudeEvents';

export const DatePickerComponent: React.FC<{
  status?: '' | 'warning' | 'error' | undefined;
}> = ({ status }) => {
  const { t } = useTranslation('mapControls');
  const { timezone } = useTimezone();
  const clock = useClock();
  const { trackEvent } = useAmplitude();
  const datePickerType = useDatePickerType();
  const setDatePickerType = useSetDatePickerType();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const selectedDates = useGetStartTimesForSelectedDates();
  const setSelectedDates = useSetSelectedDates();

  const setStartTime = useSetStartTime();
  const setEndTimeFromStartTimeForSpaces =
    useSetEndTimeFromStartTimeForSpaces();
  const setEndTimeFromStartTimeForResources =
    useSetEndTimeFromStartTimeForResources();
  const { selectedLocationId } = useMapControlsContext();
  const location = useLocationLevels(selectedLocationId);

  const handleOpenChange = useCallback((open) => setIsOpen(open), []);

  const handleTypeChange = useCallback(
    (value: DatePickerType) => {
      setDatePickerType(value);

      switch (value) {
        case 'single':
          trackEvent(AmplitudeEvents.MAP_CONTROLS_CALENDAR_SINGLE_DAY);
          break;
        case 'multi':
          trackEvent(AmplitudeEvents.MAP_CONTROLS_CALENDAR_MULTI_DAY);
          break;
        case 'range':
          trackEvent(AmplitudeEvents.MAP_CONTROLS_CALENDAR_RANGE);
          break;
        default:
          break;
      }
      setIsOpen(true);

      const updatedDates = updatedDatesForPickerType(value, selectedDates);

      if (updatedDates) {
        setSelectedDates(updatedDates);
      }
    },
    [selectedDates, setDatePickerType, setSelectedDates, trackEvent]
  );

  const handleDateChange = useCallback(
    (value: LocationDateTimeMoment | LocationDateTimeMoment[] | null) => {
      switch (datePickerType) {
        case 'single':
          trackEvent(AmplitudeEvents.CALENDAR_DATE_SELECTED_SINGLE_DAY);
          break;
        case 'multi':
          trackEvent(AmplitudeEvents.CALENDAR_DATE_SELECTED_MULTI_DAY);
          break;
        case 'range':
          trackEvent(AmplitudeEvents.CALENDAR_DATE_SELECTED_RANGE);
          break;
        default:
          break;
      }

      // Reset the datepicker if user cleared it out
      if (isSelectionEmpty(value)) {
        setIsOpen(false);
        setSelectedDates([moment()]);
        setDatePickerType('single');
        return;
      }

      // use selected day or first selected day in the list
      const selectedDay = Array.isArray(value) ? value[0] : value;

      if (!value || !selectedDay) return;

      if (Array.isArray(value)) {
        setSelectedDates(value);
      } else {
        setSelectedDates(value ? [value] : [moment()]);
      }
      // If the date is not today, update date pickers to working hours
      if (
        !hasTodaySelected([selectedDay], timezone) &&
        location.data?.getLocationById?.workingHours
      ) {
        const workingHours = getWorkingHoursForDay(
          location.data?.getLocationById?.workingHours,
          selectedDay.day()
        );

        // Get the start of working hours on the given selected day
        const start = selectedDay.startOf('day').add(workingHours.start, 'h');
        if (start) {
          setStartTime(start);
          setEndTimeFromStartTimeForSpaces(start);
          setEndTimeFromStartTimeForResources(start);
        }
      } else {
        setStartTime(moment(clock));
        setEndTimeFromStartTimeForSpaces(moment(clock));
        setEndTimeFromStartTimeForResources(moment(clock));
      }
    },
    [
      setStartTime,
      setEndTimeFromStartTimeForSpaces,
      setEndTimeFromStartTimeForResources,
      location,
      setDatePickerType,
      setSelectedDates,
      timezone,
      clock,
      datePickerType,
      trackEvent,
    ]
  );

  const handleDateRangeChange = useCallback(
    (dates: NoUndefinedRangeValueType<LocationDateTimeMoment>) => {
      const [start, end] = dates;
      if (!start || !end) {
        return;
      }

      const dateRange = createDateRange(
        momentToLocationDateTime(start, timezone),
        momentToLocationDateTime(end, timezone)
      );
      handleDateChange(dateRange);
    },
    [timezone, handleDateChange]
  );

  // Turn the selected dates into an object that the Range picker understands
  const selectedDatesAsRange:
    | NoUndefinedRangeValueType<LocationDateTimeMoment>
    | undefined = useMemo(() => {
    if (selectedDates && selectedDates.length > 1) {
      const first = selectedDates[0];
      const last = selectedDates[selectedDates.length - 1];

      return [first, last];
    }
  }, [selectedDates]);

  const disabledDate = useCallback(
    (current: LocationDateTimeMoment): boolean => {
      const startOfDayInLocalTime =
        locationDateTimeMoment(timezone).startOf('day');
      return current && current.isBefore(startOfDayInLocalTime);
    },
    [timezone]
  );

  return (
    <>
      {datePickerType === 'range' ? (
        <MomentRangePicker
          value={selectedDatesAsRange}
          style={{ width: '100%' }}
          status={status}
          open={isOpen}
          onOpenChange={(open) => handleOpenChange(open)}
          onCalendarChange={handleDateRangeChange}
          panelRender={(render) => (
            <SingleMonthPanel>
              <div css={segmentedContainerStyle}>
                <Segmented
                  css={segmentedStyle}
                  options={[
                    t('date_picker.single'),
                    t('date_picker.multi'),
                    t('date_picker.range'),
                  ]}
                  value={datePickerType}
                  onChange={(value) =>
                    handleTypeChange(value as DatePickerType)
                  }
                />
              </div>
              {render}
            </SingleMonthPanel>
          )}
          disabledDate={disabledDate}
        />
      ) : (
        <MomentDatePicker
          allowClear={datePickerType !== 'single'}
          style={{ width: '100%' }}
          status={status}
          multiple={datePickerType === 'multi'}
          value={selectedDates}
          onChange={handleDateChange}
          onCalendarChange={handleDateChange}
          open={isOpen}
          onOpenChange={(open) => {
            // TODO - hack to get multi day picker to close
            // For some reason if you select multiple dates and try to click away the 'onOpenChange' handler fires twice:
            // once with the correct 'false' value and then again with the 'true' value. If you change over to one of the
            // other pickers and change the date, this issue disappears.
            // The only bad side effect here is the animation gets cut short slightly on multi-day picker closing.
            if (datePickerType === 'multi' && open === false) {
              setDatePickerType('range');
              setIsOpen(false);
              setDatePickerType('multi');
            }
            handleOpenChange(open);
          }}
          panelRender={(render) => (
            <>
              <div css={segmentedContainerStyle}>
                <Segmented
                  css={segmentedStyle}
                  options={[
                    { value: 'single', label: t('date_picker.single') },
                    { value: 'multi', label: t('date_picker.multi') },
                    { value: 'range', label: t('date_picker.range') },
                  ]}
                  value={datePickerType}
                  onChange={(value) =>
                    handleTypeChange(value as DatePickerType)
                  }
                />
              </div>
              {render}
            </>
          )}
          disabledDate={disabledDate}
        />
      )}
    </>
  );
};

const segmentedContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: var(--Components-Segmented-Global-borderRadiusSM, 4px);
  background: var(--Components-Segmented-Component-itemSelectedBg, #fff);
  /* boxShadowTertiary */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03),
    0px 1px 6px -1px rgba(0, 0, 0, 0.02), 0px 2px 4px 0px rgba(0, 0, 0, 0.02);
`;

const segmentedStyle = css`
  display: flex;
  width: 100%;

  .ant-segmented-item {
    flex: 1;
  }
`;

// Force the range picker to only display a single month
const SingleMonthPanel = styled.div`
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none !important;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
`;

const isSelectionEmpty = (
  value: LocationDateTimeMoment | LocationDateTimeMoment[] | null
): boolean => {
  return !value || (Array.isArray(value) && value.length === 0);
};
