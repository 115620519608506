import moment from 'moment';
import { useTranslation, TFunction } from 'react-i18next';

// Returns the quantity and time type for a given duration
// This only works for a sinle time type - either minutes, hours, days, weeks or months
// ie. returns "100 days" or "12 hours" or "45 minutes" - can't return "13 hours, 30 minutes"
function formatDuration(
  duration: string,
  t: TFunction<'time', undefined>
): string {
  const durationMoment = moment.duration(duration);

  if (duration.includes('T')) {
    if (duration.includes('M')) {
      return t('time:time_options.minutes', {
        count: durationMoment.asMinutes(),
      });
    } else {
      return t('time:time_options.hours', {
        count: durationMoment.asHours(),
      });
    }
  } else if (duration.includes('D')) {
    return t('time:time_options.days', { count: durationMoment.asDays() });
  } else if (duration.includes('W')) {
    return t('time:time_options.weeks', {
      count: durationMoment.asWeeks(),
    });
  }
  return t('time:time_options.months', {
    count: durationMoment.asMonths(),
  });
}

export function useLocalizeDuration(duration: string): string {
  const { t } = useTranslation();
  return formatDuration(duration, t);
}
