import styled from '@emotion/styled';
import { Modal, Typography, type ModalProps } from '@robinpowered/ui-kit';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const EditSummaryCancelModal: FC<ModalProps> = (modalProps) => {
  const { t } = useTranslation('deskDetails');

  return (
    <Modal
      {...modalProps}
      title={t('edit_desk_reservation.cancel_modal.title')}
    >
      <Content>
        <Typography.Text>
          {t('edit_desk_reservation.cancel_modal.message')}
        </Typography.Text>
      </Content>
    </Modal>
  );
};

const Content = styled.div`
  padding: var(--Components-Drawer-Global-paddingLG, 24px) 0;
`;
