import { useState, useCallback, useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@robinpowered/ui-kit';
import {
  useGetSpaceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useStartTimeIsNow,
  useTimezone,
} from 'atoms/resource';
import { momentToLocationDateTime } from 'utils';
import { useAvailabilityPillStyles, useBookingComposer } from './hooks';
import { AvailabilityPill } from './SpaceAvailabilityPill';
import { SpaceListDetail } from '../graphql/useSpaceList';
import {
  Availability_Pill_SHORT_TIME_FORMAT,
  Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE,
  TIME_FORMAT_24H,
} from 'constants/timeFormat';
import { useUserLocale } from 'hooks';

type AvailabilityDetailsProps = {
  space: SpaceListDetail;
};

export const SpaceListAvailabilityPills = ({
  space,
}: AvailabilityDetailsProps) => {
  const { t } = useTranslation('spaceDetails');
  const { timezone } = useTimezone();
  const { linkToComposer } = useBookingComposer();
  const selectedStartTime = useGetStartTimesForSelectedDates();
  const selectedEndTime = useGetSpaceEndTimesForSelectedDates();
  const pillStyles = useAvailabilityPillStyles();
  const startTimeIsNow = useStartTimeIsNow();
  const { use24HourTime } = useUserLocale();
  const [isRedirecting, setIsRedirecting] = useState(false);

  const handlePillClick = useCallback(
    (start: string, end: string) => {
      setIsRedirecting(true);
      linkToComposer(space.id, start, end);
    },
    [linkToComposer, setIsRedirecting, space.id]
  );

  const handleBookButtonClick = () => {
    setIsRedirecting(true);
    linkToComposer(space.id, selectedStartTime?.[0], selectedEndTime?.[0]);
  };

  const allPillsUnavailable = useMemo(() => {
    const availabilitySlots = space.meetingRoomWindowedAvailability || [];
    return availabilitySlots
      .slice(0, 2)
      .every((slot) => slot.status !== 'AVAILABLE');
  }, [space.meetingRoomWindowedAvailability]);

  const renderAvailabilityPills = useCallback(() => {
    const TimePills = space.meetingRoomWindowedAvailability?.map(
      (slot, index) => {
        const { start, end, status } = slot;

        if (!start || !end) {
          return null;
        }

        const isDisabled = status !== 'AVAILABLE';
        const startMoment = momentToLocationDateTime(moment(start), timezone);
        const endMoment = momentToLocationDateTime(moment(end), timezone);

        const startTime =
          index === 0 && startTimeIsNow
            ? t('space_list_item.now')
            : startMoment.format(
                use24HourTime
                  ? TIME_FORMAT_24H
                  : Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE
              );
        const startAndEndTime =
          startMoment.format(
            use24HourTime
              ? TIME_FORMAT_24H
              : Availability_Pill_SHORT_TIME_FORMAT
          ) +
          ' - ' +
          endMoment.format(
            use24HourTime
              ? TIME_FORMAT_24H
              : Availability_Pill_SHORT_TIME_FORMAT_WITH_ZONE
          );

        return (
          <SpaceListAvailabilityPill
            key={index}
            disabled={isDisabled || isRedirecting}
            {...pillStyles}
            onClick={() => handlePillClick(start, end)}
          >
            <span>{startTime}</span>
            <span>{startAndEndTime}</span>
          </SpaceListAvailabilityPill>
        );
      }
    );

    return TimePills;
  }, [
    isRedirecting,
    space.meetingRoomWindowedAvailability,
    startTimeIsNow,
    timezone,
    pillStyles,
    handlePillClick,
    use24HourTime,
    t,
  ]);

  return (
    <AvailbilityDetails>
      <AvailabilityPillsContainer>
        {renderAvailabilityPills()}
        {allPillsUnavailable && (
          <Button
            onClick={handleBookButtonClick}
            type="default"
            disabled={isRedirecting}
          >
            {t('space_list_item.book')}
          </Button>
        )}
      </AvailabilityPillsContainer>
    </AvailbilityDetails>
  );
};

const hoverAnimationStyles = css`
  position: relative;
  overflow: hidden;
  text-align: center;
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
  &:hover span:first-child {
    transform: translate(-150%, -50%);
    opacity: 0;
  }
  &:hover span:last-child {
    transform: translate(-50%, -50%);
    opacity: 1;
    font-size: 10px;
  }
  span:first-child {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
  span:last-child {
    opacity: 0;
    transform: translate(150%, -50%);
  }
`;

const SpaceListAvailabilityPill = styled(AvailabilityPill)`
  width: 100%;
  ${hoverAnimationStyles}
`;

const AvailbilityDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  align-self: stretch;
  max-width: 100%;
  box-sizing: border-box;
`;

const AvailabilityPillsContainer = styled.div`
  display: flex;
  gap: 5px;
  max-width: 100%;
  box-sizing: border-box;
  flex-grow: 1;

  & > * {
    flex: 1;
    min-width: 0;
  }
`;
