import { FC } from 'react';
import { DeskListItemBase } from './DeskListItemBase';
import { UnavailableIcon } from './icons/UnavailableIcon';
import { useTranslation } from 'react-i18next';
import { DeskListItemDeskReservation } from '../../graphql/useDeskListItemData';
import { useDefaultDeskListItemClick } from './hooks/useDefaultDeskListItemClick';

export const DeskListItemAssigned: FC<{
  deskId: string;
  deskName: string;
  assignedReservation: DeskListItemDeskReservation | undefined;
}> = ({ deskName, assignedReservation, deskId }) => {
  const { t } = useTranslation('deskDetails');

  const onClick = useDefaultDeskListItemClick(deskId);

  return (
    <DeskListItemBase
      title={assignedReservation?.reservee?.user?.name || deskName}
      subtitle={
        assignedReservation
          ? t('desk_list.assigned')
          : t('desk_list.must_be_assigned')
      }
      avatarSrc={assignedReservation?.reservee?.user?.avatar || undefined}
      reserveeName={assignedReservation?.reservee?.user?.name || undefined}
      reserveeId={assignedReservation?.reservee?.user?.id || undefined}
      icon={<UnavailableIcon />}
      onClick={onClick}
    />
  );
};
