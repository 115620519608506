import styled from '@emotion/styled';
import { ChevronLeftOutline } from '@robinpowered/icons';
import { Button, Skeleton, Table, Typography } from '@robinpowered/ui-kit';
import { useSetCurrentFilter } from 'atoms/mapInteractions';
import {
  useCurrentlySelectedResource,
  useSetCurrentlySelectedResource,
} from 'atoms/resource';
import {
  useSetDeskSidebarView,
  useSetRightSidebarView,
} from 'atoms/sidebar/hooks';
import { SidebarContent, SidebarFooter } from 'components/global/sidebar';
import { SidebarHeader } from 'components/global/sidebar/SidebarHeader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DeskInformation } from '../DeskInformation';
import { useTranslation } from 'react-i18next';
import { UnassignedDeskUserSelect } from './UnassignedDeskUserSelect';
import { RRule, Weekday } from 'rrule';
import { AssignedDeskUser } from './AssignedDeskUser';
import {
  SharedDeskSchedule,
  useAssignedDeskDetails,
} from './graphql/useAssignedDeskDetails';
import { ReviewDeskAssignmentsModal } from './ReviewDeskAssignmentsModal';
import {
  addDayToWeeklyRRule,
  createWeeklyRrule,
  removeDayFromWeeklyRRule,
  rruleDayIndexList,
} from 'utils/rrule';
import { UpcomingDeskBookings } from './UpcomingDeskBookings';

export const EditDeskAssignments = () => {
  const { t } = useTranslation('deskDetails');
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setRightSidebarView = useSetRightSidebarView();
  const setDeskSidebarView = useSetDeskSidebarView();
  const setCurrentFilter = useSetCurrentFilter();
  const selectedResource = useCurrentlySelectedResource();
  const { id: deskId } = selectedResource || {};
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { data: deskDetails, loading: deskLoading } =
    useAssignedDeskDetails(deskId);
  const onClose = useCallback(() => {
    setCurrentlySelectedResource(null);
    setRightSidebarView(undefined);
    setCurrentFilter(null);
  }, [setCurrentlySelectedResource, setRightSidebarView, setCurrentFilter]);

  const [editableDeskSchedules, setEditableDeskSchedules] = useState<
    SharedDeskSchedule[]
  >([]);

  useEffect(() => {
    if (deskDetails) {
      setEditableDeskSchedules(
        deskDetails?.getSharedDesksSchedules?.flatMap(
          (deskSchedule) => deskSchedule?.schedule || []
        ) || []
      );
    }
  }, [deskDetails]);

  const resetAllSchedules = useCallback(() => {
    setEditableDeskSchedules([]);
  }, [setEditableDeskSchedules]);

  const addScheduleToDay = useCallback(
    (
      dayIndex: number,
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      avatar: string
    ) => {
      const currentSchedule = editableDeskSchedules.find(
        (schedule) => schedule.email === email
      );

      if (currentSchedule) {
        const recurrence = addDayToWeeklyRRule(
          currentSchedule.recurrence,
          dayIndex
        );
        setEditableDeskSchedules([
          ...editableDeskSchedules.filter((s) => s.email !== email),
          {
            id: userId,
            firstName,
            lastName,
            email,
            recurrence,
            avatar,
            uniqueId: userId,
          },
        ]);
      } else {
        const recurrence = createWeeklyRrule(dayIndex);
        setEditableDeskSchedules([
          ...editableDeskSchedules,
          {
            id: userId,
            firstName,
            lastName,
            email,
            recurrence,
            avatar,
            uniqueId: userId,
          },
        ]);
      }
    },
    [editableDeskSchedules, setEditableDeskSchedules]
  );

  const addScheduleToAllDays = useCallback(
    (
      userId: string,
      firstName: string,
      lastName: string,
      email: string,
      avatar: string
    ) => {
      const allDaysRule = createWeeklyRrule(rruleDayIndexList);
      const recurrence = allDaysRule.toString();
      setEditableDeskSchedules([
        {
          id: userId,
          firstName,
          lastName,
          email,
          recurrence,
          avatar,
          uniqueId: userId,
        },
      ]);
    },
    [setEditableDeskSchedules]
  );

  const onAddUserToScheduledDay = useCallback(
    (dayIndex: number) =>
      (
        userId: string,
        firstName: string,
        lastName: string,
        email: string,
        avatar: string
      ) => {
        if (editableDeskSchedules.length > 0) {
          addScheduleToDay(
            dayIndex,
            userId,
            firstName,
            lastName,
            email,
            avatar
          );
        } else {
          addScheduleToAllDays(userId, firstName, lastName, email, avatar);
        }
      },
    [addScheduleToDay, addScheduleToAllDays, editableDeskSchedules]
  );

  const removeScheduleFromDay = useCallback(
    (dayIndex: number) => {
      const updatedSchedules = editableDeskSchedules.reduce<
        SharedDeskSchedule[]
      >((updatedSchedules, schedule) => {
        const rule = RRule.fromString(schedule.recurrence);
        const scheduleDays = rule.origOptions.byweekday
          ? Array.isArray(rule.origOptions.byweekday)
            ? rule.origOptions.byweekday
            : [rule.origOptions.byweekday]
          : [];

        if (scheduleDays.length === 0) {
          return updatedSchedules;
        }

        // if single day and matches the day index, remove the schedule
        if (scheduleDays.length === 1) {
          if (
            scheduleDays[0] instanceof Weekday &&
            scheduleDays[0].weekday === dayIndex
          ) {
            return updatedSchedules;
          } else {
            updatedSchedules.push(schedule);
          }
        } else {
          // Update the schedule's recurrence rule
          const newSchedule = {
            ...schedule,
            recurrence: removeDayFromWeeklyRRule(schedule.recurrence, dayIndex),
          };

          // Add the updated schedule to the list
          updatedSchedules.push(newSchedule);
        }

        return updatedSchedules;
      }, []);
      setEditableDeskSchedules(updatedSchedules);
    },
    [editableDeskSchedules, setEditableDeskSchedules]
  );

  const items = useMemo(
    () =>
      rruleDayIndexList.map((dayIndex) => {
        const dayLabel = t(`assigned_desk.day_labels.${dayIndex}`);
        const assingedUserOnDay = editableDeskSchedules.find((schedule) => {
          const rule = RRule.fromString(schedule.recurrence);
          const scheduleDays = rule.options.byweekday;

          const isMatchingUserOnDay = scheduleDays?.some(
            (weekday) => weekday === dayIndex
          );
          return isMatchingUserOnDay;
        });

        return {
          key: dayIndex,
          day: dayLabel,
          user: {
            assingedUserOnDay,
            dayIndex,
          },
        };
      }),
    [editableDeskSchedules, t]
  );
  const AssignmentsTable = () => {
    const columns = [
      {
        title: 'day',
        dataIndex: 'day',
        key: 'day',
        width: '30px',
        render: (day: string) => <DayColumn>{day}</DayColumn>,
      },
      {
        title: 'user',
        dataIndex: 'user',
        key: 'user',

        render: (user: {
          assingedUserOnDay: {
            firstName: string;
            lastName: string;
            email: string;
            avatar: string;
          };
          dayIndex: number;
        }) => {
          const name =
            user?.assingedUserOnDay?.firstName ||
            user?.assingedUserOnDay?.lastName
              ? `${user?.assingedUserOnDay?.firstName} ${user?.assingedUserOnDay?.lastName}`
              : undefined;

          return (
            <UserColumn>
              {user.assingedUserOnDay ? (
                <AssignedDeskUser
                  name={name && name.trim().length > 0 ? name : undefined}
                  email={user.assingedUserOnDay.email}
                  avatar={user.assingedUserOnDay.avatar}
                  onRemove={() => removeScheduleFromDay(user.dayIndex)}
                />
              ) : (
                <UnassignedDeskUserSelect
                  onSelectUser={onAddUserToScheduledDay(user.dayIndex)}
                />
              )}
            </UserColumn>
          );
        },
      },
    ];

    return (
      <StyledTable
        showHeader={false}
        columns={columns}
        dataSource={items}
        pagination={false}
        bordered
      />
    );
  };

  const areSchedulesEqual = useMemo(() => {
    const existingSchedules =
      deskDetails?.getSharedDesksSchedules?.flatMap(
        (deskSchedule) => deskSchedule?.schedule || []
      ) || [];

    // Get unique emails from both arrays
    const allEmails = new Set([
      ...existingSchedules.map((schedule) => schedule.email),
      ...editableDeskSchedules.map((schedule) => schedule.email),
    ]);

    // Compare schedules for each unique email
    return Array.from(allEmails).every((email) => {
      const existingSchedule = existingSchedules.find(
        (schedule) => schedule.email === email
      );
      const newSchedule = editableDeskSchedules.find(
        (schedule) => schedule.email === email
      );

      return existingSchedule?.recurrence === newSchedule?.recurrence;
    });
  }, [deskDetails, editableDeskSchedules]);

  if (deskLoading || !deskDetails) {
    return (
      <AssignedDeskSkelaton data-testid="skeleton">
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </AssignedDeskSkelaton>
    );
  }

  return (
    <>
      <SidebarHeader
        prefix={
          <BackButton
            type="link"
            onClick={() => setDeskSidebarView('desk-details')}
            data-testid="back-button"
          >
            <ChevronLeftOutline size={16} />
          </BackButton>
        }
        header={t('assigned_desk.title')}
        onClose={onClose}
      />
      <AssignedDeskSidebarContent>
        <DeskInfoHeader>
          <DeskInformation
            name={deskDetails?.getDesksByIds[0].name}
            levelName={deskDetails?.getDesksByIds[0].level?.name}
            locationName={deskDetails?.getDesksByIds[0].location.name}
          />
        </DeskInfoHeader>
        <AssignedDeskWeekView>
          <Title level={5}>
            <div>{t('assigned_desk.table_title')}</div>
            <ClearAllButton onClick={resetAllSchedules}>
              {t('assigned_desk.clear_all')}
            </ClearAllButton>
          </Title>
          <AssignmentsTable />
        </AssignedDeskWeekView>

        <UpcomingDeskBookings deskId={deskId} />
      </AssignedDeskSidebarContent>
      <ReviewDeskAssignmentsModal
        isOpen={isModalOpen}
        setIsOpen={setModalOpen}
        newDeskSchedules={editableDeskSchedules}
        existingDeskSchedules={
          deskDetails.getSharedDesksSchedules?.flatMap(
            (deskSchedule) => deskSchedule?.schedule || []
          ) || []
        }
        deskDetails={deskDetails.getDesksByIds[0]}
      />
      <SidebarFooter
        actions={{
          primary: {
            label: t('assigned_desk.review'),
            onClick: () => setModalOpen(true),
            disabled: areSchedulesEqual,
          },
          secondary: {
            label: t('assigned_desk.cancel'),
            onClick: () => setDeskSidebarView('desk-details'),
            disabled: false,
          },
        }}
      />
    </>
  );
};
export const AssignedDeskSidebarContent = styled(SidebarContent)`
  padding: 16px;
`;

const ClearAllButton = styled(Button)`
  border: none;
  box-shadow: none;
`;

const UserColumn = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(Typography.Title)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DayColumn = styled.div`
  text-align: center;
  justify-content: center;
`;

const StyledTable = styled(Table)`
  & .ant-table-tbody > tr > td {
    vertical-align: middle;
    padding: 12px 12px;
  }

  & .ant-table-tbody > tr {
    height: 48px;
  }
  margin-bottom: 16px;
`;

const AssignedDeskWeekView = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeskInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
`;

const AssignedDeskSkelaton = styled(SidebarContent)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const BackButton = styled(Button)`
  padding: 0;
`;
