import { useDesksOnFloor } from '../graphql/useDesksOnFloor';
import { useMapControlsContext } from 'contexts';
import { FC, useMemo } from 'react';
import { DeskList, DeskListZone } from './DeskList';

export const LevelDeskList: FC<{ selectedDeskId?: string | undefined }> = ({
  selectedDeskId,
}) => {
  const { selectedLevelId } = useMapControlsContext();
  const { loading: desksLoading, data } = useDesksOnFloor(selectedLevelId);

  // Convert level data to generic zones format
  const zones: DeskListZone[] = useMemo(() => {
    if (!data?.getLevelById?.zones) {
      return [];
    }

    return data.getLevelById.zones.map((zone) => ({
      id: zone.id,
      name: zone.name,
      desks: zone.desks.map((desk) => ({
        id: desk.id,
        name: desk.name,
        zone: {
          id: zone.id,
          name: zone.name,
        },
      })),
    }));
  }, [data]);

  return (
    <DeskList
      zones={zones}
      selectedDeskId={selectedDeskId}
      isLoading={desksLoading}
    />
  );
};
