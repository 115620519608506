import styled from '@emotion/styled';
import { Button, Checkbox, message, Modal, Table } from '@robinpowered/ui-kit';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SharedDeskDetails,
  SharedDeskSchedule,
} from './graphql/useAssignedDeskDetails';
import { RRule } from 'rrule';
import { usePublishSharedScheduleForDesk } from './graphql/usePublishSharedScheduleForDesk';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';
const { Column, ColumnGroup } = Table;

type ReviewDeskAssignmentsModalProps = {
  newDeskSchedules: SharedDeskSchedule[];
  existingDeskSchedules: SharedDeskSchedule[];
  deskDetails: SharedDeskDetails;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

type ScheduleComparisonData = {
  key: string;
  name: string;
  previousSchedule?: string;
  nextSchedule?: string;
};

export const ReviewDeskAssignmentsModalBody = ({
  deskDetails,
  newDeskSchedules,
  existingDeskSchedules,
  notifyUnassigned,
  setNotifyUnassigned,
}: {
  deskDetails: SharedDeskDetails;
  newDeskSchedules: SharedDeskSchedule[];
  existingDeskSchedules: SharedDeskSchedule[];
  notifyUnassigned: boolean;
  setNotifyUnassigned: (notifyUnassigned: boolean) => void;
}) => {
  const { t } = useTranslation('deskDetails');

  const formatSchedule = (recurrence: string) => {
    const rrule = RRule.fromString(recurrence);
    const sortedWeekdays = [...rrule.options.byweekday].sort((a, b) => a - b);
    return sortedWeekdays
      .map((day) => t(`assigned_desk.day_labels.${day}`))
      .join(', ');
  };

  // Combine all unique users from both schedules
  const allUsers = new Map<string, ScheduleComparisonData>();

  // First collect all emails from both schedules
  const allEmails = new Set([
    ...existingDeskSchedules.map((s) => s.email),
    ...newDeskSchedules.map((s) => s.email),
  ]);

  // Process each unique email
  allEmails.forEach((email) => {
    const existingSchedule = existingDeskSchedules.find(
      (s) => s.email === email
    );
    const newSchedule = newDeskSchedules.find((s) => s.email === email);

    const previousScheduleText = existingSchedule
      ? formatSchedule(existingSchedule.recurrence)
      : t('assigned_desk.review_modal.unassigned');
    const nextScheduleText = newSchedule
      ? formatSchedule(newSchedule.recurrence)
      : t('assigned_desk.review_modal.unassigned');

    const userData: ScheduleComparisonData = {
      key: email,
      name: existingSchedule
        ? `${existingSchedule.firstName} ${existingSchedule.lastName}`
        : newSchedule
        ? `${newSchedule.firstName} ${newSchedule.lastName}`
        : email,
      previousSchedule: previousScheduleText,
      nextSchedule:
        previousScheduleText === nextScheduleText
          ? t('assigned_desk.review_modal.no_change')
          : nextScheduleText,
    };

    allUsers.set(email, userData);
  });

  const data = Array.from(allUsers.values());

  return (
    <>
      <ReadOnlyTable dataSource={data} pagination={false}>
        <ColumnGroup align="left" title={deskDetails.name}>
          <Column
            title={t('assigned_desk.review_modal.name_title')}
            dataIndex="name"
          />
          <Column
            title={t('assigned_desk.review_modal.previous_schedule')}
            dataIndex="previousSchedule"
            render={(text: string) => <div>{text}</div>}
          />
          <Column
            title={t('assigned_desk.review_modal.next_schedule')}
            dataIndex="nextSchedule"
            render={(text: string) => <div>{text}</div>}
          />
        </ColumnGroup>
      </ReadOnlyTable>
      <CheckboxWrapper>
        <Checkbox
          checked={notifyUnassigned}
          onChange={() => setNotifyUnassigned(!notifyUnassigned)}
        >
          {t('assigned_desk.review_modal.email_notification')}
        </Checkbox>
      </CheckboxWrapper>
    </>
  );
};

export const ReviewDeskAssignmentsModal = ({
  newDeskSchedules,
  existingDeskSchedules,
  deskDetails,
  isOpen,
  setIsOpen,
}: ReviewDeskAssignmentsModalProps) => {
  const { t } = useTranslation('deskDetails');
  const [isLoading, setIsLoading] = useState(false);
  const [publishSharedScheduleForDesk, { loading }] =
    usePublishSharedScheduleForDesk();
  const setView = useSetDeskSidebarView();

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const [notifyUnassigned, setNotifyUnassigned] = useState(false);
  const handleConfirm = () => {
    publishSharedScheduleForDesk({
      variables: {
        input: {
          deskId: deskDetails.id,
          notifyUnassigned: notifyUnassigned,
          sharedSchedules: newDeskSchedules.map((x) => {
            return {
              email: x.email,
              recurrence: x.recurrence,
              userId: x.id,
            };
          }),
        },
      },
    })
      .then(() => {
        void message.success(t('assigned_desk.review_modal.success'));
      })
      .catch(() => {
        void message.error(t('assigned_desk.review_modal.failure'));
      })
      .finally(() => {
        setIsOpen(false);
        setView('desk-details');
      });
  };

  return (
    <>
      <Modal
        width={700}
        title={t('assigned_desk.review_modal.title')}
        open={isOpen}
        onCancel={() => {
          setIsOpen(false);
        }}
        footer={
          <ModalFooter
            onClose={() => setIsOpen(false)}
            handleConfirm={handleConfirm}
            loading={isLoading}
          />
        }
      >
        <ReviewDeskAssignmentsModalBody
          newDeskSchedules={newDeskSchedules}
          existingDeskSchedules={existingDeskSchedules}
          deskDetails={deskDetails}
          notifyUnassigned={notifyUnassigned}
          setNotifyUnassigned={setNotifyUnassigned}
        />
      </Modal>
    </>
  );
};

export const ModalFooter = ({
  onClose,
  handleConfirm,
  loading,
}: {
  onClose: () => void;
  handleConfirm: () => void;
  loading: boolean;
}) => {
  const { t } = useTranslation('deskDetails');

  return (
    <>
      <Button key="keep" onClick={onClose}>
        {t('assigned_desk.review_modal.cancel')}
      </Button>

      <Button
        type="primary"
        key="cancel"
        onClick={handleConfirm}
        loading={loading}
      >
        {t('assigned_desk.review_modal.publish')}
      </Button>
    </>
  );
};

const CheckboxWrapper = styled.div`
  margin-top: 15px;
`;

const ReadOnlyTable = styled(Table)`
  & .ant-table-cell-row-hover {
    background-color: var(--ant-table-row-bg) !important;
  }
`;
