import {
  FlyToResourceComponent,
  FlyToResourceBoundsComponent,
  ResetCameraComponent,
  MapMousePointer,
  MouseClickComponent,
  MouseOverComponent,
  OnMapClickEvent,
  ReactMapGl,
  SpaceStateHoverComponent,
  SelectResource,
  useCustomResources,
  WorkAreaLabels,
  WorkAreaBoundingBox,
  CustomResourceHoverComponent,
} from '@robinpowered/perseus';
import { useSetEditDeskPotentialDeskId } from 'atoms/editDesk';
import {
  useCurrentFilter,
  useIsCameraResetting,
  useMapInteractiveLayers,
  useMapMode,
  useSetIsCameraResetting,
} from 'atoms/mapInteractions';
import {
  useCurrentlySelectedResource,
  useSetCurrentlySelectedResource,
} from 'atoms/resource';
import { SelectedResource } from 'atoms/resource/types';
import { useSetSpaceSidebarView } from 'atoms/editSpace';
import {
  useSetDeskSidebarView,
  useSetRightSidebarView,
} from 'atoms/sidebar/hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { checkIsExhaustive, getClickedResource } from 'utils';
import {
  AmplitudeEvents,
  CustomResourceAmplitudeEvents,
} from 'constants/amplitudeEvents';
import { useAmplitude } from 'contexts';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

export const MapInteractions = () => {
  const { templates } = useCustomResources();

  const mapInteractiveLayers = useMapInteractiveLayers();
  const { trackCustomResourceEvent, trackEvent } = useAmplitude();

  const isCameraResetting = useIsCameraResetting();
  const setIsCameraResetting = useSetIsCameraResetting();

  const currentlySelectedResource = useCurrentlySelectedResource();
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setEditDeskPotentialDeskId = useSetEditDeskPotentialDeskId();
  const setRightSidebarView = useSetRightSidebarView();
  const currentFilter = useCurrentFilter();
  const setDeskSidebarView = useSetDeskSidebarView();
  const setSpaceSidebarView = useSetSpaceSidebarView();
  const mapMode = useMapMode();

  const { value: workAreasEnabled } = useFeatureFlag(
    'talos(office-V2-work-areas-enabled)',
    false
  );

  const seatStateFilter = useMemo(() => {
    if (mapMode === 'edit-desk-reservation') {
      return {
        available: true,
      };
    }
  }, [mapMode]);

  const { id: selectedResourceId, type: selectedResourceType } =
    useCurrentlySelectedResource() || {};
  const [currentlySelectedResourceForFly, setCurrentlySelectedResourceForFly] =
    useState<string>();

  // Update when currentlySelectedResource changes
  useEffect(() => {
    if (currentlySelectedResource?.id) {
      setCurrentlySelectedResourceForFly(currentlySelectedResource.id);
    }
  }, [currentlySelectedResource]);

  const onCameraResetComplete = useCallback(() => {
    setIsCameraResetting(false);
  }, [setIsCameraResetting]);

  const handleBrowseModeClick = useCallback(
    (resource: SelectedResource | undefined) => {
      if (!resource) {
        setCurrentlySelectedResource(null);
        setCurrentlySelectedResourceForFly(undefined);

        // space list should stay open when space filter is active
        if (currentFilter === 'spaces') {
          setSpaceSidebarView('space-list');
        } else {
          setRightSidebarView(undefined);
        }
      } else {
        setCurrentlySelectedResource(resource);
        if (resource.type === 'seats') {
          setDeskSidebarView('desk-details');

          //We only want to track this type of event in 'browse' mode
          trackEvent(AmplitudeEvents.MAP_INTERACTION_SEAT);
        } else if (resource.type === 'spaces') {
          setSpaceSidebarView('space-details');

          //We only want to track this type of event in 'browse' mode
          trackEvent(AmplitudeEvents.MAP_INTERACTION_SPACE);
        } else if (resource.type === 'work_area_labels') {
          setDeskSidebarView('work-area-details');

          trackEvent(AmplitudeEvents.MAP_INTERACTION_WORK_AREA);
        } else {
          setRightSidebarView('custom-resource');

          //We only want to track this type of event in 'browse' mode
          if (resource?.resourceCategory) {
            trackCustomResourceEvent(
              CustomResourceAmplitudeEvents.MAP_INTERACTION_CUSTOMRESOURCE,
              resource?.resourceCategory
            );
          }
        }
      }
    },
    [
      setCurrentlySelectedResource,
      currentFilter,
      setSpaceSidebarView,
      setRightSidebarView,
      setDeskSidebarView,
      trackCustomResourceEvent,
      trackEvent,
    ]
  );

  const handleEditDeskModeClick = useCallback(
    (resource: SelectedResource | undefined) => {
      if (
        resource?.type === 'seats' &&
        resource.id !== currentlySelectedResource?.id
        // TODO: Handle unavailable desks
      ) {
        setEditDeskPotentialDeskId(resource.id);
        setDeskSidebarView('potential-desk');
      }
    },
    [
      currentlySelectedResource?.id,
      setEditDeskPotentialDeskId,
      setDeskSidebarView,
    ]
  );

  const handleMapClick = useCallback(
    (e: OnMapClickEvent) => {
      const resource = getClickedResource(e, templates);

      switch (mapMode) {
        case 'browse':
          handleBrowseModeClick(resource);
          break;

        case 'edit-desk-reservation':
          handleEditDeskModeClick(resource);
          break;

        default:
          checkIsExhaustive(mapMode);
      }
    },
    [handleBrowseModeClick, handleEditDeskModeClick, mapMode, templates]
  );

  const handleFlyComplete = useCallback(() => {
    setCurrentlySelectedResourceForFly(undefined);
  }, []);

  return (
    <>
      <MouseOverComponent mouseOverSourceIdentity={mapInteractiveLayers}>
        <SpaceStateHoverComponent />
        {/* <SeatStateHoverComponent /> */}
        <MapMousePointer
          mouseOverSourceIdentity={mapInteractiveLayers}
          defaultCursorStyle="default"
        />
      </MouseOverComponent>

      <SpaceStateHoverComponent />

      {templates?.map((template) => (
        <CustomResourceHoverComponent layerId={template.id} />
      ))}

      {/** Map Navigation Control  */}
      <ReactMapGl.NavigationControl position="top-right" />

      {/** Seat + space mouse click control */}
      <MouseClickComponent
        onClick={handleMapClick}
        seatStateFilter={seatStateFilter}
      />

      <SelectResource
        selectedResource={
          selectedResourceId
            ? {
                id: selectedResourceId,
                type: selectedResourceType as 'seats' | 'spaces',
              }
            : undefined
        }
      />

      {workAreasEnabled && (
        <>
          <WorkAreaLabels />

          {currentlySelectedResource?.type === 'work_area_labels' && (
            <>
              <WorkAreaBoundingBox workAreaId={currentlySelectedResource.id} />

              <FlyToResourceBoundsComponent
                resourceLayer="work_area_outlines"
                resourceId={currentlySelectedResourceForFly}
                onFitCompleteOrInterrupted={handleFlyComplete}
              />
            </>
          )}
        </>
      )}

      {/* Fly to resource on click components  */}
      {mapInteractiveLayers.has('seats') && (
        <FlyToResourceComponent
          resourceLayer="seats"
          resourceId={currentlySelectedResourceForFly}
          onFlyCompleteOrInterrupted={handleFlyComplete}
          zoomTo={6}
        />
      )}

      {(mapInteractiveLayers.has('spaces') ||
        mapInteractiveLayers.has('space_labels')) && (
        <FlyToResourceComponent
          resourceLayer="spaces"
          resourceId={currentlySelectedResourceForFly}
          onFlyCompleteOrInterrupted={handleFlyComplete}
          zoomTo={4}
        />
      )}

      {templates?.map((template) => (
        <FlyToResourceComponent
          resourceLayer={template.id}
          resourceId={currentlySelectedResourceForFly}
          zoomTo={4}
        />
      ))}

      {isCameraResetting && (
        <ResetCameraComponent
          onCameraResetCompleteOrInterrupted={onCameraResetComplete}
        />
      )}
    </>
  );
};
