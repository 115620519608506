import common from './common.json';
import map from './map.json';
import mapControls from './mapControls.json';
import filters from './filters.json';
import time from './time.json';
import deskDetails from './deskDetails.json';
import spaceDetails from './spaceDetails.json';
import customResourceDetails from './customResourceDetails.json';
import reportAnIssue from './reportAnIssue.json';
import leftSideBar from './leftSideBar.json';
import error from './error.json';
import workAreaDetails from './workAreaDetails.json';
import days from './days.json';

export const fr_ca = {
  common,
  days,
  map,
  mapControls,
  filters,
  time,
  deskDetails,
  spaceDetails,
  reportAnIssue,
  leftSideBar,
  customResourceDetails,
  error,
  workAreaDetails,
};
