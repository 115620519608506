import {
  useGetResourceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useTimezone,
} from 'atoms/resource';
import { ISO8601Duration, IsoDateOnlyString } from 'generated';
import { useMemo } from 'react';
import {
  createIso8601Duration,
  createRecurrenceFromDates,
  LocationDateTimeMoment,
  momentToLocationDateTime,
  RDateString,
  toIsoDateOnlyString,
} from 'utils';

interface ResourceAvailabilityQueryParameters {
  dates: IsoDateOnlyString[];
  startTimes: LocationDateTimeMoment[] | null;
  endTime: LocationDateTimeMoment | undefined;
  durationInMinutes: number;
  durationISO: ISO8601Duration;
  recurrence?: RDateString;
}

export const useResourceAvailabilityQueryParameters =
  (): ResourceAvailabilityQueryParameters => {
    const { timezone } = useTimezone();

    const startTimes = useGetStartTimesForSelectedDates();
    const resourceEndTimes = useGetResourceEndTimesForSelectedDates();

    const firstStartTime = startTimes?.[0];
    const firstEndTime = resourceEndTimes?.[0];

    const durationInMinutes = useMemo(() => {
      if (firstStartTime && firstEndTime) {
        return firstEndTime.diff(firstStartTime, 'minute');
      }
      return 15;
    }, [firstStartTime, firstEndTime]);

    const durationISO = useMemo(() => {
      return createIso8601Duration({
        minutes: durationInMinutes,
      });
    }, [durationInMinutes]);

    const dates = useMemo(() => {
      return startTimes
        ? startTimes.map((startTime) =>
            toIsoDateOnlyString(
              momentToLocationDateTime(startTime.clone(), timezone)
            )
          )
        : [];
    }, [startTimes, timezone]);

    const recurrence = useMemo(() => {
      return startTimes && startTimes.length > 0
        ? createRecurrenceFromDates(startTimes)
        : undefined;
    }, [startTimes]);

    return {
      dates,
      startTimes,
      endTime: resourceEndTimes?.[0],
      durationInMinutes,
      durationISO,
      recurrence,
    };
  };
