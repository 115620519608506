import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWorkAreaDetails } from './graphql/useWorkAreaDetails';
import { DeskList, DeskListZone } from '../desk/desk-list/DeskList';

type WorkAreaDeskListProps = {
  workAreaId: string | undefined;
};

export const WorkAreaDeskList: FC<WorkAreaDeskListProps> = ({ workAreaId }) => {
  const { loading, data } = useWorkAreaDetails(workAreaId);
  const workAreaData = data?.getWorkAreaById;

  // Group desks by zone
  const zones: DeskListZone[] = useMemo(() => {
    if (!workAreaData?.desks) {
      return [];
    }

    // Create a map to group desks by zone
    const zoneMap = new Map<string, DeskListZone>();

    // Process each desk and group by zone
    workAreaData.desks.forEach((desk) => {
      // Do not include disabled desks in the list
      if (desk.isDisabled) {
        return;
      }

      const { zone } = desk;

      if (!zone) {
        return;
      }

      if (!zoneMap.has(zone.id)) {
        zoneMap.set(zone.id, {
          id: zone.id,
          name: zone.name,
          desks: [],
        });
      }

      const mappedZone = zoneMap.get(zone.id);

      if (mappedZone) {
        const deskZone = desk.zone
          ? {
              id: zone.id,
              name: zone.name,
            }
          : undefined;

        mappedZone.desks.push({
          id: desk.id,
          name: desk.name,
          zone: deskZone,
        });
      }
    });

    return Array.from(zoneMap.values());
  }, [workAreaData]);

  return <DeskList zones={zones} isLoading={loading} />;
};

// Export the hook for use in the view component
export const useWorkAreaName = (
  workAreaId: string | undefined
): { name: string; loading: boolean } => {
  const { t } = useTranslation('workAreaDetails');
  const { data, loading } = useWorkAreaDetails(workAreaId);

  return {
    name: data?.getWorkAreaById?.name || t('work_area.header'),
    loading,
  };
};
