import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AutoComplete, Empty, Input, Typography } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import Fuse, { FuseResult } from 'fuse.js';
import { useSetEditDeskPotentialDeskId } from 'atoms/editDesk';
import styled from '@emotion/styled';
import { sortObjectAsc } from 'utils/sort';
import { useSetDeskSidebarView } from 'atoms/sidebar/hooks';
import { useMapMode } from 'atoms/mapInteractions';
import { useViewDeskDetails } from './DeskListItem/hooks/useViewDeskDetails';
import { checkIsExhaustive } from 'utils/isExhaustive';
import { DeskListDesk, DeskListZone } from './DeskList';

export const DeskListSearch: FC<{
  zones: DeskListZone[] | null | undefined;
}> = ({ zones }) => {
  const { t } = useTranslation('deskDetails');
  const setViewDeskDetails = useViewDeskDetails();
  const setPotentialDeskId = useSetEditDeskPotentialDeskId();
  const setView = useSetDeskSidebarView();

  const mapMode = useMapMode();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [searchResults, setSearchResults] = useState<DeskListZone[]>([]);

  const renderDeskOption = useCallback(
    ({ id, name }: { id: string; name: string }) => ({
      key: `desk-${id}`,
      value: id,
      label: name,
    }),
    []
  );

  const zonesOptions = useMemo(
    () =>
      [...searchResults].sort(sortObjectAsc('name')).map((zone) => ({
        key: `zone-${zone.id}`,
        label: <ZoneLabel key={zone.id}>{zone.name}</ZoneLabel>,
        options: [...zone.desks]
          .sort(sortObjectAsc('name'))
          .map(renderDeskOption),
      })),
    [searchResults, renderDeskOption]
  );

  useEffect(() => {
    if (!zones) {
      return;
    }

    if (!searchTerm) {
      setSearchResults(zones);
      return;
    }

    const fuse = new Fuse(zones, {
      keys: ['desks.name'],
      includeMatches: true,
      threshold: 0.2,
    });

    const results = fuse.search(searchTerm);
    const filteredZones = results.map(filterZoneToMatchedDesks);

    setSearchResults(filteredZones);
  }, [searchTerm, zones]);

  const handleSearch = useCallback((searchTerm: string) => {
    setSearchTerm(searchTerm);
  }, []);

  const handleSelect = (deskId: string) => {
    const selectForEdit = () => {
      setPotentialDeskId(deskId);
      setView('potential-desk');
    };
    switch (mapMode) {
      case 'browse':
        return setViewDeskDetails(deskId);

      case 'edit-desk-reservation':
        return selectForEdit();

      default:
        checkIsExhaustive(mapMode);
    }
  };

  return (
    <AutoComplete
      data-testid="desk-list-search"
      onSearch={handleSearch}
      onChange={handleSearch}
      onSelect={handleSelect}
      style={{ width: '100%' }}
      options={zonesOptions}
      notFoundContent={
        <Empty
          data-testid="desk-list-search-no-results"
          description={t('desk_list.search_no_results')}
        />
      }
    >
      <Input.Search placeholder={t('desk_list.search')} />
    </AutoComplete>
  );
};

const filterZoneToMatchedDesks = (result: FuseResult<DeskListZone>) => {
  const zone = { ...result.item };

  zone.desks =
    result.matches
      ?.reduce<DeskListDesk[]>((acc, match) => {
        if (match.refIndex !== undefined) {
          const desk = result.item.desks[match.refIndex];

          if (desk) {
            acc.push(desk);
          }
        }
        return acc;
      }, [])
      .sort(sortObjectAsc('name')) || [];

  return zone;
};

const ZoneLabel = styled(Typography.Text)`
  color: var(--Components-List-Global-colorTextDescription, #6c6c6c);
`;
