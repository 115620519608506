import { gql, useQuery } from '@apollo/client';
import { useAuthContext } from 'contexts';
import {
  GetCustomResourceReservationsQuery,
  GetCustomResourceReservationsQueryVariables,
  ISO8601Duration,
  IsoDateOnlyString,
  IsoLocalTimeString,
} from 'generated';

export type CustomResourceReservation =
  GetCustomResourceReservationsQuery['customResourceReservationsGroupedByDate']['resourceReservationsByDate'][0]['reservations'][0];

const GET_RESOURCE_RESERVATIONS_BY_ID = gql`
  query getCustomResourceReservations(
    $organizationId: ID!
    $resourceInstanceIds: [ID!]!
    $localDates: [IsoDateOnlyString!]!
    $localStartTime: IsoLocalTimeString!
    $duration: ISO8601_Duration!
  ) {
    customResourceReservationsGroupedByDate(
      organizationId: $organizationId
      resourceInstanceIds: $resourceInstanceIds
      localDates: $localDates
      localStartTime: $localStartTime
      duration: $duration
    ) {
      id
      resourceReservationsByDate {
        resourceInstanceId
        localDate
        reservations {
          id
          date
          duration
          reservee
          reservedBy
          startTime
          resourceInstanceId
          isConfirmed
          accountReservee {
            id
            name
            avatar
            primaryEmail {
              email
            }
          }
        }
      }
    }
  }
`;

export const useGetResourceReservationsByResourceId = (
  selectedResourceId: string | undefined,
  dates: IsoDateOnlyString[],
  startTime: IsoLocalTimeString | undefined,
  duration: ISO8601Duration | undefined
) => {
  const { currentOrg } = useAuthContext();

  const variables =
    selectedResourceId &&
    currentOrg?.id &&
    dates.length > 0 &&
    startTime &&
    duration
      ? {
          organizationId: currentOrg?.id,
          resourceInstanceIds: [selectedResourceId],
          localDates: dates,
          localStartTime: startTime,
          duration,
        }
      : undefined;
  const { loading, data } = useQuery<
    GetCustomResourceReservationsQuery,
    GetCustomResourceReservationsQueryVariables
  >(GET_RESOURCE_RESERVATIONS_BY_ID, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    skip:
      !selectedResourceId ||
      !currentOrg?.id ||
      dates.length === 0 ||
      !startTime ||
      !duration,
    variables,
  });

  return {
    data:
      data?.customResourceReservationsGroupedByDate
        .resourceReservationsByDate || [],
    loading,
  };
};
