import { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { config } from 'config';
import { datadogRum } from '@datadog/browser-rum';
import { Button, Result, Typography } from '@robinpowered/ui-kit';
import { AnalyticsCard, InfoSolid, QuestionOutline } from '@robinpowered/icons';

const { Paragraph, Text } = Typography;

export class AuthenticationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

type State = {
  error: null | Error;
};

export class ErrorBoundaryComponent extends Component<
  WithTranslation<'error'>,
  State
> {
  state: State = {
    error: null,
  };

  static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  componentDidCatch(error: Error): void {
    if (config.robinEnv !== 'development') {
      datadogRum.addError(error);
    }

    if (error instanceof AuthenticationError) {
      if (config.env !== 'development') {
        // Any authentications outside of the apollo client would indicate a missing cookie, no need to clear it
        const currentPath = window.location.pathname + window.location.search;
        const encodedPath = encodeURIComponent(currentPath);
        window.location.href = `${config.dashboardUrl}/login?redirectTo=${encodedPath}`;
      }
    }
  }

  render(): React.ReactNode {
    const { t } = this.props;

    if (!this.state.error) {
      return this.props.children;
    }
    return (
      <Result
        status="500"
        title={t('generic_error_heading')}
        subTitle={t('generic_error_message')}
        extra={
          <Button type="primary" onClick={() => window.location.reload()}>
            {t('reload_button')}
          </Button>
        }
      >
        <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              {t('helpful_resources')}
            </Text>
          </Paragraph>
          <Paragraph>
            <AnalyticsCard style={{ width: 16, height: 16 }} />{' '}
            {t('system_status')}{' '}
            <a href="https://status.robinpowered.com">{t('view_link')}</a>
          </Paragraph>
          <Paragraph>
            <InfoSolid style={{ width: 16, height: 16 }} /> {t('help_center')}{' '}
            <a href="https://support.robinpowered.com/hc/en-us">
              {t('view_link')}
            </a>
          </Paragraph>
          <Paragraph>
            <QuestionOutline style={{ width: 16, height: 16 }} />{' '}
            {t('support_contact')}
          </Paragraph>
        </div>
      </Result>
    );
  }
}

export const GlobalErrorBoundary = withTranslation('error')(
  ErrorBoundaryComponent
);
