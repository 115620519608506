import { config } from 'config';

export const layoutToolUrl = (orgSlug: string, levelId: string) => {
  return `${config.dashboardUrl}/${orgSlug}/manage-map/${levelId}`;
};

export const assignmentToolUrl = (orgSlug: string, levelId: string) => {
  return `${config.dashboardUrl}/${orgSlug}/manage-workplace/${levelId}`;
};

export const quickEditAssignmentToolUrl = (
  orgSlug: string,
  levelId: string
) => {
  return `${config.dashboardUrl}/${orgSlug}/workplace-management/${levelId}/draft/default`;
};

export const scenarioPlanningAssignmentToolUrl = (
  orgSlug: string,
  levelId: string
) => {
  return `${config.dashboardUrl}/${orgSlug}/workplace-management/${levelId}`;
};
