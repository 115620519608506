import styled from '@emotion/styled';
import { Avatar, Typography } from '@robinpowered/ui-kit';
import React from 'react';

const { Text } = Typography;

interface UserDetailsProps {
  avatarSrc: string | null | undefined;
  name: string | null | undefined;
  email: string | null | undefined;
}

export function UserDetails({
  avatarSrc,
  name,
  email,
}: UserDetailsProps): JSX.Element {
  const userDetails = name ? (
    <>
      <UserName>{name}</UserName>
      <UserEmail>{email}</UserEmail>
    </>
  ) : (
    <>
      <UserEmail>{email}</UserEmail>
    </>
  );
  return (
    <UserDetailContainer>
      <Avatar src={avatarSrc} name={name || email || ''} />
      <UserDetailsContainer>{userDetails}</UserDetailsContainer>
      {/* <FavoriteButton type="text" icon={<StarSolid />} /> */}
    </UserDetailContainer>
  );
}

const UserDetailContainer = styled.div`
  display: flex;
  padding: var(--Space-Margin-marginXS, 8px) 0px;
  align-items: center;
  gap: var(--Space-Margin-marginSM, 12px);
  align-self: stretch;
`;

const UserDetailsContainer = styled.div`
  display: flex;
  height: var(--Size-Height-controlHeightSM, 28px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  min-width: 0;
`;

const UserName = styled(Text)`
  display: flex;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserEmail = styled(Text)`
  height: 18px;
  flex-shrink: 0;
  align-self: stretch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

// const FavoriteButton = styled(Button)`
//   display: flex;
//   width: 32px;
//   height: 32px;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   gap: 8px;
//   border-radius: var(--Components-Button-Global-borderRadius, 4px);

//   .ant-btn-icon {
//     width: 16px;
//     height: 16px;
//   }
// `;
