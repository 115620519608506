import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en';
import { fr } from './fr';
import { fr_ca } from './fr-ca';
import { de } from './de';
import { es } from './es';
import { ja } from './ja';

export const defaultNS = 'common';
export const resources = { en, fr, 'fr-CA': fr_ca, de, es, ja } as const;
export const nameSpaceList = [
  'common',
  'customResourceDetails',
  'days',
  'deskDetails',
  'error',
  'filters',
  'leftSidebar',
  'map',
  'mapControls',
  'reportAnIssue',
  'spaceDetails',
  'time',
] as const;

const getInitialLanguage = () => {
  const stored = localStorage.getItem('i18nextLng');
  if (stored && stored !== 'en') return stored;

  const browser = navigator.language;
  const lang = browser.split('-')[0];
  return Object.keys(resources).includes(lang) ? lang : 'en';
};

const i18n = i18next.use(initReactI18next);

void i18n.init({
  fallbackLng: 'en',
  resources,
  ns: nameSpaceList,
  defaultNS,
  lng: getInitialLanguage(),
  interpolation: { escapeValue: false },
});

export { i18n };
