import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo } from 'react';
import { Button, Skeleton } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';
import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { ChevronLeftOutline } from '@robinpowered/icons';
import { ResourceInformation } from './ResourceInformation';
import { CustomResourceBookingControls } from './booking/CustomResourceBookingControls';
import { useGetResourceById } from './hooks/graphql/useGetResourceById';
import {
  useGetResourceEndTimesForSelectedDates,
  useGetStartTimesForSelectedDates,
  useSetCurrentlySelectedResource,
} from 'atoms/resource';
import { useSetCurrentFilter } from 'atoms/mapInteractions';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';
import { CustomResourceReservations } from './reservation/CustomResourceReservations';
import { useGetResourceReservationsByResourceId } from './hooks/graphql/useGetResourceReservationsByResourceId';
import {
  createIso8601Duration,
  toIsoDateOnlyString,
  toIsoLocalTimeString,
} from 'utils';
import { useResourceReservationRecurringValidate } from './hooks/graphql/useResourceReservationRecurringValidate';
import { CustomResourceDetailsAmenitiesAndPolicies } from './CustomResourceDetailsAmenitiesAndPolicies';
import { CustomResourceNonReservableAlert } from './CustomResourceNonReservableAlert';
import { useResourceAvailabilityQueryParameters } from 'hooks/useResourceAvailabilityParameters';
import { useAuthContext } from 'contexts';
import { useBookingControlsContext } from 'contexts/BookingControlsContext';

interface CustomResourceDetailsProps {
  selectedResourceId: string;
}

export const CustomResourceDetails = ({
  selectedResourceId,
}: CustomResourceDetailsProps) => {
  const { t } = useTranslation('customResourceDetails');
  const { currentUser } = useAuthContext();

  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const setRightSidebarView = useSetRightSidebarView();
  const setCurrentFilter = useSetCurrentFilter();

  const { isBookingControlsSticky, setIsBookingControlsSticky } =
    useBookingControlsContext();

  useEffect(() => {
    setIsBookingControlsSticky(null);
  }, [selectedResourceId, setIsBookingControlsSticky]);

  const { data: resource, loading } = useGetResourceById(selectedResourceId);
  const startDatetimes = useGetStartTimesForSelectedDates();
  const endDateTimes = useGetResourceEndTimesForSelectedDates();

  const { data: reservations, loading: reservationsLoading } =
    useGetResourceReservationsByResourceId(
      resource?.customResourceInstanceId,
      startDatetimes ? [toIsoDateOnlyString(startDatetimes[0])] : [],
      startDatetimes ? toIsoLocalTimeString(startDatetimes[0]) : undefined,
      endDateTimes && startDatetimes
        ? createIso8601Duration({
            minutes: endDateTimes[0].diff(startDatetimes[0], 'minutes'),
          })
        : undefined
    );

  const { dates, startTimes, durationISO } =
    useResourceAvailabilityQueryParameters();

  const {
    data: reservationValidations,
    loading: reservationValidationsLoading,
  } = useResourceReservationRecurringValidate(
    selectedResourceId,
    dates,
    startTimes?.[0] ? toIsoLocalTimeString(startTimes[0]) : null,
    durationISO,
    currentUser?.id ?? ''
  );

  const allReservations = useMemo(
    () => reservations?.flatMap((x) => x.reservations),
    [reservations]
  );

  const bookingEnabled = useMemo(
    () => reservationValidations?.validationResponse?.errors.length === 0,
    [reservationValidations]
  );

  const hideBookingControls = useMemo(() => {
    return !isBookingControlsSticky;
  }, [isBookingControlsSticky]);

  useEffect(() => {
    if (
      isBookingControlsSticky === null &&
      !loading &&
      !reservationsLoading &&
      !reservationValidationsLoading
    ) {
      setIsBookingControlsSticky(bookingEnabled);
    }
  }, [
    bookingEnabled,
    isBookingControlsSticky,
    loading,
    reservationsLoading,
    reservationValidationsLoading,
    setIsBookingControlsSticky,
  ]);

  const onBack = useCallback(() => {
    //if (backDestination) {
    //  setDeskSidebarView(backDestination);
    //}

    console.log('TODO - custom resource back destination/context'); // eslint-disable-line no-console
  }, []);

  const onClose = useCallback(() => {
    setCurrentlySelectedResource(null);
    setRightSidebarView(undefined);
    setCurrentFilter(null);
  }, [setCurrentlySelectedResource, setRightSidebarView, setCurrentFilter]);

  if (loading) {
    return (
      <SidebarContent>
        <ResourceDetailsContent data-testid="skeleton">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </ResourceDetailsContent>
      </SidebarContent>
    );
  }

  if (!resource) {
    return null;
  }

  return (
    <>
      <SidebarHeader
        prefix={
          <BackButton type="link" onClick={onBack} data-testid="back-button">
            <ChevronLeftOutline size={16} />
          </BackButton>
        }
        header={t('resource_details.title')}
        onClose={onClose}
      />
      <SidebarContent>
        <ResourceDetailsContent>
          <ResourceInfoHeader>
            <ResourceInformation
              name={resource?.name}
              levelName={resource?.floor?.name}
              locationName={resource?.location?.name}
            />
          </ResourceInfoHeader>

          {!hideBookingControls && (
            <CustomResourceBookingControls
              bookingEnabled={bookingEnabled}
              unbookableReasons={
                reservationValidations?.validationResponse?.errors || []
              }
              resourceInstanceId={resource.customResourceInstanceId}
            />
          )}

          <CustomResourceNonReservableAlert
            validationErrors={
              reservationValidations?.validationResponse?.errors || []
            }
            bookingType={resource.bookingType}
          />

          <CustomResourceReservations
            reservations={allReservations}
            loading={reservationsLoading}
          />

          <CustomResourceDetailsAmenitiesAndPolicies
            resourceId={selectedResourceId}
          />
        </ResourceDetailsContent>
      </SidebarContent>
    </>
  );
};

const ResourceDetailsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--main-colors-white-white-0, #fff);
`;

const ResourceInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const BackButton = styled(Button)`
  padding: 0;
`;
