import { FC, useCallback } from 'react';
import { SidebarContent, SidebarHeader } from 'components/global/sidebar';
import { useCurrentlySelectedResource } from 'atoms/resource/hooks';
import { useSetRightSidebarView } from 'atoms/sidebar/hooks';
import {
  useResetMapInteractiveLayesrs,
  useSetCurrentFilter,
} from 'atoms/mapInteractions';
import { useSetCurrentlySelectedResource } from 'atoms/resource/hooks';
import { WorkAreaDeskList, useWorkAreaName } from './WorkAreaDeskList';
import { Skeleton } from '@robinpowered/ui-kit';
import styled from '@emotion/styled';

export const WorkAreaDeskListView: FC = () => {
  const { id: workAreaId } = useCurrentlySelectedResource() || {};
  const setRightSidebarView = useSetRightSidebarView();
  const setCurrentFilters = useSetCurrentFilter();
  const setCurrentlySelectedResource = useSetCurrentlySelectedResource();
  const resetMapInteractiveLayers = useResetMapInteractiveLayesrs();
  const { name: workAreaName, loading: nameLoading } =
    useWorkAreaName(workAreaId);

  const onClose = useCallback(() => {
    setRightSidebarView(undefined);
    setCurrentFilters(null);
    setCurrentlySelectedResource(null);
    resetMapInteractiveLayers();
  }, [
    setRightSidebarView,
    setCurrentFilters,
    setCurrentlySelectedResource,
    resetMapInteractiveLayers,
  ]);

  return (
    <>
      {nameLoading ? (
        <SkeletonWrapper>
          <Skeleton />
        </SkeletonWrapper>
      ) : (
        <SidebarHeader header={workAreaName} onClose={onClose} />
      )}

      <SidebarContent>
        <WorkAreaDeskList workAreaId={workAreaId} />
      </SidebarContent>
    </>
  );
};

const SkeletonWrapper = styled(Skeleton)`
  padding: 16px;
`;
