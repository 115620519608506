export enum AmplitudeEvents {
  // Shared with schedule app
  LOADED_OFFICE_TAB = 'loaded-office-tab',
  BOOKED_DESK = 'booked-desk',
  BOOKED_SPACE = 'booked-space', // Spaces are not directly booked in the office app
  REPORT_ISSUE = 'report-issue', // Reported issue with a desk only

  // Office UI specific
  MODAL_SWITCH_CURRENT_MAP = 'modal-switch-current-map',
  MAP_INTERACTION_SEAT = 'map-interaction-seat',
  MAP_INTERACTION_SPACE = 'map-interaction-space',
  MAP_INTERACTION_WORK_AREA = 'map-interaction-work-area',
  BOOKED_DESK_BY_ADMIN = 'booked-desk-by-admin',
  SPACE_DETAILS_VIEW_MORE_TIMES = 'space-details-view-more-times',
  SPACE_DETAILS_AVAILABILITY_PILL_CLICK = 'space-details-availability-pill-click',
  SPACE_DETAILS_NEW_EVENT_CLICK = 'space-details-new-event-click',
  MAP_CONTROLS_CALENDAR_SINGLE_DAY = 'map-controls-calendar-single-day',
  MAP_CONTROLS_CALENDAR_MULTI_DAY = 'map-controls-calendar-multi-day',
  MAP_CONTROLS_CALENDAR_RANGE = 'map-controls-calendar-range',
  CALENDAR_DATE_SELECTED_SINGLE_DAY = 'calendar-date-selected-single-day',
  CALENDAR_DATE_SELECTED_MULTI_DAY = 'calendar-date-selected-multi-day',
  CALENDAR_DATE_SELECTED_RANGE = 'calendar-date-selected-range',
  MAP_CONTROLS_END_TIME_BUTTON = 'map-controls-end-time-button',
  SELECTED_DESKS_FILTER = 'selected-desks-filter',
  SELECTED_SPACES_FILTER = 'selected-spaces-filter',
}

export enum CustomResourceAmplitudeEvents {
  MAP_INTERACTION_CUSTOMRESOURCE = 'map-interaction-customresource',
  BOOKED_CUSTOMRESOURCE = 'booked-customresource',
  BOOKED_CUSTOMRESOURCE_BY_ADMIN = 'booked-customresource-byadmin',
  SELECTED_CUSTOMRESOURCE_FILTER = 'selected-customresource-filter',
  CHECKIN_CUSTOMRESOURCE = 'checkin-customresource',
  CANCELLED_CUSTOMRESOURCE = 'cancelled-customresource',
}
