import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { Skeleton, message } from '@robinpowered/ui-kit';
import { rightSidebarView } from '../../atoms/sidebar/atoms';
import { useCurrentlySelectedResource } from 'atoms/resource';
import { useReportIssueWithDesk, useDeskLocationDetails } from './graphql';
import { IssueReport, ReportIssue, ResourceDetails } from './ReportIssue';
import { SidebarHeader, SidebarContent } from '../global/sidebar';

export const DeskReportIssue = (): JSX.Element | null => {
  const { t } = useTranslation('reportAnIssue');
  const setRightSidebarView = useSetRecoilState(rightSidebarView);
  const { id: selectedResourceId } = useCurrentlySelectedResource() || {};
  const [reportIssueWithDesk, reportIssueWithDeskRequest] =
    useReportIssueWithDesk();
  const { deskDetails, loading } = useDeskLocationDetails(selectedResourceId);

  const handleClose = () => {
    setRightSidebarView('desks');
  };

  const handleFormSubmit = async (issue: IssueReport) => {
    try {
      if (!deskDetails?.id) {
        return;
      }
      await reportIssueWithDesk({
        variables: {
          input: {
            deskId: deskDetails.id,
            issue,
          },
        },
      });
      handleClose();
      await message.success(t('report_issue.success_message'));
    } catch (e) {
      await message.error(t('report_issue.failure_message'));
    }
  };

  if (loading) {
    return (
      <>
        <SidebarHeader header={t('report_issue.header')} />
        <SidebarContent data-testid="skeleton">
          <Skeleton active />
        </SidebarContent>
      </>
    );
  }

  return (
    <ReportIssue
      resourceDetails={deskDetails as ResourceDetails}
      handleFormSubmit={handleFormSubmit}
      handleClose={handleClose}
      reportIssueWithResourceRequest={reportIssueWithDeskRequest}
    />
  );
};
