import styled from '@emotion/styled';
import { EmergencySolid } from '@robinpowered/icons';
import { Button, Popconfirm } from '@robinpowered/ui-kit';
import { useTranslation } from 'react-i18next';

interface CancelResourceButtonProps {
  cancelReservation: () => void;
  isLoading: boolean;
  disabled: boolean;
}

export const CancelReservationButton = ({
  cancelReservation,
  isLoading,
  disabled,
}: CancelResourceButtonProps) => {
  const { t } = useTranslation('customResourceDetails');
  return (
    <>
      <Popconfirm
        title={t('resource_booking_controls.cancel_confirm_title')}
        description={t('resource_booking_controls.cancel_confirm_desc')}
        onConfirm={() => {
          cancelReservation();
        }}
        // see cssReset.ts for global css
        overlayClassName="cancel-resource-popconfirm-overlay"
        okText={t('resource_booking_controls.yes')}
        cancelText={t('resource_booking_controls.no')}
        okButtonProps={{
          danger: true,
        }}
        icon={
          <EmergencySolidWrapper
            size={16}
            color="var(--ant-color-error, #e81c1c)"
          />
        }
      >
        <CancelButton
          data-testid="cancel-button"
          type="default"
          danger
          loading={isLoading}
          disabled={disabled}
        >
          {t('resource_booking_controls.cancel')}
        </CancelButton>
      </Popconfirm>
    </>
  );
};
const EmergencySolidWrapper = styled(EmergencySolid)`
  margin-right: 2px;
  margin-top: 2px;
`;

const CancelButton = styled(Button)`
  display: flex;
  padding: 0px var(--Components-Button-Component-paddingInline, 16px);
  justify-content: center;
  align-items: center;
  flex: 1;
`;
